import { createContext, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { Localized } from "@fluent/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorContainer } from "../../../app/ErrorContainer";
import { LoadingContainer } from "../../../app/LoadingContainer";
import { AppBar, Box, Button, Container, Grid, Stack, Tab } from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { TeamMemberPanel } from "../components/TeamMemberPanel";
import { RiskAnalysisContent } from "../../riskAnalysis/NewRisk";
import produce from "immer";
import { DocumentDetailsPanel } from "../components/DocumentDetailsPanel";
import { DocumentLimitEditor } from "../components/DocumentLimitEditor";
import { DocumentToolbarMenu } from "../components/DocumentToolbarMenu";
import { WorkflowActionRunner } from "../components/WorkflowActionRunner";
import { ActionTitle } from "../components/WorkflowActionUtils";
import { DocumentActions, DocumentHeader, DocumentWorkflowActions, loadDocument, loadDocumentActions, performDocumentAction } from "../documentsApi";
import { showSuccess } from "../../notifications/notificationsSlice";
import { DocComment, loadDocComments } from "../commentsAPI";
import { LoadActivities, LoadValidations } from "../../../app/AppDataLoader";
import { DocumentFormHeader } from "../components/DocumentFormHeader";
import { useNavigate } from "react-router-dom"
import { EditInternalDSSpec } from "../../specs/EditSpecDS";
import { If } from "../../../app/If";
import { EditInternalFSSpec } from "../../specs/EditSpecFS";

type FormTabs = "content" | "attachments" | "team"

export const DsForm = () => {
    const dispatch = useAppDispatch()
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const [document, setDocument] = useState<DocumentHeader>();
    const [documentActions, setDocumentActions] = useState<DocumentActions>();
    const [comments, setComments] = useState<DocComment[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const document = await dispatch(loadDocument(id)).then(unwrapResult);
                setDocument(document);

                const actions = await dispatch(loadDocumentActions(id)).then(unwrapResult);
                setDocumentActions(actions);

                setComments(
                    await dispatch(loadDocComments(id)).unwrap()
                )
            } catch (error) {
                setIsError(true);
                if (error instanceof Error) {
                    setError(error);
                } else {
                    setError(new Error('An unknown error occurred'));
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (document && documentActions) {
        return <LoadValidations component={<LoadingContainer />}>
            <LoadActivities component={<LoadingContainer />}>
                <RiskAnalysisRoot document={document} actions={documentActions} comments={comments} />
            </LoadActivities>
        </LoadValidations>
    }

    return <>
    </>
}

export const DataSetterContext = createContext<(f: (data: DocumentHeader) => void) => void>(() => { })

export interface RiskAnalysisRootProps {
    document: DocumentHeader
    actions: DocumentActions
    comments: DocComment[]
}
export const RiskAnalysisRoot = ({ document: defDoc, actions: actionsDef, comments }: RiskAnalysisRootProps) => {
    const [document, setDocument] = useState(defDoc)
    const [actions, setActions] = useState(actionsDef)
    const [tab, setTab] = useState<FormTabs>("content");
    const navigate = useNavigate()
    const [contentValue, setContentValue] = useState<number|null>(null);

    useEffect(() => {
        if (document?.settings) {
            // Wyciągnięcie elementu o name === "CONTENT"
            const contentSetting = document.settings.find(
                (setting) => setting.name === "CONTENT"
            );

            const numericValue = Number(contentSetting?.value);

            // Sprawdzenie, czy wartość jest liczbą (w przypadku błędnych danych)
            setContentValue(isNaN(numericValue) ? null : numericValue);
        }
    }, [document]);

    const [goodThreshold, setGoodThreshold] = useState<number>(
        () => {
            const setting = document?.settings.find(x => x.name === 'GOOD_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );

    const [warningThreshold, setWarningThreshold] = useState<number>(
        () => {
            const setting = document?.settings.find(x => x.name === 'WARNING_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );


    const updateDocLocally = useCallback((f: (data: DocumentHeader) => void) => {
        setDocument(produce(draft => {
            f(draft)
        }))
    }, [])

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])


    const [selectedAction, setSelectedAction] = useState<DocumentWorkflowActions | null>(null);

    const handleActionClick = (action: DocumentWorkflowActions) => {
        setSelectedAction(action);
    }


    const getButtonColor = (action: DocumentWorkflowActions) => {
        switch (action) {
            case "Reject":
            case "WithdrawFromOpinion":
                return "error";
            default:
                return "info";
        }
    };

    const onWorkflowActionSuccess = (actions: DocumentActions) => {
        setActions(actions)
        setDocument(produce(document => {
            document.status = actions.documentStatus
        }))
        setSelectedAction(null)
    };

    return <DataSetterContext.Provider value={updateDocLocally}>
        <Container maxWidth={false} sx={{ padding: "2px", paddingLeft: "2px!important", paddingRight: "2px!important" }}>
            <DocumentFormHeader doc={document} />
            <Grid container >
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value="content"
                                label={<Localized id="">Content</Localized>} />
                            <Tab
                                key={1}
                                value="details"
                                label={<Localized id="t">Dokument</Localized>} />
                            <Tab
                                key={2}
                                value="team"
                                label={<Localized id="t">Zespół</Localized>} />
                            <Tab
                                key={3}
                                value="attachments"
                                label={<Localized id="attachments">Załaczniki</Localized>} />
                        </TabList>
                        <TabPanel sx={{ pl: 0, pr: 0, pt: 1 }} value='content'>
                            <If condition={contentValue != null}>
                                <EditInternalFSSpec id={Number(contentValue)} />
                            </If>
                        </TabPanel>
                        <TabPanel value="team">
                            <TeamMemberPanel documentId={document?.id} mode="create" />
                        </TabPanel>
                        <TabPanel value={"details"}>
                            <DocumentDetailsPanel document={document} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <div className="flex justify-end">
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={2} direction="row">
                            {actions.workflowActions.map((action) => (
                                <LoadingButton
                                    key={action}
                                    size="small"
                                    variant="contained"
                                    color={getButtonColor(action)}
                                    onClick={() => handleActionClick(action)}
                                >
                                    <ActionTitle action={action} />
                                </LoadingButton>
                            ))}
                        </Stack>
                    </Box>
                    <DocumentToolbarMenu documentId={document.id} />
                    <Button onClick={() => navigate(-1)} variant='contained' color="primary" size="small"><Localized id='back'>Wróć</Localized></Button>
                </div>
            </AppBar>
            {selectedAction && (
                <WorkflowActionRunner
                    documentId={document.id}
                    action={selectedAction}
                    onSuccess={onWorkflowActionSuccess}
                    onCancel={() => setSelectedAction(null)} />)
            }
        </Container>
    </DataSetterContext.Provider>
}
