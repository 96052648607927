import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";
import { ManagementRoleType } from "../../utils/managedObject";

export type CompanySettingType
    = "DEFAULT_LANGUAGE"

export type SupplierEventType
    = "NEW_URS_REQUEST"

export type WorkflowRoleType
    = "OWNER"
    | "PROCESS_MANAGER"
    | "COLLABORATOR"
    | "APPROVER"


export type NotificationMethodType
    = "EMAIL"

export interface CompanySetting {
    id: AppId
    companyId: number
    settingType: CompanySettingType
    value: string
    isActive: boolean
    modifiedBy: string
    modifyDate: Date
}

export interface CompanySupplierAssigmentSetting {
    id: AppId;
    user: string;
    companyId: number;
    event: SupplierEventType
    role: ManagementRoleType;
    isActive: boolean
    notifyUser: boolean
    notificationMethod: boolean
    modifiedBy: string
    modifyDate: Date
}

export interface CompanySupplierAssigmentSettingPayload {
    id?: AppId; 
    user: string;
    event: SupplierEventType;
    role: ManagementRoleType;
    notifyUser: boolean;
}

export const loadCompanySettings = createAsyncThunk<CompanySetting[], void, AppThunkAPIType>("/company-settings/load-main-settings",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting[], void, AppThunkAPIType>("/company-settings/supplier-assigments",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings/supplier-assigments` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, CompanySupplierAssigmentSettingPayload, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (companySupplierAssigmentSetting, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: `api/company-settings/supplier-assigments`, payload: companySupplierAssigmentSetting }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, CompanySupplierAssigmentSettingPayload, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (companySupplierAssigmentSetting, { dispatch, rejectWithValue }) => {
        const { id, ...data } = companySupplierAssigmentSetting;
        const result = await dispatch(putWithAuth({ url: `api/company-settings/supplier-assigments/${id}`, payload: data }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, string, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/company-settings/supplier-assigments/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })