import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Button, ButtonGroup, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { APIError } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { ServerErrorMsg } from "../../app/ServerErrorMsg"
import { DeviceTypeCombobox } from "../device/DeviceTypeCombobox"
import { createSpec, defaultSpec, DeviceSpecificationType } from "./specSlice"
import DeviceSpecTypeDropdown from "./DeviceSpecTypeDropdown"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const CreateSpec = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [deviceTypeId, setDeviceTypeId] = useState<string>("")
    const [specType, setSpecType] = useState<DeviceSpecificationType | "">("")

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const save = async () => {
        if (name !== '' && deviceTypeId !== '' && specType) {
            setSaving(true)
            try {
                const { id } = await dispatch(createSpec({
                    ...defaultSpec,
                    name,
                    description,
                    structureId: deviceTypeId,
                    type: specType
                })).unwrap()
                navigate(`/specs/edit/${id}`, { replace: true })
            } catch (err) {
                setError(err as APIError)
            } finally {
                setSaving(false)
            }
        }
    }

    const onCancel = useCallback(() => {
        navigate(-1)
    }, [])

    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])

    return <div className="container mx-auto">
        <div className="flex flex-col gap-4">
            <ServerErrorMsg err={error} />
            <DeviceSpecTypeDropdown value={specType} onChange={setSpecType} />
            <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                required
                error={name === ''}
                label={<Localized id='spec-name'>_Spec Name</Localized>}
            />
            <TextField
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth
                multiline
                label={<Localized id='spec-description'>_Spec description</Localized>}
            />
            <DeviceTypeCombobox
                onlyLeafs={true}
                fullWidth
                required
                error={deviceTypeId === ''}
                helperText={deviceTypeId === '' && <RequiredError />}
                value={deviceTypeId}
                onChange={handleDeviceTypeIdChange}
                label={<Localized id="structure">Struktura</Localized>}
            />
            <ButtonGroup>
                <LoadingButton loading={saving} sx={{ width: 100 }} size="small" variant='contained' onClick={save}>
                    <Localized id='save'>Save</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} sx={{ width: 100 }} size="small" variant='outlined' onClick={onCancel}>
                    <Localized id='cancel'>Cancel</Localized>
                </LoadingButton>
            </ButtonGroup>

        </div>
    </div>
}
