import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import { Localized, useLocalization } from "@fluent/react";
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, Grid, MenuItem, Paper, Tab, TableContainer, Typography } from "@mui/material";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { archiveUser, blockUser, createUser, deleteUserFunc, loadUser, resetUserPassword, selectUserById, sendUserInvitation, unlockUser, updateUser, User } from './usersSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUserCompanyId } from '../user/userSlice';
import { UserStatusLabel } from './UserStatusLabel';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { If } from '../../app/If';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { APIError } from '../../app/appTypes';
import BlockIcon from '@mui/icons-material/Block';
import KeyIcon from '@mui/icons-material/Key';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SystemEvent, loadSystemEventsForCompany } from '../systemSettings/systemEvents/systemEventsApi';
import { enUS, plPL } from '@mui/x-data-grid/locales';
import { selectSettings } from '../settings/settingsSlice';
import { useNavigate } from 'react-router-dom';

type FormTabs = "details" | "events"

export type UserFrom = {
    entity: User | undefined,
}

export const CreateCompanyUser = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const { l10n } = useLocalization();
    const { locale } = useAppSelector(selectSettings);
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectUserById(state, id));
    // const companyId = useAppSelector(state => selectUserCompanyId(state))
    const [user, setUser] = useState<User | undefined>(entity);
    const [companyId, setCompanyId] = useState<string>(entity?.companyId ?? "");
    const [userName, setUserName] = useState<string>(entity?.userName ?? "");
    const [email, setEmail] = useState<string>(entity?.email ?? "");
    const [firstName, setFirstName] = useState<string>(entity?.firstName ?? "");
    const [lastName, setLastName] = useState<string>(entity?.lastName ?? "");
    const [role, setRole] = useState<string>(entity?.role ?? "");
    const [phone, setPhone] = useState<string>(entity?.phone ?? "");
    const [status, setStatus] = useState<string>(entity?.status ?? "");
    const [position, setPosition] = useState<string>(entity?.position ?? "");
    const [lockedAt, setLockedAt] = useState<Date | string>(entity?.lockedAt ?? "");
    const [lockReason, setLockReason] = useState<string>(entity?.lockReason ?? "");
    const [lockReasonDialog, setLockReasonDialog] = useState<string>("");
    const [passwordDialog, setPasswordDialog] = useState<string>("");
    const dispatch = useAppDispatch();

    //errors handling
    const [userNameError, setUserNameError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [roleError, setRoleError] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [sendInvitation, setSendInvitation] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [unlockUserDialog, setUnlockUserDialog] = useState(false);
    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [blockUserDialog, setBlockUserDialog] = useState(false);
    const [archiveUserDialog, setArchiveUserDialog] = useState(false);
    const [systemEvents, setSystemEvents] = useState<SystemEvent[]>([]);

    const [saving, setSaving] = useState(false)

    const handleGoBackclick = useCallback(() => navigate(-1), [navigate])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string

    useEffect(() => {
        if(entity === undefined && location.pathname !== "/company/user/create") {
            dispatch(loadUser(id)).then(unwrapResult)
            .then(result => {
                setUser(result);
                setCompanyId(result.companyId);
                setUserName(result.userName);
                setEmail(result.email);
                setFirstName(result.firstName);
                setLastName(result.lastName);
                setRole(result.role);
                setPhone(result.phone);
                setStatus(result.status);
                setPosition(result.position);
                if(result.lockedAt !== null) {
                    setLockedAt(result.lockedAt);
                    setLockReason(result.lockReason);
                }
            })
            .catch((error) => console.error(error))
        }
        dispatch(loadSystemEventsForCompany({ companyId: loggedUserCompanyId, query: `?objectType=User&objectId=${id}` }))
            .then(unwrapResult)
            .then(setSystemEvents)
            .catch((error) => console.error(error))
    }, [dispatch, id, loggedUserCompanyId])

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'details'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleSaveClick = async () => {
        ClearErrors();
        if (!Validate()) {
            return;
        }
        if (companyId === null) return

        if (!user) {

            try {
                const actionResult = await dispatch(createUser({
                    userName, companyId, email,
                    firstName, lastName, role, phone, position, status, lockReason:"", lockedAt:null
                }));
                const result = unwrapResult(actionResult);

                setUser(result);
                setCompanyId(result.companyId);
                setUserName(result.userName);
                setEmail(result.email);
                setFirstName(result.firstName);
                setLastName(result.lastName);
                setRole(result.role);
                setPhone(result.phone);
                setStatus(result.status);
                setPosition(result.position);

                dispatch(showSuccess("saved"));

                navigate(`/company/user/edit/${result.id}`, { replace: true });

            } catch (error:any) {
                if(error && error.problem) {
                    dispatch(showError(error.problem.title));
                } else {
                    dispatch(showError("error"));
                }
            }
        } else {
            try {
                await dispatch(updateUser({
                    ...user,
                    userName, companyId, email,
                    firstName, lastName, role, phone, position, lockReason:"", lockedAt:null
                })).unwrap();


                dispatch(showSuccess("saved"));
            } catch (error) {
                dispatch(showError("error"));
            }
        }
    }

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Nieprawidłowy adres e-mail");
        }
        else {
            setEmailError("");
        }
    };

    function ClearErrors() {
        setUserNameError(false);
        setFirstNameError(false);
        setRoleError(false);
        setLastNameError(false);
        setEmailError("");
    }

    function Validate() {
        let valResult = true;
        validateEmail()
        if (userName === '') {
            valResult = false;
            setUserNameError(true);
        }
        if (role === '') {
            valResult = false;
            setRoleError(true);
        }
        if (firstName === '') {
            valResult = false;
            setFirstNameError(true);
        }
        if (lastName === '') {
            valResult = false;
            setLastNameError(true);
        }
        if (emailError !== '') {
            valResult = false;

        }

        return valResult;
    }

    const handleUserNameChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setUserName(e.target.value), [setUserName]);

    const handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => {
                setEmail(e.target.value)
                if(location.pathname === "/company/user/create") setUserName(e.target.value);
            }, [setEmail]);

    const handleFirstNameChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setFirstName(e.target.value), [setFirstName]);

    const handleLastNameChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setLastName(e.target.value), [setLastName]);

    const handleRoleChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setRole(e.target.value), [setRole]);

    const handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setPhone(e.target.value), [setPhone]);

    const handlePositionChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setPosition(e.target.value), [setPosition]);

    const handleUserLockReasonChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setLockReasonDialog(e.target.value), [setLockReasonDialog]);

    const handlePasswordChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setPasswordDialog(e.target.value), [setPasswordDialog]);

    const handleSendInvitationClick = useCallback(() => {
        setSendInvitation(true)
    }, [])

    const handleDeleteUserClick = useCallback(() => {
        setDeleteUser(true)
    }, [])

    const handleUnlockClick = useCallback(() => {
        setUnlockUserDialog(true)
    }, [])

    const handleResetClick = useCallback(() => {
        setResetPasswordDialog(true)
    }, [])

    const handleBlockUser = useCallback(() => {
        setBlockUserDialog(true)
    }, [])

    const handleArchiveUser = useCallback(() => {
        setArchiveUserDialog(true)
    }, [])

    const handleConfirmArchiveUserClick = async () => {
        if (user) {
            try {
                setSaving(true)
                const result = await dispatch(archiveUser({ email: user.email, reason: lockReasonDialog, password: passwordDialog }));
                if (archiveUser.fulfilled.match(result)) {
                    result.payload as User;
                    dispatch(showSuccess("saved"))
                    setStatus("ARCHIVED");
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
                setPasswordDialog("");
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setArchiveUserDialog(false)
            }
        }
    }

    const handleBlockUserClick = async () => {
        if (user) {
            try {
                setSaving(true)
                const result = await dispatch(blockUser({ email: user.email, lockreason: lockReasonDialog }));
                if(blockUser.fulfilled.match(result)) {
                    result.payload as User;
                    dispatch(showSuccess("saved"))
                    setStatus("LOCKED");
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setBlockUserDialog(false)
            }
        }
        setBlockUserDialog(false)
    }

    const handleResetPasswordClick = async () => {
        if (user) {
            try {
                setSaving(true)
                await dispatch(resetUserPassword({ email: user.email })).unwrap();
                dispatch(showSuccess("saved"))
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setResetPasswordDialog(false)
            }
        }
        setResetPasswordDialog(false)
    }

    const handleUnlockUserClick = async () => {
        if (user) {
            try {
                setSaving(true)
                await dispatch(unlockUser({ email: user.email })).unwrap();
                dispatch(showSuccess("saved"))

                setStatus("ACTIVE");
            }
            catch (error) {
                const apierror = error as APIError;
                if (apierror.kind === "http" && apierror.problem?.title) {
                    dispatch(showError(apierror.problem?.title));
                }
                else {
                    dispatch(showError("error"));
                }
            }
            finally {
                setSaving(false)
                setUnlockUserDialog(false)
            }
        }
        setUnlockUserDialog(false)
    }

    const handleConfirmSendInvitationClick = async () => {
        if (user) {
            try {
                setSaving(true)
                const result = await dispatch(sendUserInvitation({email: user.email}));

                if (sendUserInvitation.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    setStatus("INVITED");
                } else {
                    dispatch(showError("error"))
                }
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setSendInvitation(false)
            }
        }
        setSendInvitation(false)
    }

    const handleConfirmDeleteUserClick = async () => {
        if (user) {
            try {
                setSaving(true)
                const result = await dispatch(deleteUserFunc({email: user.email}));
                if (deleteUserFunc.fulfilled.match(result)) {
                    result.payload as User;
                    dispatch(showSuccess("saved"))
                    handleGoBackclick();
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setDeleteUser(false)
            }
        }
        setDeleteUser(false)
    }

    const handleCancelSendInvitationClick = async () => {
        setSendInvitation(false)
    }

    const handleCancelDeleteUserClick = async () => {
        setDeleteUser(false)
    }

    const handleCanceUnlockUserClick = async () => {
        setUnlockUserDialog(false)
    }

    const handleCanceResetPasswordClick = async () => {
        setResetPasswordDialog(false)
    }

    const handleCancelBlockUserClick = async () => {
        setBlockUserDialog(false)
    }

    const handleCancelArchiveUserClick = async () => {
        setArchiveUserDialog(false)
    }

    const columns: GridColDef<SystemEvent, any, any>[] = [
        {
            field: 'createdBy',
            headerName: l10n.getString("username", null, "username"),
            width: 300
        },
        {
            field: 'eventType',
            headerName: l10n.getString("system-events-type", null, "Zdarzenie"),
            flex: 1,
        },
        {
            field: 'eventDate',
            headerName: l10n.getString("system-events-date", null, "Data zdarzenia"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.eventDate !== null) return new Date(row.eventDate).toLocaleString();
            }
        },
        {
            field: 'description',
            headerName: l10n.getString("system-events-description", null, "Opis"),
            flex: 1,
        },
        {
            field: 'expired',
            headerName: l10n.getString("status", null, "Status"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.eventType === "SendInvitation" && row.eventDate !== null && (new Date().getTime() - new Date(row.eventDate).getTime()) / (1000 * 60 * 60) >= 24) return l10n.getString("system-events-expired", null, "Wygasło")
            }
        }
    ]

    return (
        <Container maxWidth="md" sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }}>
            <TabContext value={tab}>
                <TabList onChange={handleTabChange}>
                    <Tab
                        key={0}
                        value={"details"}
                        label={<Localized id="details">Szczegóły</Localized>} />
                    <Tab
                        key={1}
                        value={"events"}
                        label={<Localized id="system-events-history">Historia zdarzeń</Localized>} />
                </TabList>
                <TabPanel value="details">
                    <Paper sx={{ marginTop: "10px" }}>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    required
                                    disabled={true}
                                    inputProps={{ maxLength: 50 }}
                                    value={userName}
                                    onChange={handleUserNameChange}
                                    error={userNameError}
                                    label={<Localized id="username-login">Nazwa użytkownika / login</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography >
                                    <UserStatusLabel status={status}></UserStatusLabel>
                                </Typography >
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    value={firstName}
                                    error={firstNameError}
                                    onChange={handleFirstNameChange}
                                    label={<Localized id="user-first-name">Imię</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    fullWidth
                                    value={lastName}
                                    error={lastNameError}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={handleLastNameChange}
                                    label={<Localized id="user-last-name">Nazwisko</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    required
                                    sx={{ minWidth: 256 }}
                                    value={role}
                                    error={roleError}
                                    onChange={handleRoleChange}
                                    label={<Localized id="user-role">Rola</Localized>} >
                                    {["admin", "user"].map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    value={email}
                                    required
                                    onBlur={validateEmail}
                                    error={!!emailError}
                                    helperText={emailError}
                                    type={"email"}
                                    disabled={user && status !== "INVITED" && status !== "NOT_CONFIRMED"}
                                    onChange={handleEmailChange}
                                    inputProps={{ maxLength: 100 }}
                                    label={<Localized id="user-email">Email</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    value={phone}
                                    inputProps={{ maxLength: 20 }}
                                    onChange={handlePhoneChange}
                                    label={<Localized id="user-phone">Phone</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={position}
                                    inputProps={{ maxLength: 200 }}
                                    onChange={handlePositionChange}
                                    label={<Localized id="user-position">Stanowisko</Localized>}>
                                </TextField>
                            </Grid>
                            <If condition={lockReason !== ""}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        value={new Date(lockedAt).toLocaleString()}
                                        aria-readonly
                                        disabled={true}
                                        label={<Localized id="user-lock-date">Data blokady</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        value={lockReason}
                                        multiline
                                        rows={4}
                                        aria-readonly
                                        disabled={true}
                                        label={<Localized id="user-lock-reason">Powód blokady</Localized>}>
                                    </TextField>
                                </Grid>
                            </If>
                            <Grid item xs={4}>
                                <ButtonGroup color="secondary" variant="contained">
                                    <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                        <Localized id="save">Zapisz</Localized>
                                    </Button>
                                    <Button component={Link} to={`/company/users`} color="secondary" variant="outlined">
                                        <Localized id="back">Wróć</Localized>
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={8}>
                                <Box display="flex" justifyContent="flex-end">
                                    <If condition={status === "NOT_CONFIRMED" || status === "INVITED"}>
                                        <Button onClick={handleSendInvitationClick} color="primary" variant="outlined" endIcon={<SendIcon color='secondary' />}>
                                            <Localized id="invite">Zaproś</Localized>
                                        </Button>
                                        <Button onClick={handleDeleteUserClick} color="primary" variant="outlined" endIcon={<DeleteIcon color='secondary' />}>
                                            <Localized id="delete">Usuń</Localized>
                                        </Button>
                                    </If>
                                    <If condition={status === "LOCKED"}>
                                        <Button onClick={handleUnlockClick} color="primary" variant="outlined" endIcon={<LockOpenIcon color='secondary' />}>
                                            <Localized id="unlock">Odblokuj</Localized>
                                        </Button>
                                    </If>
                                    <If condition={status === "LOCKED" || status === "ACTIVE"}>
                                        <Button onClick={handleArchiveUser} color="primary" variant="outlined" endIcon={<LockOpenIcon color='secondary' />}>
                                            <Localized id="user-archive">Archiwizuj</Localized>
                                        </Button>
                                        <Button onClick={handleResetClick} color="primary" variant="outlined" endIcon={<KeyIcon color='secondary' />}>
                                            <Localized id="reset-password">Resetuj hasło</Localized>
                                        </Button>
                                    </If>
                                    <If condition={status === "ACTIVE"}>
                                        <Button onClick={handleBlockUser} color="primary" variant="outlined" endIcon={<BlockIcon color='error' />}>
                                            <Localized id="user-block">Zablokuj</Localized>
                                        </Button>
                                    </If>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>
                <TabPanel value="events">
                    <TableContainer component={Paper}>
                        <DataGrid localeText={getCustomLocaleText()}
                            style={{ minHeight: 100, height: 'calc(100vh - 240px)' }}
                            rows={systemEvents}
                            columns={columns}
                            initialState={{
                                sorting: { sortModel: [{ field: 'eventDate', sort: 'desc' }] }
                            }}
                        />
                    </TableContainer>
                </TabPanel>
            </TabContext>
            <Dialog open={sendInvitation}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-send-invitation">
                            <span>Do wybranego użytkownika zostanie wysłany mail powitalny, czy kontunuować?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSendInvitationClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelSendInvitationClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteUser}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-delete">
                            <span>Czy na pewno chcesz usunąć tego użytkownika?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmDeleteUserClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelDeleteUserClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={unlockUserDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-unlock-question">
                            <span>Czy odblokować wskazanego użytkownika?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleUnlockUserClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCanceUnlockUserClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={resetPasswordDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-reset-password-question">
                            <span>Czy zresetować hasło wskazanego użytkownika?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleResetPasswordClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCanceResetPasswordClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={blockUserDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-block-question">
                            <span>Czy zablokować użytkownika?</span>
                        </Localized>
                        <TextField
                            fullWidth
                            value={lockReasonDialog}
                            required
                            onChange={handleUserLockReasonChange}
                            type={"text"}
                            multiline
                            rows={4}
                            inputProps={{ maxLength: 1000 }}
                            label={<Localized id="user-lock-reason">Powód blokady</Localized>}>
                        </TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleBlockUserClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelBlockUserClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={archiveUserDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="user-archiving-question">
                            <span>Czy na pewno chcesz zarchiwizować użytkownika? Zarchwizowanie użytkownika spowoduje utratę dostępu do aplikacji</span>
                        </Localized>
                        <TextField
                            fullWidth
                            value={lockReasonDialog}
                            required
                            onChange={handleUserLockReasonChange}
                            type={"text"}
                            multiline
                            rows={4}
                            inputProps={{ maxLength: 1000 }}
                            label={<Localized id="user-archived-reason">Powód archiwizacji</Localized>}>
                        </TextField>
                        <TextField
                            sx={{ marginTop: "25px" }}
                            fullWidth
                            value={passwordDialog}
                            required
                            onChange={handlePasswordChange}
                            type={"password"}
                            inputProps={{ maxLength: 100 }}
                            label={<Localized id="">Hasło</Localized>}
                            helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}>
                        </TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmArchiveUserClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelArchiveUserClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
