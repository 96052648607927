import { DialogActions, DialogContent } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { Localized } from "@fluent/react";
import { useState } from "react";
import { DocumentHeader, resetWorkflow } from "../documentsApi";
import { showError, showSuccess } from "../../notifications/notificationsSlice";
import { useAppDispatch } from "../../../app/hooks";

interface DocumentResetWorkflowDialogProps {
    documentId: string;
    onSuccess: (document:DocumentHeader) => void;
    onCancel: () => void;
}

export const DocumentResetWorkflowDialog = ({ documentId, onSuccess, onCancel }:DocumentResetWorkflowDialogProps) => {
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState<boolean>(false);

    const handleSaveClick = async () => {
        try {
            setSaving(true)
            const result = await dispatch(resetWorkflow(documentId));
            if (resetWorkflow.fulfilled.match(result)) {
                onSuccess(result.payload);
                dispatch(showSuccess("saved"))
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    return <>
        <DialogContent>
            <Localized id="documents-overview-reset-wfl-message">Czy na pewno chcesz zresetować workflow? Tej akcji nie będzie można cofnąć!</Localized>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving}>
                <Localized id="yes">
                    <span>Tak</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="no">
                    <span>Nie</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}