import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../app/appTypes"
import { getWithAuth, postWithAuth } from "../../http"


export type DeviceValidationStatus = 'NOT_VALIDATED' | 'VALIDATED' | 'INPROGRESS'

export interface SupplierDeviceInstance {
    id: AppId;
    name: string;
    description: string;
    deviceTypeId: string;
    deviceTypeName: string;
    producerId: string;
    producerName: string;
    model: string;
    serialNo: string;
    productionYear: number;
    varrantyStatus: string;
    manufacturer: string;
    validationStatus: DeviceValidationStatus;
    guid: string;
}

export const loadSupplierDeviceInstances = createAsyncThunk<SupplierDeviceInstance[], void, AppThunkAPIType>(
    "supplier-devices/load-all", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/devices" }))
        const { payload } = result

        if (getWithAuth.fulfilled.match(result)) {
            return payload as SupplierDeviceInstance[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadSupplierDeviceInstance = createAsyncThunk<SupplierDeviceInstance, string, AppThunkAPIType>("supplier-devices/load-one", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/devices/${id}/`,
    }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SupplierDeviceInstance
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface CreateSupplierDeviceInstancePayload {
    name: string;
    deviceTypeId: string;
    serialNo: string;
    model: string;
    productionYear: number;
    description: string;
}

export const createSupplierDeviceInstance = createAsyncThunk<SupplierDeviceInstance, CreateSupplierDeviceInstancePayload, AppThunkAPIType>(
    "supplier-devices/create-device",
    async (device: CreateSupplierDeviceInstancePayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/devices",
            payload: device,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as SupplierDeviceInstance
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)