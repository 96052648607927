import { Button, Chip, IconButton, Stack } from "@mui/material"
import { StandardFilterMenu, FilterOptions } from "./StandardFilterMenu"
import { DateFilterMenu } from "./DateFilterMenu"
import { Localized, useLocalization } from "@fluent/react"
import SyncIcon from '@mui/icons-material/Sync';
import { useLocalStorage } from "../../../useLocalStorage"
import { useEffect } from "react";

interface DateObject {
    from: Date | "all";
    to: Date | "all";
}

export interface DocumentFilters {
    dateFrom: Date | "all";
    dateTo: Date | "all";
    status: string[];
    role: string[];
}

interface URSListFilters {
    date: DateObject;
    statusOptions: FilterOptions[];
    myRoleOptions: FilterOptions[];
}

interface DocumentFilterPanelProps {
    filterPanelName: string; 
    onFilterChange: (filters:DocumentFilters) => void;
}

export const DocumentFilterPanel = ({ filterPanelName, onFilterChange }:DocumentFilterPanelProps) => {
    const { l10n } = useLocalization();
    
    useEffect(() => {
        const statuses = filters.statusOptions.filter(f => f.selected).map((status) => status.uniqueName);
        const myRoles = filters.myRoleOptions.filter(f => f.selected).map((role) => role.uniqueName);

        onFilterChange({ dateFrom: filters.date.from, dateTo: filters.date.to, status: statuses, role: myRoles })

    }, []);

    const [filters, setFilters] = useLocalStorage<URSListFilters>(`${filterPanelName}Filter`, {
        date: { from: "all", to: "all" },
        statusOptions: [
            {
                uniqueName: "N",
                name: l10n.getString("document-status-n", null, "_Nowy"),
                selected: false
            },
            {
                uniqueName: "I",
                name: l10n.getString("document-status-i", null, "_Do zatwierdzenia"),
                selected: false
            },
            {
                uniqueName: "A",
                name: l10n.getString("document-status-a", null, "_Zatwierdzony"),
                selected: false
            },
            {
                uniqueName: "F",
                name: l10n.getString("document-status-f", null, "_Zakończony"),
                selected: false
            },
            {
                uniqueName: "C",
                name: l10n.getString("document-status-c", null, "_Korekta"),
                selected: false
            },
            {
                uniqueName: "P",
                name: l10n.getString("document-status-p", null, "_Opublikowany"),
                selected: false
            },
            {
                uniqueName: "O",
                name: l10n.getString("document-status-o", null, "_Opiniowanie"),
                selected: false
            },
            {
                uniqueName: "D",
                name: l10n.getString("document-status-d", null, "_Zaopiniowany"),
                selected: false
            },
            {
                uniqueName: "E",
                name: l10n.getString("document-status-e", null, "_Zaimportowany"),
                selected: false
            },
            {
                uniqueName: "R",
                name: l10n.getString("document-status-r", null, "_Odrzucony"),
                selected: false
            }
        ],
        myRoleOptions: [
            {
                uniqueName: "IN",
                name: "Jestem w zespole",
                checkOther: true,
                selected: false
            },
            {
                uniqueName: "C",
                name: l10n.getString("urs-role-creator"),
                selected: false
            },
            {
                uniqueName: "E",
                name: l10n.getString("urs-role-editor"),
                selected: false
            },
            {
                uniqueName: "R",
                name: l10n.getString("urs-role-reviever"),
                selected: false
            },
            {
                uniqueName: "A",
                name: l10n.getString("urs-role-approver"),
                selected: false
            },
            {
                uniqueName: "AR",
                name: l10n.getString("urs-role-reviever-and-approver"),
                selected: false
            },
            {
                uniqueName: "V",
                name: l10n.getString("urs-role-viewer"),
                selected: false
            },
            {
                uniqueName: "EXT_R",
                name: l10n.getString("urs-role-external-reviever"),
                selected: false
            }
        ]
    })

    const handleSubmit = (date) => {
        setFilters(prevFilters => ({ 
            ...prevFilters,
            date: { from: date.dateFrom, to: date.dateTo }
        }));
    }

    const updateOption = (type: string, name:string) => {
        console.log(name)
        if(type === "status") {
            setFilters(prevFilters => ({
                ...prevFilters,
                statusOptions: prevFilters.statusOptions.map(item =>
                    item.uniqueName === name ? { ...item, selected: !item.selected } : item
                )
            }))
        } else if(type === "myRole") {
            if(name === "IN") {
                const selectedINOption = filters.myRoleOptions.find(f => f.uniqueName === "IN").selected;
                setFilters(prevFilters => ({
                    ...prevFilters,
                    myRoleOptions: prevFilters.myRoleOptions.map(item => (
                        { ...item, selected: !selectedINOption }
                    ))
                }))
            } else {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    myRoleOptions: prevFilters.myRoleOptions.map(item => (
                        item.uniqueName === name ? { ...item, selected: !item.selected } : item
                    ))
                }))
            }
        }
    }

    const handleClearDateFilterClick = () => {
        setFilters(prevFilters => ({ 
            ...prevFilters,
            date: { from: "all", to: "all" }
        }));
    }

    const handleRefreshClick = () => {
        onFilterChange({ dateFrom: filters.date.from, dateTo: filters.date.to, status: filters.statusOptions.filter(f => f.selected).map((status) => status.uniqueName), role: filters.myRoleOptions.filter(f => f.selected).map((role) => role.uniqueName) })
    }

    const handleClearFiltersClick = () => {
        setFilters(prevFilters => ({ 
            date: { from: "all", to: "all" },
            statusOptions: prevFilters.statusOptions.map(item => ({
                ...item,
                selected: false
            })),
            myRoleOptions: prevFilters.myRoleOptions.map(item => ({
                ...item,
                selected: false
            }))
        }));

        onFilterChange({ dateFrom: "all", dateTo: "all", status: [], role: [] })
    }

    return (
        <>
            <Stack sx={{ paddingBottom: 1 }}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <DateFilterMenu allowAllOption defaultOption="all" currentOption={filters.date.from === "all" ? "all" : undefined} onSubmit={handleSubmit}/>
                    <StandardFilterMenu label="Status" multiple options={filters.statusOptions} onUpdate={(name) => updateOption("status", name)}></StandardFilterMenu>
                    <StandardFilterMenu label={l10n.getString("filters-my-role", null, "_Moja rola")} multiple options={filters.myRoleOptions} onUpdate={(name) => updateOption("myRole", name)}></StandardFilterMenu>
                    <IconButton
                        onClick={handleRefreshClick}>
                        <SyncIcon />
                    </IconButton>
                </Stack>
                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
                    {filters.date.from !== "all" ? <Chip label={new Date(filters.date.from).toLocaleDateString() + " - " + new Date(filters.date.to).toLocaleDateString()} size="small" onDelete={handleClearDateFilterClick}/> : null}
                    {filters.statusOptions.filter(f => f.selected).map((option) =>
                        <Chip label={<span><strong>Status: </strong>{option.name}</span>} size="small" onDelete={() => updateOption("status", option.uniqueName)}/>
                    )}
                    {filters.myRoleOptions.filter(f => f.selected).map((option) =>
                        <Chip label={<span><strong><Localized id="filters-my-role">_Moja rola</Localized>: </strong>{option.name}</span>} size="small" onDelete={() => updateOption("myRole", option.uniqueName)}/>
                    )}
                    {filters.statusOptions.some(o => o.selected) || filters.myRoleOptions.some(o => o.selected) || filters.date.from !== "all" ? <Button size="small" variant="text" onClick={handleClearFiltersClick}>
                        <Localized id="filters-clear-all-filters">_Wyczyść filtry</Localized>
                    </Button> : null}
                </Stack>
            </Stack>
        </>
    )
}