import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, Fab, Skeleton } from "@mui/material"
import { FlexDiv } from "../../../app/Utils"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import AddIcon from '@mui/icons-material/Add'
import { DocumentationProcessStatusLabel } from "./DocumentationProcessStatusLabel"
import { unwrapResult } from "@reduxjs/toolkit"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { useNavigate } from "react-router-dom"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import { ProjectProcessHeader } from "../projectProcess/ProjectProcessApiClient"
import { loadSupplierDocumentationProcesses } from "./documentationProcessApiClient"
import { CreateSupplierDocProcess } from "./CreateSupplierDocProcess"
import { LoadURSOffers } from "../../../app/AppDataLoader"

export const SupplierDocProcessList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [processes, setProcesses] = useState<ProjectProcessHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [createProcessDialogVisible, setCreateProcessDialogVisible] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSupplierDocumentationProcesses())
            .then(unwrapResult)
            .then(setProcesses)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    }, [])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const openCreateProcessDialog = useCallback(() => {
        setCreateProcessDialogVisible(true)
    }, [setCreateProcessDialogVisible])

    const handleHideDialog = useCallback(() => setCreateProcessDialogVisible(false), [])

    const onSuccess = useCallback((proc: ProjectProcessHeader) => {
        navigate(`/supplier-doc-process/edit/${proc.id}`);
    }, [dispatch])

    const handleGoToClick = (id) => {
        navigate(`/supplier-doc-process/edit/${id}`)
    };

    const processColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: l10n.getString('project-process', null, 'Proces'),
            flex: 1
        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),
            width: 200,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentationProcessStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleGoToClick(params.id)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 200px)' }} localeText={getCustomLocaleText()} rows={processes} columns={processColumns} slots={{ toolbar: QuickSearchToolbar }} onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                    </div>
                </div>
                <FlexDiv>
                    <Fab onClick={() => openCreateProcessDialog()} color="secondary" sx={{ marginTop: 2 }}>
                        <AddIcon />
                    </Fab>
                </FlexDiv>
            </Box>
            <Dialog open={createProcessDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
                <LoadURSOffers component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
                    <CreateSupplierDocProcess
                        no={""}
                        name={""}
                        onSucces={onSuccess}
                        onCancel={handleHideDialog}
                    />
                </LoadURSOffers>
            </Dialog>
        </>
    )
}
