import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Company, createCompany, createCompanyQuery, selectCompanyById, updateCompany } from "./companiesSlice";
import SaveIcon from '@mui/icons-material/Save';
import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import { Localized, useLocalization } from "@fluent/react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch, useAppSelector, useQueryId } from "../../app/hooks";
import { Box, Card, CardContent, Fab, Grid, Paper, Skeleton, Stack, Tab, Typography } from "@mui/material";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Link, useParams } from "react-router-dom";
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { AreasDialogBrowser } from "../areas/AreasDialogBrowser";
import produce from "immer";
import { selectAllAreas } from "../areas/areaSlice";
import { LoadAreas, LoadStructure } from "../../app/AppDataLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { StructureTreeComponent } from "../device/Structure";
import { getWithAuth, putWithAuth } from "../../http";
import { If } from "../../app/If";
import { checkStructureNodes, selectStructure, uncheckAllStructureNodes } from "../device/structureSlice";
import { store } from "../../app/store";
import TableContainer from '@mui/material/TableContainer';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enUS, plPL } from "@mui/x-data-grid/locales";
import { selectSettings } from "../settings/settingsSlice";
import { User, loadUsersForCompany } from "../users/usersSlice";
import { UserStatusLabel } from "../users/UserStatusLabel";
import { ContactPerson, loadCompanyToRegister } from "../companyToRegister/companiesToRegisterApi";
import { FlexDiv } from '../../app/Utils';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { LoadingContainer } from "../../app/LoadingContainer";
import { ModuleAccessTable } from "../modules/ModuleAccess";
import { SystemFeatureFlagsForCompany } from "../systemSettings/systemFeatureFlags/SystemFeatureFlagsForCompany";
import { AIAccessForCompany } from "../ai/AIAccessGrid";

const PREFIX = 'CompanyForm';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    paper: `${PREFIX}-paper`,
    shortNameField: `${PREFIX}-shortNameField`,
    nameField: `${PREFIX}-nameField`,
    field: `${PREFIX}-field`
};

export interface CompanyStructure {
    companyId: AppId
    structureId: AppId
}

export const loadCompanyStructure = 
    createAsyncThunk<CompanyStructure[], AppId, AppThunkAPIType>("company_structure_access/load", async (companyId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/company-structure-access/${companyId}`,
        }))

        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanyStructure[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const saveCompanyStructure = 
    createAsyncThunk<CompanyStructure[], [AppId, CompanyStructure[]], AppThunkAPIType>("company_structure_access/save", async ([companyId, items], { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/sys/company-structure-access/${companyId}`,
            payload: items,
        }))
        if (putWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

type FormTabs = "contact" /* | "areas" */ | "structure" | "modules" | "ai" | "featureFlags" | "users";

type StructureState = "empty" | "loading" | "loaded" | "error"

export type CompanyFormProps = {
    entity: Company | undefined,
}

export const CompanyForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const queryId = useQueryId();
    const entity = useAppSelector(state => selectCompanyById(state, id));
    const [shortName, setShortName] = useState<string>(entity?.shortName ?? "");
    const [name, setName] = useState<string>(entity?.name ?? "");
    const [isProducer, setIsProducer] = useState<boolean>(entity?.isProducer ?? false);
    const [isCustomer, setIsCustomer] = useState<boolean>(entity?.isCustomer ?? false);

    const [address1, setAddress] = useState<string>(entity?.address1 ?? "");
    const [address2, setAddressSecond] = useState<string>(entity?.address2 ?? "");
    const [city, setCity] = useState<string>(entity?.city ?? "");
    const [state, setState] = useState<string>(entity?.state ?? "");
    const [zipcode, setZipcode] = useState<string>(entity?.zipcode ?? "");
    const [country, setCountry] = useState<string>(entity?.country ?? "");
    const [contactPerson, setContactPerson] = useState<string>(entity?.contactPerson ?? "");
    const [email, setEmail] = useState<string>(entity?.email ?? "");
    const [phone, setPhone] = useState<string>(entity?.phone ?? "");
    const [mobile, setMobile] = useState<string>(entity?.mobile ?? "");
    const [user, setUser] = useState<ContactPerson>();
    const [showDialog, setShowDialog] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const allAreas = useAppSelector(selectAllAreas)    

    const [selection, setSelection] = useState<Set<AppId>>(entity?.areas ? new Set(entity?.areas.map(i => { return i.areaId; }).slice()) : new Set())
    //! Zakomentowane, bo wyświetlał się warning w konsoli
    // const rows = useMemo(() => allAreas.filter(({ id }) => selection.has(id) ), [allAreas, selection])
    

    const [shortNameError, setShortNameError] = useState(false);
    const [nameError, setNameError] = useState(false);
    
    const [tab, setTab] = useState<FormTabs>("contact");

    const [structure, setStructure] = useState<StructureState>("empty")

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const { locale } = useAppSelector(selectSettings);
    // const allUsers = useAppSelector(selectAllUsers);
    const { l10n } = useLocalization()
    const [allUsersForCompany, setAllUsersCompany] = useState<User[]>([]);

    useEffect(() => {
        if(queryId) {
            dispatch(loadCompanyToRegister(queryId)).then(unwrapResult).then(result => {
                console.log(result);
                setShortName(result.companyName);
                setName(result.companyName);
                setAddress(result.address);
                setCity(result.city);
                setState(result.state);
                setZipcode(result.zipcode);
                setCountry(result.country);
                setUser({ firstName: result.firstName, lastName: result.lastName, email: result.email, phone: result.phone })
                // setContactPerson(result.firstName + ' ' + result.lastName);
                // setEmail(result.email);
                // setPhone(result.phone);
            });
        } else {
            const load = async (companyId: AppId) => {
                return await dispatch(loadCompanyStructure(companyId)).unwrap()
            }
            if (tab === "structure" && structure === "empty") {
                setStructure("loading")
                load(id).then(x => {
                    dispatch(uncheckAllStructureNodes())
                    dispatch(checkStructureNodes(x.map(({ structureId }) => structureId)))
                    setStructure("loaded")
                }).catch(() => {
                    setStructure("error")
                })

            }
            
            if(tab === "users") {
                setIsLoading(true);
                dispatch(loadUsersForCompany(id)).then(unwrapResult).then(setAllUsersCompany).finally(() => setIsLoading(false));
            }
        }
    }, [dispatch, tab, structure, id, queryId])

    //! Zakomentowane, bo wyświetlał się warning w konsoli
    // const handleAddAreasClick = useCallback(() => {                 
    //     setShowDialog(true)
    // }, [ setShowDialog ])

    const handleCancelDialogClick = useCallback(() => {
        setShowDialog(false)
    }, [ setShowDialog ])    

    const handleSelectBrowserItem = useCallback((areaId: AppId) => {
        setSelection(
            produce(draft => {
                draft.add(areaId)
            })
        )
    }, [])

    const handleDeselectBrowserItem = useCallback((areaId: AppId) => {
        setSelection(
            produce(draft => {
                draft.delete(areaId)
            })
        )
    }, [])

    const handleCopyAreaClick = async () => {
        setShowDialog(false)                          
    }

    //! Zakomentowane, bo wyświetlał się warning w konsoli
    // const handleDeleteAreaClick = useCallback((areaId: AppId) => {
    //     setSelection(
    //         produce(draft => {
    //             draft.delete(areaId)
    //         })
    //     )
    // }, [])

    const handleSetPersonClick = async () => {
        if(user !== undefined) {
            setContactPerson(user.firstName + ' ' + user.lastName);
            setEmail(user.email);
            setPhone(user.phone);
        }           
    }


    function prepareAreas() {
        var areas = new Array<{ areaId: AppId; }>();

        for (const areadid of selection) {
            let result = allAreas.find(({ id }) => id === areadid);

            
            if (result) {
                areas.push({ areaId: result.id });
                
            }
        }
        return areas;
    }


    const handleSaveClick = async () => {
        ClearErrors();
        if (!Validate()){
            return;
        }

        var areas = prepareAreas();

        if (!entity) {
            try {
                if(queryId) {
                    const company = {
                        name, 
                        shortName,
                        isProducer,
                        isCustomer,                    
                        address1,
                        address2,
                        city,
                        state,
                        zipcode,
                        country,
                        contactPerson,
                        email,
                        phone,
                        mobile,
                        areas
                    }
                    const actionResult = await dispatch(createCompanyQuery({company, companyId: queryId }));
                    const { id: newId } = unwrapResult(actionResult);
    
                    dispatch(showSuccess("saved"));
    
                    navigate(`/company/edit/${newId}`, { replace: true });
                } else {
                    const actionResult = await dispatch(createCompany({
                        name, 
                        shortName,
                        isProducer,
                        isCustomer,                    
                        address1,
                        address2,
                        city,
                        state,
                        zipcode,
                        country,
                        contactPerson,
                        email,
                        phone,
                        mobile,
                        areas
                    }));
                    const { id: newId } = unwrapResult(actionResult);

                    dispatch(showSuccess("saved"));

                    navigate(`/company/edit/${newId}`, { replace: true });
                }
            } catch (error) {
                dispatch(showError("error"));
            }
        } else {
            try {
                await dispatch(updateCompany({...entity, 
                    name, 
                    shortName,
                    isProducer,
                    isCustomer,                    
                    address1,
                    address2,
                    city,
                    state,
                    zipcode,
                    country,
                    contactPerson,
                    email,
                    phone,
                    mobile,
                    areas
                }));
                if (structure === "loaded") {
                    const checkNodes = selectStructure(store.getState()).tree.multiselection
                    const items: CompanyStructure[] = Object.entries(checkNodes).map(([structureId, _]) => {
                        return {
                            structureId,
                            companyId: entity.id,
                        }
                    })
                    await dispatch(saveCompanyStructure([entity.id, items]))
                }
                dispatch(showSuccess("saved"));
            } catch (error) {
                dispatch(showError("error"));
            }
        }
    }


     function ClearErrors(){
        setShortNameError(false);
        setNameError(false);        
    }

    function Validate() {
        let valResult = true;
        if(shortName === '')
        {
            valResult = false;
            setShortNameError(true);
        }
        if(name === '')
        {
            valResult = false;
            setNameError(true);
        }
              
        return valResult;
    }
    const handleShortNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setShortName(e.target.value), [setShortName]);

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setName(e.target.value), [setName]);
    
    const handleAddressChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setAddress(e.target.value), [setAddress]);

    const handleAddressSecond: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setAddressSecond(e.target.value), [setAddressSecond]);
                
    const handleCityChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setCity(e.target.value), [setCity]);
    
    const handleStateChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setState(e.target.value), [setState]);        
    
    const handleZipCodeChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setZipcode(e.target.value), [setZipcode]);        
                            
    const handleCountryChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setCountry(e.target.value), [setCountry]);        
                                                    
    const handleContactPersonChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setContactPerson(e.target.value), [setContactPerson]);        
                                                                 
     const handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setEmail(e.target.value), [setEmail]); 

    const handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setPhone(e.target.value), [setPhone]);                 
                                
    const handleMobileChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setMobile(e.target.value), [setMobile]);                 
                
    const handleProducerChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setIsProducer(e.target.checked), [setIsProducer]);    

    const handleClientChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setIsCustomer(e.target.checked), [setIsCustomer]);   


    const handleTabChange = useCallback((_, newTab) => {
            setTab(newTab)
    }, [])
          
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'userName',
            headerName: l10n.getString("username", null, "username"),
            width: 250
        },
        {
            field: 'firstName',
            headerName: l10n.getString("user-first-name", null, "Imię"),
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: l10n.getString("user-last-name", null, "Nazwisko"),
            flex: 1,
        },
        {
            field: 'position',
            headerName: l10n.getString("user-position", null, "Stanowisko"),
            width: 250
        },
        {
            field: 'status',
            headerName: l10n.getString("user-status", null, "Status"),
            width: 200,

            valueGetter: (_, row) => {
                var status = row.status.toUpperCase();
                switch (status) {
                    case "LOCKED":
                        return l10n.getString("user-status-locked", null, "")
                    case "NOT_CONFIRMED":
                        return l10n.getString("user-status-not-confirmed", null, "")
                    case "ACTIVE":
                        return l10n.getString("user-status-active", null, "")
                    default:
                        return ''
                }
            },
            renderCell: (params) => <UserStatusLabel status={params.row.status} />
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`/user/edit/${params.row.id}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    return (
        <LoadAreas component={<Skeleton height="50vh" variant="rectangular" />}>
            <Container component={Paper} maxWidth={false} sx={{paddingTop:2}}>
                <Grid container spacing={2} >
                    <Grid  item xs={12}>
                        <TextField 
                            required                            
                            value={shortName}
                            sx = {{width: "25%"}}
                            onChange={handleShortNameChange}   
                            error={shortNameError}                      
                            label={<Localized id="companies-short-name">Nazwa skrócona</Localized>}></TextField>
                    </Grid>
                    <Grid  item xs={12}>                        
                        <TextField
                            required    
                            fullWidth
                            value={name}
                            onChange={handleNameChange}
                            error={nameError}                      
                            label={<Localized id="companies-name">Nazwa</Localized>}></TextField>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        <Tab 
                            key={0}
                            value={"contact"}
                            label={<Localized id="companies-contact-data">Kontakt</Localized>} />
                        {/* <Tab 
                            key={1}
                            value={"areas"}
                            label={<Localized id="companies-areas">Obszary działalności</Localized>} /> */}
                        <Tab 
                            key={2}
                            value={"structure"}
                            label={<Localized id="companies-structure">Struktura</Localized>} />
                        <Tab 
                            key={3}
                            value={"modules"}
                            label={<Localized id="companies-modules">Moduły</Localized>} />
                        <Tab 
                            key={4}
                            value={"ai"}
                            label={<Localized id="companies-ai">Ustawienia AI</Localized>} />
                        <Tab 
                            key={5}
                            value={"featureFlags"}
                            label={<Localized id="system-feature-flags">Ustawienia flag</Localized>} />
                        <Tab 
                            key={6}
                            value={"users"}
                            label={<Localized id="companies-users">Użytkownicy</Localized>} />

                    </TabList>
                    <TabPanel value="contact">
                        <Grid container spacing={2}>                   
                            <Grid container item xs={6} direction="column" >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isProducer}
                                                    onChange={handleProducerChange} />}
                                            
                                            label={<Localized id="companies-is-producer"><span>Producent</span></Localized>} />
                                    </FormGroup>
                                </Grid>  
                                <Grid container item xs={6} direction="column" >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    checked={isCustomer}
                                                    onChange={handleClientChange}/>}
                                            value={isCustomer}
                                            label={<Localized id="companies-is-customer"><span>Klient</span></Localized>} />
                                    </FormGroup>
                                </Grid>  
                            <Grid container item xs={6} direction="column" >
                                <Card className={classes.root} variant="outlined">
                                    <CardContent>                                  
                                        <Stack direction="column" spacing={2}>
                                            <Typography className={classes.title} color="textSecondary" >
                                                {<Localized id="companies-address-data">Dane adresowe</Localized> }
                                            </Typography>
                                            <TextField
                                                className={classes.field}
                                                value={address1}
                                                onChange={handleAddressChange}
                                                label={<Localized id="companies-address-first">Adres 1</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={address2}
                                                onChange={handleAddressSecond}
                                                label={<Localized id="companies-address-second">Adres 2</Localized> }></TextField>
                                            <Stack direction="row" spacing={2}>
                                                <TextField                                          
                                                    sx={{width: "50%"}}
                                                    value={city}
                                                    onChange={handleCityChange}
                                                    label={<Localized id="companies-address-city">Miasto</Localized> }></TextField>
                                                <TextField
                                                
                                                    sx={{width: "50%"}}
                                                    value={state}
                                                    onChange={handleStateChange}
                                                    label={<Localized id="companies-address-state">Stan</Localized> }></TextField>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    sx={{width: "50%"}}
                                                    value={zipcode}
                                                    onChange={handleZipCodeChange}
                                                    label={<Localized id="companies-address-zip-code">Zip/Postal code</Localized> }></TextField>
                                                <TextField
                                                    sx={{width: "50%"}}
                                                    value={country}
                                                    onChange={handleCountryChange}
                                                    label={<Localized id="companies-address-country">Country</Localized> }></TextField>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>  
                            <Grid container item xs={6} direction="column" >
                                <Card className={classes.root} variant="outlined">
                                    <CardContent>                

                                        <Stack direction="column" spacing={2}>
                                            <Typography className={classes.title} color="textSecondary" >
                                                {<Localized id="companies-contact-data">Dane kontaktowe</Localized> }
                                            </Typography>
                                            <TextField
                                                className={classes.field}
                                                value={contactPerson}
                                                onChange={handleContactPersonChange}
                                                label={<Localized id="companies-contact-person">Osoba kontaktowa</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={email}
                                                onChange={handleEmailChange}
                                                label={<Localized id="companies-email">Email</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                label={<Localized id="companies-phone">Tel</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={mobile}
                                                onChange={handleMobileChange}
                                                label={<Localized id="companies-mobile">Mobile</Localized> }></TextField>
                                        </Stack>
                                        <If condition={queryId !== undefined}>
                                            <ButtonGroup sx={{marginTop:4}}  color="secondary" variant="contained">
                                                <Button onClick={handleSetPersonClick}>
                                                    <Localized id="create-user">Utwórz użytkownika</Localized>
                                                </Button>
                                            </ButtonGroup>
                                        </If>
                                    </CardContent>
                                </Card>
                            </Grid>  
                        </Grid>                                  
                    </TabPanel>
                    {/* <TabPanel value="areas">
                        <Grid container spacing={2}  sx={{
                                display: tab === "areas" ? 'flex' : 'none',
                            }} >
                                <Table sx ={{minHeight:100}}>
                                <TableHead>
                                    <TableRow>    
                                        <TableCell id="area-code">
                                            <Localized id="area-code">
                                                <span>Kod</span>
                                            </Localized>                                    
                                        </TableCell>                           
                                        <TableCell id="area-name">
                                            <Localized id="area-name">
                                                <span>Nazwa obszaru</span>
                                            </Localized>                                        
                                        </TableCell>
                                        <TableCell sx={{
                                            width: '160px',
                                        }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                        {rows.map(({ id, code, name }) => 
                                            <TableRow key={id}>     
                                                <TableCell sx = {{minWidth:80, width:80, maxWidth:80}}>{code}</TableCell>                                                                           
                                                <TableCell>{name}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup>                                              
                                                        <Button color="primary"  onClick={() => handleDeleteAreaClick(id)}>
                                                            <Localized id="delete">
                                                                <span>Usuń</span>
                                                            </Localized>
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <ButtonGroup sx={{marginLeft:1, marginTop:2, display: 'flex'}} color="secondary" size="small" variant="outlined">                            
                                    <Button  onClick={handleAddAreasClick}>
                                        <Localized id="companies-add-area">
                                            <span>Wybierz</span>
                                        </Localized>
                                    </Button>
                                </ButtonGroup> 
                        </Grid> 
                    </TabPanel> */}
                    <TabPanel value="structure">
                        <If condition={entity !== undefined && structure === "loaded"} otherwise={<LoadingContainer/>}>
                            <LoadStructure component={<Skeleton />}>
                                <Box sx={{
                                    maxHeight: 'calc(100vh - 420px)',
                                    overflowY: 'auto',
                                }}>
                                    <StructureTreeComponent editable={false} multiselect={true} showCounter={true} />
                                </Box>
                            </LoadStructure>
                        </If>
                    </TabPanel>
                    <TabPanel value="modules">
                        <ModuleAccessTable companyId={entity?.id} />
                    </TabPanel>
                    <TabPanel value="featureFlags">
                        <SystemFeatureFlagsForCompany companyId={entity?.id} />
                    </TabPanel>
                    <TabPanel value="ai">
                        <AIAccessForCompany companyId={entity?.id} companyName={entity?.name} />
                    </TabPanel>
                    <TabPanel value="users">
                        <TableContainer component={Paper}>
                            {isLoading ? (<LoadingContainer/>) : (
                            <DataGrid localeText={getCustomLocaleText()}
                                style={{ minHeight: 100, height: 'calc(100vh - 525px)' }}
                                rows={allUsersForCompany as User[]}
                                columns={columns}
                                initialState={{
                                    sorting: { sortModel: [{ field: 'userName', sort: 'asc' }] }
                                }}
                            />)}
                        </TableContainer>
                        <If condition={id !== undefined}>
                            <FlexDiv>
                                <Fab sx={{
                                        marginTop: 2,
                                    }} 
                                    component={Link} 
                                    to={`/user/create?id=${id}`} 
                                    color="secondary" 
                                >
                                    <AddIcon />
                                </Fab>
                            </FlexDiv>
                        </If>
                    </TabPanel>
                </TabContext>
                <Box sx={{paddingBottom:2}}>
                    <ButtonGroup sx={{marginTop:4}}  color="secondary" variant="contained">
                        <If condition={ tab !== "users" && tab !== "modules" && tab !== "ai" && tab !== "featureFlags" }>
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                        </If>
                        <Button  component={Link} to={`/companies`} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </Box>
                <AreasDialogBrowser
                    selection={selection} 
                    open={showDialog}
                    itemChecked={handleSelectBrowserItem}   
                    itemUnchecked={handleDeselectBrowserItem}               
                    handleOkClick={handleCopyAreaClick}
                    handleCancelClick={handleCancelDialogClick}                    
                />
                </Container>
            </LoadAreas>
    );
}
