import { Urs, UrsActionType } from "./UrsSlice"
import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import { Localized } from "@fluent/react"

interface UrsDetailsPanelProps {
    entity: Urs,
    editable: boolean,
    isUrsLinkAvail: (action: UrsActionType, urs: Urs) => boolean;
    handleEditUrsNumber: () => void;
    handleEditSimpleDescriptionFieldClick: (field: 'purpose' | 'description' | 'area' | 'title', value: string) => void;
    handleEditDescriptionFieldClick: (field: 'purpose' | 'description' | 'area', value: string) => void;
}

export const UrsDetailsPanel = ({
    entity,
    editable,
    isUrsLinkAvail,
    handleEditUrsNumber,
    handleEditSimpleDescriptionFieldClick,
    handleEditDescriptionFieldClick }: UrsDetailsPanelProps) => {

    return (

        <Stack direction="column" spacing={2}>
            {[
                {
                    id: "document-number",
                    label: "Numer dokumentu",
                    content: entity.ursNo || "Brak ...",
                    editable: isUrsLinkAvail("changeursnumber", entity),
                    editAction: handleEditUrsNumber,
                },
                {
                    id: "urs-title",
                    label: "Tytuł dokumentu",
                    content: entity.title || "Brak tytułu...",
                    editable: isUrsLinkAvail("changeurstitle", entity),
                    editAction: () =>
                        handleEditSimpleDescriptionFieldClick("title", entity.title || ""),
                },
                {
                    id: "urs-purpose",
                    label: "Cel",
                    content: <div dangerouslySetInnerHTML={{ __html: entity.purpose || "" }} />,
                    editable: editable,
                    editAction: () =>
                        handleEditDescriptionFieldClick("purpose", entity.purpose || ""),
                },
                {
                    id: "urs-description",
                    label: "Opis",
                    content: <div dangerouslySetInnerHTML={{ __html: entity.description || "" }} />,
                    editable: editable,
                    editAction: () =>
                        handleEditDescriptionFieldClick("description", entity.description || ""),
                },
                {
                    id: "urs-area",
                    label: "Obszar działania",
                    content: <div dangerouslySetInnerHTML={{ __html: entity.area || "" }} />,
                    editable: editable,
                    editAction: () =>
                        handleEditDescriptionFieldClick("area", entity.area || ""),
                },
            ].map(({ id, label, content, editable, editAction }) => (
                <Paper
                    key={id}
                    elevation={2}
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        backgroundColor: "background.paper",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Typography variant="subtitle2" gutterBottom>
                        <Localized id={id}>
                            <span>{label}</span>
                        </Localized>
                    </Typography>
                    <Box
                        sx={{
                            padding: 1,
                            marginLeft: 2,
                            borderRadius: 1,
                            backgroundColor: "grey.100",
                        }}
                    >
                        <Typography variant="body1">{content}</Typography>
                    </Box>
                    {editable && (
                        <Button
                            variant="text"
                            size="small"
                            onClick={editAction}
                            sx={{ marginTop: 1 }}
                        >
                            <Localized id="edit" />
                        </Button>
                    )}
                </Paper>
            ))}
        </Stack>
    );
}
