import { Localized } from "@fluent/react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DocumentCreationMode, DocumentNumberingMode } from "../documentsApi"
import { Alert, Button, ButtonGroup, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Help } from "../../dialogs/HelpDialog"
import { If } from "../../../app/If"
import { UrsHeader } from "../../urs/UrsSlice"
import { selectAllUrs } from "../../urs/ursListSlice"
import { AppTextFieldHandler } from "../../../app/appTypes"
import { LoadURSTemplates } from "../../../app/AppDataLoader"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import { StructureName } from "../../device/Structure"
import { createRiskAnalysis, RiskAnalysisHeader } from "./riskAnalysisApi"
import { loadSettingsForCompany } from "../../systemSettings/systemDocumentSettings/SystemDocumentSettingsApi"
import { unwrapResult } from "@reduxjs/toolkit"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>
type FormTabs = "purpose" | "description" | "area"

export const CreateRiskAnalysis = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { state, search } = useLocation()
    const location = useLocation();

    const isSupplierRiskAnalysis = location.pathname.includes('supplier-risk-analysis');
    
    const params = new URLSearchParams(search)


    const [documentNumber, setDocumentNumber] = useState("")
    const [title, setTitle] = useState("")
    const [language, setLanguage] = useState("PL")
    const [selectedOption, setSelectedOption] = useState<DocumentCreationMode>("Empty");
    const [numberingMode, setNumberingMode] = useState<DocumentNumberingMode>("DECIMAL_HIERARCHICAL");
    const [showForType, setShowForType] = useState(true);
    const allUrs = useAppSelector(selectAllUrs)

    useEffect(() => {
        if(selectedOption === "Empty") {
            dispatch(loadSettingsForCompany(`?documentCode=URS`))
                .then(unwrapResult)
                .then((response) => {
                    const numMode = response.find(f => f.name === "NUMBERING_MODE");
                    if(numMode !== undefined) {
                        setNumberingMode(numMode.value as DocumentNumberingMode);
                    }
                })
                .catch((error) => console.error(error))
        }
    }, [selectedOption])

    const [selectedUrs, setSelectedUrs] = useState<UrsHeader | undefined>(() => {
        if (state && state[`defaultUrsId`]) {
            const urs = allUrs.find(x => x.id == state[`defaultUrsId`])
            setSelectedOption("Document")
            if (urs) {
                return urs
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    })


    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [templateDialogVisible, setTemplateDialogVisible] = useState<boolean>(false);
    const [ursBasedOnDialogVisible, setUrsBasedOnDialogVisible] = useState<boolean>(false);


    const [tab, setTab] = useState<FormTabs>("purpose");
    const numberError = validationErrors && documentNumber === ""
    const titleError = validationErrors && title === ""
    const ursError = validationErrors && selectedOption === "Document" && selectedUrs === undefined

    const UrsDocuments = useMemo(() => {
        return allUrs
    }, [allUrs, showForType])


    const handleShowForTypeChange = (event) => {
        setShowForType(event.target.checked);
    };

    const handleNumberingModeChange = (value) => {
        setNumberingMode(value);
    };

    const handleTitleChange: AppTextFieldHandler = useCallback(e => {
        setTitle(e.target.value)
    }, [])

    const handleDocumentNumberChange: AppTextFieldHandler = useCallback(e => {
        setDocumentNumber(e.target.value)
    }, [])

    const handleTemplateClick = () => {
        setSelectedOption('Template')
    }

    const handleUrsClick = () => {
        setSelectedOption('Document')
    }

    const hadleEmptyClick = () => {
        setSelectedOption('Empty')
        setSelectedUrs(undefined)
    }

    const handleChooseUrsClick = useCallback((newUrs: UrsHeader) => {
        setSelectedUrs(newUrs)
        setUrsBasedOnDialogVisible(false)
    }, [])

    const handleCancelUrsClick = useCallback(() => {
        setUrsBasedOnDialogVisible(false)
    }, [])


    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const handleSaveButtonClick = async () => {
        setValidationErrors(true)

        if (documentNumber && title) {
            if ((selectedOption === "Document" && selectedUrs === undefined)) {
                return
            }
            setSaving(true)
            try {
                const resultSettings = await dispatch(loadSettingsForCompany(`?documentCode=RiskAnalysis`))
                if(loadSettingsForCompany.fulfilled.match(resultSettings)) {
                    const goodThreshold = resultSettings.payload.find(f => f.name === "GOOD_THRESHOLD");
                    const warningThreshold = resultSettings.payload.find(f => f.name === "WARNING_THRESHOLD");

                    const result =
                        await dispatch(createRiskAnalysis({
                            documentType: "RISK_ANALYSIS",
                            creationMode: selectedOption,
                            structureId: "",
                            documentNumber: documentNumber,
                            templateId: "",
                            documentId: selectedUrs?.id || "",
                            title: title,
                            language: language,
                            settings: {
                                numberingMode: numberingMode,
                                goodThreshold: goodThreshold !== undefined ? goodThreshold.value : "0",
                                warningThreshold: warningThreshold !== undefined ? warningThreshold.value : "0",
                            }
                        })
                        )
                    if (createRiskAnalysis.fulfilled.match(result)) {
                        const newDocument = result.payload as RiskAnalysisHeader;
                        dispatch(showSuccess("saved"))
                        if (isSupplierRiskAnalysis)
                            {
                                navigate(`/supplier-risk-analysis/edit/${newDocument.id}`, { replace: true })

                            }
                            else{
                                navigate(`/risk-analysis/edit/${newDocument.id}`, { replace: true })

                            }
                    }
                    else {
                        let errorMsg = "error"
                            if (result.payload) {
                                if (result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title
                                }
                            }
                        }
                        dispatch(showError(errorMsg))
                    }
                } else {
                    let errorMsg = "error"
                        if (resultSettings.payload) {
                            if (resultSettings.payload.kind === 'http') {
                            const problem = resultSettings.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }} component={Paper} maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextField
                        helperText={numberError && <RequiredError />}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={documentNumber}
                        error={numberError}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleDocumentNumberChange}
                        label={<Localized id=""><span>Numer dokumentu</span></Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ width: '80%' }} >
                        <InputLabel id="app-language-label"><Localized id='document-language'>Język</Localized></InputLabel>
                        <Select
                            labelId="app-language-label"
                            id="app-language"
                            value={language}
                            onChange={e => setLanguage(e.target.value)}
                            label={<Localized id='document-language'>Język</Localized>}
                            fullWidth
                        >
                            <MenuItem value={'PL'}>PL</MenuItem>
                            <MenuItem value={'EN'}>EN</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        helperText={titleError && <RequiredError />}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={title}
                        error={titleError}
                        onChange={handleTitleChange}
                        label={<Localized id=""><span>Tytuł</span></Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,

                        }}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    size="small"
                                    color={selectedOption === 'Empty' ? "secondary" : "primary"}
                                    onClick={() => hadleEmptyClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="">Pusty</Localized>
                                </Button>
                                <Button
                                    size="small"
                                    color={selectedOption === 'Document' ? "secondary" : "primary"}
                                    onClick={() => handleUrsClick()}
                                >
                                    URS
                                </Button>

                            </InputAdornment>
                        }}
                        label={<Fragment>
                            <Localized id="">Jak wygenerować dokument?</Localized>
                            <Help id="8be28b75-4ca8-4967-8cdb-91a3a1828c2e" />
                        </Fragment>
                        }
                    ></TextField>

                </Grid>
                <If condition={selectedOption == "Template"}>
                    <Grid item xs={8}>
                       
                    </Grid>
                </If>
                <If condition={selectedOption == "Document"}>
                    <Grid item xs={8}>
                        <LoadURSTemplates component={<Skeleton />}>
                            <TextField
                                label={<span>URS</span>}
                                value={selectedUrs?.ursNo}
                                fullWidth
                                required
                                error={ursError}
                                helperText={ursError && <RequiredError />}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            onClick={() => setUrsBasedOnDialogVisible(true)}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            >
                            </TextField>
                        </LoadURSTemplates>
                    </Grid>
                </If>
                <If condition={selectedOption == "Empty"}>
                    <Grid item xs={8}>
                        <TextField
                            value={numberingMode}
                            sx={{ width: '50%' }}
                            select
                            label={<Fragment>
                                <Localized id="urs-numbering-method">Sposób numerowania  </Localized>
                                <Help id="7e0300fb-e1cf-4810-aab7-183f5e68d975" />
                            </Fragment>
                            }
                            onChange={e => handleNumberingModeChange(e.target.value)}
                        >

                            <MenuItem value={"DECIMAL_HIERARCHICAL"}><Localized id="urs-hierarchical"><span>Hierarchiczne</span></Localized></MenuItem>
                            <MenuItem value={"UNIQUE_REQ"}><Localized id="urs-unique"><span>Unikalne</span></Localized></MenuItem>
                            <MenuItem value={"ONLY_SIMPLE_REQ"}><Localized id="urs-simple"><span>Proste</span></Localized></MenuItem>
                        </TextField>
                        
                    </Grid>
                </If>
               
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="secondary">
                        <Button onClick={handleSaveButtonClick} disabled={saving} startIcon={<SaveIcon />}>
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                        <Button onClick={handleBackButtonClick} variant="outlined">
                            <Localized id="cancel">Anuluj</Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            
            <Dialog open={ursBasedOnDialogVisible} fullWidth maxWidth="md">
                <DialogTitle>
                    <span>URS</span>
                </DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showForType}
                                onChange={handleShowForTypeChange}
                                color="primary"
                            />
                        }
                        label={<Localized id="urs-create-show-for-device-type">
                            <span>Pokaż tylko zgodne z typem urządzenia</span>
                        </Localized>
                        }
                    />
                    <TableContainer
                        component={Paper}
                        sx={{ display: 'flex', height: 'calc(100vh - 440px)' }}
                    >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Localized id="urs-number">Numer URS</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="urs-device-type">Typ urzadzenia</Localized>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UrsDocuments.map(row => {
                                    return <TableRow key={row.id}>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}><Typography component="span">{row.ursNo}</Typography></TableCell>
                                        <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}><StructureName structureId={row.deviceTypeId} /></TableCell>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Button color="primary" variant="outlined" onClick={() => handleChooseUrsClick(row)} >
                                                <Localized id="select">
                                                    <span>Wybierz</span>
                                                </Localized>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUrsClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
