import { Localized } from "@fluent/react";
import { DeviceValidationStatus } from "../supplierDeviceInstanceApi";

export const SupplierDeviceValidationStatusLabel = ({ status, styled = true }
    : { status: DeviceValidationStatus; styled?: boolean; }) => {

    const statusMapping: Record<DeviceValidationStatus, { color: string; labelId: string }> = {
        NOT_VALIDATED: { color: "bg-red-200", labelId: "validation-status-not-validated" },
        VALIDATED: { color: "bg-green-200", labelId: "validation-status-validated" },
        INPROGRESS: { color: "bg-blue-200", labelId: "validation-status-inprogress" },
    };

    const mapping = statusMapping[status];

    if (!mapping) {
        return <span></span>;
    }

    return styled ?
        (
            <span className={`inline-block px-2 py-1 rounded ${mapping.color}`}>
                <Localized id={mapping.labelId} />
            </span>
        ) :
        (
            <Localized id={mapping.labelId} />
        );
};
