import { Localized, useLocalization } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import {  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ProjectProcess } from "../projectProcess/ProjectProcessApiClient"
import { generateSupplierDocuments } from "./documentationProcessApiClient"
import { selectAllSpecs, Spec } from "../../specs/specSlice"
import { useState } from "react"


export interface GenerateSupplierDocumentsProps {
    processId: AppId
    onSave: (project: ProjectProcess) => void
    onCancel: () => void
}


export const GenerateSupplierDocuments = (props: GenerateSupplierDocumentsProps) => {
    const dispatch = useAppDispatch()
    const { processId } = props
    const [saving, setSaving] = useState(false)
    const { l10n } = useLocalization()

    const allSpecs = useAppSelector(state => selectAllSpecs(state))
    const [spec, setSpec] = useState<Spec | undefined>()

    const [selectedDocument, setSelectedDocument] = useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedDocument(event.target.value as string);
    };

    const handleDeviceChange = (event: SelectChangeEvent) => {
        const newDevice = allSpecs.find(({ id }) => id.toString() === event.target.value)
        if (newDevice) {
            setSpec(newDevice)
        }
    }

    const handleOkClick = () => {
        setSaving(true)
        if (spec && selectedDocument) {
            console.log(document)
            if (document) {
                dispatch(generateSupplierDocuments({
                    processId: processId,
                    specId: spec?.id,
                    document: selectedDocument
                }))
                    .then(unwrapResult)
                    .then(project => props.onSave(project))
                    .catch(err => console.error(err))
                    .finally(() => setSaving(false))
            }
        }
    }

    return <>
        <Dialog open={true} fullWidth maxWidth='sm'>
            <DialogTitle>
                <Localized id=''>Generate documentation</Localized>
            </DialogTitle>
            <DialogContent>
                <FormControl sx={{ marginTop: 2 }} fullWidth>

                    <Typography variant="body2" gutterBottom>
                        Select Device specification
                    </Typography>
                    <Select
                        value={spec?.id.toString() ?? ""}
                        label={<Localized id='device-type-name'>Device</Localized>}
                        onChange={handleDeviceChange}
                    >
                        {allSpecs.map(({ id, name }) => <MenuItem value={id.toString()}>{name}</MenuItem>)}
                    </Select>
                    <Typography variant="body2" gutterBottom>
                        Select Document Type
                    </Typography>
                    <Select
                        id="document-select"
                        value={selectedDocument}
                        onChange={handleChange}
                        label="Document Type"
                    >
                        <MenuItem value="FS&DS">FS & DS (two documents)</MenuItem>
                        <MenuItem value="FDS">FDS (One document)</MenuItem>
                    </Select>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} onClick={handleOkClick}>
                    <Localized id='ok'>OK</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={props.onCancel}>
                    <Localized id='cancel'>Anuluj</Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
