import { Localized } from "@fluent/react";
import { Alert, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";
import { showError, showSuccess } from "../../notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CompanySupplierAssigmentSetting, CompanySupplierAssigmentSettingPayload, createSupplierAssigmentsSettings, updateSupplierAssigmentsSettings } from "./companySettingsAPI";
import { ManagementRoleType } from "../../utils/managedObject";
import { selectAllUsers } from "../../users/usersSlice";


type AddEditCompanySettingsDialogMode = 'create' | 'edit' | 'delete'

export interface AddEditCompanySettingsDialogProps {
    companySupplierAssigmentSetting: CompanySupplierAssigmentSetting | undefined
    mode: AddEditCompanySettingsDialogMode;
    onSuccessfullySaved: (updatedGroup: CompanySupplierAssigmentSetting) => void;
    onCancel: () => void
}

export const AddEditCompanySettingDialog: React.FC<AddEditCompanySettingsDialogProps> = ({ companySupplierAssigmentSetting, mode, onSuccessfullySaved, onCancel }) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")
    const allUsers = useAppSelector(selectAllUsers);
    const [user, setUser] = useState<string>(companySupplierAssigmentSetting !== undefined ? companySupplierAssigmentSetting.user : "");
    const [role, setRole] = useState<ManagementRoleType>(companySupplierAssigmentSetting !== undefined ? companySupplierAssigmentSetting.role : "APPROVER");

    const handleUserChange = useCallback(e => {
        setUser(e.target.value)
    }, [])

    const handleRoleChange = useCallback(e => {
        setRole(e.target.value)
    }, [])

    const handleSaveClick = async () => {
        setSaving(true)

        try {
            switch (mode) {
                case "create":
                    if (user && role) {

                        const newSetting: CompanySupplierAssigmentSettingPayload = {
                            user: user,
                            event: "NEW_URS_REQUEST",
                            role: role,
                            notifyUser: true
                        };

                        const result = await dispatch(createSupplierAssigmentsSettings(newSetting))
                        if (createSupplierAssigmentsSettings.fulfilled.match(result)) {
                            const newSupplierAssigmentsSetting = result.payload as CompanySupplierAssigmentSetting;
                            dispatch(showSuccess("saved"))
                            onSuccessfullySaved(newSupplierAssigmentsSetting)
                        } else {
                            let errorMsg = "error"
                            if (result.payload && result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title

                                }
                            }
                            setServerError(errorMsg)
                        }

                    }

                    break
                case "edit":

                    if (user && role) {

                        const updatedSetting: CompanySupplierAssigmentSettingPayload = {
                            id: companySupplierAssigmentSetting?.id,
                            user: user,
                            event: "NEW_URS_REQUEST",
                            role: role,
                            notifyUser: true
                        };

                        const result = await dispatch(updateSupplierAssigmentsSettings(updatedSetting))
                        if (updateSupplierAssigmentsSettings.fulfilled.match(result)) {
                            const newSupplierAssigmentsSetting = result.payload as CompanySupplierAssigmentSetting;
                            dispatch(showSuccess("saved"))
                            onSuccessfullySaved(newSupplierAssigmentsSetting)
                        } else {
                            let errorMsg = "error"
                            if (result.payload && result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title

                                }
                            }
                            setServerError(errorMsg)
                        }

                    }

                    break
                case "delete":
                    break;
            }

        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    return <>
        <Dialog open={true} maxWidth="xs" fullWidth>
            <DialogContent>
                <Stack direction="column" spacing={2} padding={2}>
                    <FormControl>
                        <InputLabel id="app-user-label"><Localized id='user'>Użytkownik</Localized></InputLabel>
                        <Select
                            labelId="app-user-label"
                            value={user}
                            onChange={handleUserChange}
                            label={<Localized id='user'>User</Localized>}
                            fullWidth
                        >
                            {allUsers.filter(f => f.status === "ACTIVE").map((option, index) =>
                                <MenuItem key={index} value={option.id}>{option.userName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="app-role-label"><Localized id='company-settings-role'>Rola</Localized></InputLabel>
                        <Select
                            labelId="app-role-label"
                            value={role}
                            onChange={handleRoleChange}
                            label={<Localized id='company-settings-role'>Rola</Localized>}
                            fullWidth
                        >
                            <MenuItem value="PROCESS_OWNER">PROCESS_OWNER</MenuItem>
                            <MenuItem value="PROCESS_MANAGER">PROCESS_MANAGER</MenuItem>
                            <MenuItem value="EDITOR">EDITOR</MenuItem>
                            <MenuItem value="COLLABORATOR">COLLABORATOR</MenuItem>
                            <MenuItem value="APPROVER">APPROVER</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleSaveClick} loading={saving}>
                    <Localized id="save">
                        <span>Zapisz</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={onCancel}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}

