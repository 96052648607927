import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useState } from "react";
import DeleteForever from '@mui/icons-material/DeleteForever';

interface DeleteConfirmationDialogProps {
    open: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
    open,
    onConfirm,
    onCancel,
}) => {
    const [deleting, setDeleting] = useState(false);

    const handleConfirmClick = async () => {
        setDeleting(true);
        try {
            await onConfirm();
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Dialog maxWidth="md" open={true} onClose={onCancel}>
            <DialogTitle>
                <Localized id="confirm-delete">
                    <span>Czy na pewno chcesz usunąć?</span>
                </Localized>
            </DialogTitle>
            <DialogActions>
                <LoadingButton loading={deleting} onClick={handleConfirmClick} startIcon={<DeleteForever />} size="small" color="error" variant="contained">
                    <Localized id="yes">
                        <span>Tak</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton loading={deleting} onClick={onCancel} size="small" variant="outlined">
                    <Localized id="no">
                        <span>Nie</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
