import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit"
import {AppDataState, AppId, AppThunkAPIType, unknownError} from "../../app/appTypes"
import {RootState} from "../../app/store"

export type Uuid = string

export interface BlobMeta {
    fileMeta : FileMeta
    blobId   : string
}

export interface FileMeta {
    fileName    : string
    contentType : string
    sha         : string
}

export interface Blobs {
    resourceId: Uuid
    blobs: BlobMeta[] | null
}

export const loadBlobs = createAsyncThunk<BlobMeta[], Uuid, AppThunkAPIType>(
    "blobs/load", 
    async (resourceId, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}`, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return await response.json() as BlobMeta[]
        } catch (error) {
            return rejectWithValue({ kind: 'unknown' })    
        }
})

export const loadManyBlobs = createAsyncThunk<Blobs[], [AnyDocId, Uuid[]], AppThunkAPIType>(
    "blobs/loadMany", 
    async ([_, resourceIds], { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BLOB_API}/query`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resourceIds),
            })
            const responseData = await response.json() as [Uuid, BlobMeta[] | null][]
            return responseData.map(([resId, blobsMeta]): Blobs => {
                const blobs: Blobs = { 
                    resourceId: resId,
                    blobs: blobsMeta ?? [],
                }
                return blobs
            }) 
        } catch (error) {
            return rejectWithValue({ kind: 'unknown' })    
        }
})

export const uploadBlobs = createAsyncThunk<BlobMeta[], [Uuid, File[]], AppThunkAPIType>
    ("blobs/upload", async ([resourceId, files], { rejectWithValue }) => {
        const fd = new FormData()
        files.forEach(file => {
            fd.append("file", file)
        }) 

        try {
            const response = await fetch(`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}`, {
                method: 'POST',
                mode: 'cors',
                body: fd,
            })    
            return await response.json() as BlobMeta[]
        } catch (error) {
            console.log(error) 
            return rejectWithValue({ kind: 'unknown' })
        }
})

export const deleteBlob = createAsyncThunk<void, [Uuid, string], AppThunkAPIType>("blobs/delete", async ([resourceId, blobId], { rejectWithValue }) => {
    try {
        await fetch(`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blobId}`, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
        })
    } catch (error) {
        return rejectWithValue({ kind: 'unknown' })    
    }

})

export const buildBlobUri = (resourceId: string, bm: BlobMeta): string => {
    return `${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${bm.blobId}`
}

const adapter = createEntityAdapter<Blobs>({
    selectId: blob => blob.resourceId
})

export interface AnyDocId {
    docType : "urs" | "materialCard" | "offer" | "project" | "process" | "meetingMinute" | "document"
    docId   : AppId
}

export type BlobsState = EntityState<Blobs> & {
    state: AppDataState
    anyDocId: AnyDocId | null
}

const initialState: BlobsState = adapter.getInitialState({
    state: { type: 'empty' },
    anyDocId: null,
})

export const ursAttachements = createSlice({
    name: 'ursAttachements',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadManyBlobs.pending, (state, action) => {
            state.state = {
                type: 'loading',
                requestId: action.meta.requestId,
            }
            const [docId] = action.meta.arg
            state.anyDocId = docId
        })
        builder.addCase(loadManyBlobs.fulfilled, (state, action) => {
            adapter.removeAll(state)
            adapter.setMany(state, action.payload)
            state.state = {
                type: 'loaded'
            }
        })
        builder.addCase(loadManyBlobs.rejected, (state, action) => {
            state.state = {
                type: 'error',
                error: action.payload ?? unknownError()
            }
        })
        builder.addCase(deleteBlob.fulfilled, (state, action) => {
            const [resourceId, blobId] = action.meta.arg
            const entity = state.entities[resourceId]
            if (entity) {
                const { blobs } = entity
                adapter.updateOne(state, {
                    id: resourceId,
                    changes: {
                        blobs: blobs ? blobs.filter(x => x.blobId !== blobId) : []
                    }
                })
            }
        })
        builder.addCase(uploadBlobs.fulfilled, (state, action) => {
            const [resourceId] = action.meta.arg
            const entity = state.entities[resourceId]
            if (entity) {
                const { blobs } = entity
                adapter.updateOne(state, {
                    id: resourceId,
                    changes: {
                        blobs: action.payload,
                    }
                })
            } else {
                adapter.setOne(state, {
                    resourceId,
                    blobs: action.payload,
                })
            }
        })
    }
})

export const selectUrsAttachements = (state: RootState) => state.ursAttachements

export const {
    selectAll  : selectAllBlobs,
    selectById : selectBlobsByResourceId,
} = adapter.getSelectors(selectUrsAttachements)

export default ursAttachements.reducer

