import { ReactNode } from "react"
import { Localized } from "@fluent/react"
import { StructureName } from "../../device/Structure"
import { SupplierDeviceInstance } from "../supplierDeviceInstanceApi"
import { SupplierDeviceValidationStatusLabel } from "./SupplierDeviceValidationStatusLabel"

interface HeaderFieldProps {
    children: ReactNode
}
const HeaderField = (props: HeaderFieldProps) => {
    return <div className="px-2 py-1 text-lg">{props.children}</div>
}
interface HeaderLabelProps {
    children: ReactNode
}
const HeaderLabel = (props: HeaderLabelProps) => {
    return <div className="text-xs font-bold px-2 py-1 bg-gray-100">{props.children}</div>
}


export const SupplierDeviceInstanceHeader = ({ device }: { device: SupplierDeviceInstance }) => {
    return <>
        <div className="grid grid-cols-3 border-solid border rounded border-gray-300 shadow">
            <HeaderLabel><Localized id="devices-serial-number"><span>_Serial no</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="devices-type"><span>_Typ urządzenia</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="devices-validation-status"><span>_status</span></Localized></HeaderLabel>

            <HeaderField>{device.serialNo}</HeaderField>
            <HeaderField><StructureName structureId={device.deviceTypeId} /></HeaderField>
            <HeaderField>
                <SupplierDeviceValidationStatusLabel status={device.validationStatus} />
            </HeaderField>
        </div>
    </>
}
