import { Localized } from "@fluent/react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState, useCallback } from "react";
import { AppId } from "../../app/appTypes";
import { useAppSelector } from "../../app/hooks";
import produce from "immer";
import { selectAllSuppliers } from "../suppliers/suppliersSlice";
import { selectAllCompanies } from "../company/companiesSlice";
import { selectUserCompanyId } from "../user/userSlice";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";

type FormTabs = "external" | "internal"


export const CompanyDialogBrowser = (
    { open,processing, filter, handleConfirmPublishClick, handleCancelPublishClick }:
        {
            open: boolean,
            processing: boolean,
            filter:  Set<AppId>,
            handleConfirmPublishClick: (ids: Set<AppId>,  suppliersIds : Set<AppId>) => void,
            handleCancelPublishClick: () => void
        }) => {

    const [selection, setSelection] = useState<Set<AppId>>(new Set<AppId>())
    const [selectionSupplier, setSupplierSelection] = useState<Set<AppId>>(new Set<AppId>())

    const itemChecked = useCallback((id: AppId) => {
        setSelection(
            produce(draft => {
                draft.add(id)
            })
        )
    }, [])
    const itemUnchecked = useCallback((id: AppId) => {
        setSelection(
            produce(draft => {
                draft.delete(id)
            })
        )
    }, [])

    const supplierChecked = useCallback((id: AppId) => {
        setSupplierSelection(
            produce(draft => {
                draft.add(id)
            })
        )
    }, [])

    const supplierUnchecked = useCallback((id: AppId) => {
        setSupplierSelection(
            produce(draft => {
                draft.delete(id)
            })
        )
    }, [])

    
    const handleConfirmClick = async () => {
        if(selectionSupplier.size > 0 || selection.size > 0){
            handleConfirmPublishClick(selection, selectionSupplier)
        }
    }

    return <>
        <Dialog open={open} fullWidth={true} maxWidth="md" >
            <DialogContent>
                <DialogContentText>
                    <Localized id="urs-publish-question">
                        <span>Czy opublikować URS dla dostawców?</span>
                    </Localized>
                </DialogContentText>
                <CompaniesBrowser
                    selection={selection}
                    selectionSupplier={selectionSupplier}
                    filter={filter}
                    itemChecked={itemChecked}
                    itemUnchecked={itemUnchecked}
                    supplierChecked={supplierChecked}
                    supplierUnchecked={supplierUnchecked}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={processing} onClick={handleConfirmClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={processing} onClick={handleCancelPublishClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>;
}

export const CompaniesBrowser =
    (
        { selection, selectionSupplier, filter, itemChecked, itemUnchecked , supplierChecked, supplierUnchecked}:
            {
                selection: Set<AppId>,
                selectionSupplier: Set<AppId>,

                filter: Set<AppId>,
                itemChecked: (id: AppId) => void,
                itemUnchecked: (id: AppId) => void,
                supplierChecked:(id: AppId) => void,
                supplierUnchecked: (id: AppId) => void,
            }
    ) => {

        const [tab, setTab] = useState<FormTabs>("external");

        const companyId = useAppSelector(selectUserCompanyId) as string
        
        const allCompanies = useAppSelector(selectAllCompanies)
            .filter(x => x.id !== companyId && x.isProducer)
            .sort((a, b) => a.shortName.localeCompare(b.shortName))


            
        const allSuppliers = useAppSelector(state => selectAllSuppliers(state).filter(x => !filter.has(x.id)))
            .sort((a, b) => a.shortName.localeCompare(b.shortName))

        const handleSupplierCheckboxChange = (id: AppId, checked: boolean) => {
            if (checked) {
                supplierChecked(id)
            } else {
                supplierUnchecked(id)
            }
        }

        const handleCheckboxChange = (id: AppId, checked: boolean) => {
            if (checked) {
                itemChecked(id)
            } else {
                itemUnchecked(id)
            }
        }

        const handleTabChange = useCallback((_, newTab) => {
            setTab(newTab)
        }, [])

        return <>
            <TableContainer
                component={Paper}
                sx={{ marginTop: 2,  height: 'calc(100vh - 440px)' }}
            >
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        <Tab 
                            key={0}
                            value={"external"}
                            label={<Localized id="supplier-external">External</Localized>} /> 
                        <Tab 
                            key={1}
                            value={"internal"}
                            label={<Localized id="supplier-internal">Internal</Localized>} /> 
                    </TabList>
                    <TabPanel value="internal">   
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Localized id="companies-short-name">Nazwa skrócona</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="companies-name">Nazwa</Localized>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allCompanies.map(({ id, shortName, name }) => {
                                    return <TableRow key={id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selection.has(id)}
                                                onChange={(_, checked) => handleCheckboxChange(id, checked)}
                                            />
                                        </TableCell>
                                        <TableCell>{shortName}</TableCell>
                                        <TableCell>{name}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table> 
                    </TabPanel>
                    <TabPanel value="external">              
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Localized id="companies-short-name">Nazwa skrócona</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="companies-name">Nazwa</Localized>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allSuppliers.map(({ id, shortName, name }) => {
                                    return <TableRow key={id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectionSupplier.has(id)}
                                                onChange={(_, checked) => handleSupplierCheckboxChange(id, checked)}
                                            />
                                        </TableCell>
                                        <TableCell>{shortName}</TableCell>
                                        <TableCell>{name}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TabPanel>
                </TabContext>
            </TableContainer>
        </>;
    }