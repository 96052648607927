import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  TextField,
  IconButton,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import TestPhaseTab from './TestPhaseTab';
import { TestPhase, TestSpecification, TestSubType, updateTestSpecification } from './testSpecificationApi';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { showError, showSuccess } from '../notifications/notificationsSlice';
import { useAppDispatch } from '../../app/hooks';
import { LoadingButton } from '@mui/lab';
import { Localized } from '@fluent/react';
import SaveIcon from '@mui/icons-material/Save'

interface EditTestSpecificationDialogProps {
  testSpecification?: TestSpecification;
  onSave: () => void;
  onCancel: () => void
}

export const EditTestSpecificationDialog = ({
  testSpecification,
  onSave,
  onCancel
}: EditTestSpecificationDialogProps) => {
  const dispatch = useAppDispatch()
  const [fullScreen, setFullScreen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [testName, setTestName] = useState(testSpecification?.name);
  const [testDescription, setTestDescription] = useState(testSpecification?.description);
  const [testPhases, setTestPhases] = useState<TestPhase[]>(testSpecification?.testPhases || []);
  const [saving, setSaving] = useState(false)

  const toggleFullScreen = () => setFullScreen((prev) => !prev);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSave = async () => {
    if (testSpecification && testName) {
      setSaving(true)
      try {
        const result = await dispatch(updateTestSpecification({
          ...testSpecification,
          name: testName,
          description: testDescription ?? "",
          testPhases: testPhases ?? [],
        }));

        if (updateTestSpecification.fulfilled.match(result)) {
          dispatch(showSuccess("saved"))
          onSave()
        }
        else {
          let errorMsg = "error"
          if (result.payload) {
            if (result.payload.kind === 'http') {
              const problem = result.payload.problem
              if (problem) {
                errorMsg = problem.title
              }
              dispatch(showError(errorMsg))
            }
          }
        }

      } catch (error) {

        dispatch(showError("error"))
      }
      setSaving(false)
    }
  }


  const updatePhaseSteps = (phaseId: number, updatedSteps: TestPhase['steps']) => {
    setTestPhases((prev) =>
      prev.map((phase) =>
        phase.id === phaseId ? { ...phase, steps: updatedSteps } : phase
      )
    );
  };

  const updatePhaseIsActive = (phaseId: number, isActive: boolean) => {
    setTestPhases((prev) =>
      prev.map((phase) =>
        phase.id === phaseId ? { ...phase, isActive } : phase
      )
    );
  };

  const updatePhaseSubType = (phaseId: number, subType: TestSubType) => {
    setTestPhases((prev) =>
      prev.map((phase) =>
        phase.id === phaseId ? { ...phase, subType } : phase
      )
    );
  };

  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth={fullScreen ? undefined : 'md'}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          Edit test
          <IconButton onClick={toggleFullScreen} style={{ float: 'right' }} color="primary">
            {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              label={<Localized id="test-name">_test-name</Localized>}
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
              sx={{ marginTop: "5px" }}
              fullWidth
            />
            <TextField
              label={<Localized id="test-description">_test-description</Localized>}
              value={testDescription}
              onChange={(e) => setTestDescription(e.target.value)}
              fullWidth
              multiline
              rows={2}
            />
            <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
              {testPhases.map((phase) => (
                <Tab
                  key={phase.id}
                  label={
                    <Box display="flex" alignItems="center" gap={1}>
                      <span>{phase.phaseType}</span>
                      {!phase.isActive && <RemoveCircleOutlineIcon color="error" fontSize="small" />}
                      {phase.isActive && <CheckCircleOutlineIcon color="success" fontSize="small" />}
                    </Box>
                  }
                />
              ))}
            </Tabs>
            {testPhases.map(
              (phase, index) =>
                activeTab === index && (
                  <TestPhaseTab
                    key={phase.id}
                    phase={phase}
                    onUpdateSteps={(updatedSteps) =>
                      updatePhaseSteps(phase.id, updatedSteps)
                    }
                    onUpdateIsActive={(isActive) =>
                      updatePhaseIsActive(phase.id, isActive)
                    }
                    onUpdatePhaseSubType={(subType) =>
                      updatePhaseSubType(phase.id, subType)
                    }

                  />
                )
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={saving} onClick={handleSave} startIcon={<SaveIcon />} size="small" color="success" variant="contained">
            <Localized id="save">
              <span>save</span>
            </Localized>
          </LoadingButton>
          <LoadingButton loading={saving} onClick={onCancel} size="small" variant="outlined">
            <Localized id="cancel">
              <span>cancel</span>
            </Localized>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

