import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http"
import { DocumentHeader, DocumentSeetings, DocumentTypes } from "../documentsApi"

export interface RiskAnalysisHeader extends DocumentHeader {

}

export type RiskAnalysisDocument = RiskAnalysisHeader & {
    groups: RiskAnalysisGroup[]
    // functions: RiskAnalysisFunction[]
}

export interface RiskAnalysisGroup {
    id: AppId
    code: string
    name: string
    numeration: string
    guid: string
    functions: RiskAnalysisFunction[]
    groups: RiskAnalysisGroup[]
}

export interface RiskAnalysisFunction {
    id: AppId
    name: string
    numeration: string
    guid: string
    criticality: string | null
    defects: RiskAnalysisDefect[]
    isTestable: boolean
    testDefinitionId: AppId | null
    currentControlMethod: string
    recommendedAction: string
    hasDQ: boolean
    hasIQ: boolean
    hasOQ: boolean
    hasPQ: boolean
}

export interface RiskAnalysisDefect {
    id: AppId
    name: string
    guid: string
    value: number
    consequences: RiskAnalysisConsequence[]
    functionId: AppId
    generated: boolean
}

export interface RiskAnalysisConsequence {
    id: AppId
    name: string
    guid: string
    value: number 
    reasons: RiskAnalysisReason[]
    severity: number
    generated: boolean
}

export interface RiskAnalysisReason {
    id: AppId
    name: string
    guid: string
    value: number
    currentControlMethod: string
    generated: boolean
    occurrenceProbability: number
    detectionProbability: number
}

export interface RiskAnalysisSettings {
    numberingMode: string,
    goodThreshold: string,
    warningThreshold: string,
}

export interface CreateRiskAnalysisPayload {
    documentType: DocumentTypes,
    creationMode: string,
    structureId: string,
    documentNumber: string,
    templateId: string,
    documentId: string,
    title: string,
    language: string,
    settings: RiskAnalysisSettings
}

export const loadAllRiskAnalysisDocuments = createAsyncThunk<RiskAnalysisHeader[], void, AppThunkAPIType>("/risk-analysis/load-list",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/risk-analysis` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadAllRiskAnalysisDocumentsByFilters = createAsyncThunk<RiskAnalysisHeader[], string, AppThunkAPIType>("/risk-analysis/load-list",
    async (filters, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/risk-analysis${filters}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const loadRiskAnalysis = createAsyncThunk<RiskAnalysisDocument, string, AppThunkAPIType>(
    "risk-analysis/load", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/risk-analysis/${id}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisDocument
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createRiskAnalysis = createAsyncThunk<RiskAnalysisHeader, CreateRiskAnalysisPayload, AppThunkAPIType>(
    "risk-analysis/create",
    async (createPayload: CreateRiskAnalysisPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/risk-analysis/create-document",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export interface CreateRiskAnalysisGroup {
    documentId: string
    code: string
    name: string
    systemTypeId: string | null
    parentGroupGuid: string | null
}
export const createRiskAnalysisGroup = createAsyncThunk<RiskAnalysisGroup, CreateRiskAnalysisGroup, AppThunkAPIType>(
    "risk-analysis/create-group",
    async (args, { rejectWithValue, dispatch }) => {
        const { documentId } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${documentId}/sections/add`,
            payload: args,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisGroup
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export interface UpdateGroupPayload {
    docId: AppId
    id: AppId
    code: string
    name: string
}
export const updateGroup = createAsyncThunk<RiskAnalysisGroup, UpdateGroupPayload, AppThunkAPIType>(
    'risk-analysis/update-group',
    async (args, { dispatch, rejectWithValue }) => {
        const { docId, ...data } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/sections/edit`,
            payload: data,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisGroup
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteGroup = createAsyncThunk<AppId, { docId: AppId, groupId: AppId }, AppThunkAPIType>('risk-analysis/delete-group', async (args, api) => {
    const { docId, groupId } = args
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/risk-analysis/${docId}/sections/${groupId}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return groupId
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export interface CreateFunction {
    documentId: AppId
    name: string
    criticality: string
    isTestable: boolean
    currentControlMethod: string
    testDefinitionId: number | null
    parentSectionGuid: AppId | null
}
export const createFunction = createAsyncThunk<RiskAnalysisFunction, CreateFunction, AppThunkAPIType>(
    "risk-analysis/create-function",
    async (args, { rejectWithValue, dispatch }) => {
        const { documentId, ...data } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${documentId}/content/function`,
            payload: data,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisFunction
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export type UpdateFunctionPayload  = Omit<RiskAnalysisFunction, 'defects'> & { docId: AppId }

export const updateFunction = createAsyncThunk<RiskAnalysisFunction, UpdateFunctionPayload, AppThunkAPIType>(
    'risk-analysis/update-function',
    async (args, { dispatch, rejectWithValue }) => {
        const { docId, id, ...data } = args
        const result = await dispatch(putWithAuth({
            url: `api/risk-analysis/${docId}/content/function/${id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisFunction
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteFunction = createAsyncThunk<AppId, { docId: AppId, functionId: AppId }, AppThunkAPIType>('risk-analysis/delete-function', async (args, api) => {
    const { docId, functionId } = args
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/risk-analysis/${docId}/function/${functionId}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return functionId
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export interface CreateDefectPayload {
    docId: AppId
    name: string
    functionId: AppId
    value: number
}
export const createDefect = createAsyncThunk<RiskAnalysisDefect, CreateDefectPayload, AppThunkAPIType>(
    "risk-analysis/create-defect",
    async (createPayload: CreateDefectPayload, { rejectWithValue, dispatch }) => {
        const { docId, ...reqPayload } = createPayload
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/content/defects`,
            payload: reqPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisDefect
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const genDefectAI = createAsyncThunk<RiskAnalysisDefect[], { docId: AppId, fnId: AppId }, AppThunkAPIType>(
    'risk-analysis/gen-defect-ai',
    async (args, { dispatch, rejectWithValue }) => {
        const { fnId, docId } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/functions/${fnId}/defects/generate`,
            payload: {},
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisDefect[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    },
)

export interface UpdateDefectPayload {
    docId: AppId
    id: AppId
    functionId: AppId
    name: string
}
export const updateDefect = createAsyncThunk<RiskAnalysisDefect, UpdateDefectPayload, AppThunkAPIType>(
    'risk-analysis/update-defect',
    async (args, { dispatch, rejectWithValue }) => {
        const { docId, ...data } = args
        const result = await dispatch(putWithAuth({
            url: `api/risk-analysis/${docId}/content/defects/${data.id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisDefect
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteDefect = createAsyncThunk<AppId, { docId: AppId, defectId: AppId }, AppThunkAPIType>('risk-analysis/delete-defect', async (args, api) => {
    const { docId, defectId } = args
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/risk-analysis/${docId}/content/defects/${defectId}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return defectId
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export interface CreateReasonPayload {
    docId: AppId
    name: string
    consequenceId: AppId
    value: number
    currentControlMethod: string
}
export const createReason = createAsyncThunk<RiskAnalysisReason, CreateReasonPayload, AppThunkAPIType>(
    "risk-analysis/create-reason",
    async (args: CreateReasonPayload, { rejectWithValue, dispatch }) => {
        const { docId, ...reqPayload } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/content/reasons`,
            payload: reqPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisReason
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const genReasonAI = createAsyncThunk<RiskAnalysisReason[], { docId: AppId, consequenceId: AppId }, AppThunkAPIType>(
    'risk-analysis/gen-reason-ai',
    async ({ docId, consequenceId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/consequences/${consequenceId}/reasons/generate`,
            payload: {},
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisReason[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    },
)

export interface UpdateReasonPayload {
    docId: AppId
    id: AppId
    name: string
    consequenceId: AppId
    currentControlMethod: string
    occurrenceProbability: number
    detectionProbability: number
}
export const updateReason = createAsyncThunk<RiskAnalysisReason, UpdateReasonPayload, AppThunkAPIType>(
    'risk-analysis/update-reason',
    async (args, { dispatch, rejectWithValue }) => {
        const { docId, id, ...data } = args
        const result = await dispatch(putWithAuth({
            url: `api/risk-analysis/${docId}/content/reasons/${id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisReason
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteReason = createAsyncThunk<AppId, { docId: AppId, id: AppId }, AppThunkAPIType>('risk-analysis/delete-reason', async (args, api) => {
    const { dispatch, rejectWithValue } = api
    const { id, docId } = args
    const result = await dispatch(deleteWithAuth({
        url: `api/risk-analysis/${docId}/content/reasons/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export interface CreateConsequencePayload {
    docId: AppId
    name: string
    defectId: AppId
    value: number
}

export const createConsequence = createAsyncThunk<RiskAnalysisConsequence, CreateConsequencePayload, AppThunkAPIType>(
    "risk-analysis/create-consequence",
    async (args: CreateConsequencePayload, { rejectWithValue, dispatch }) => {
        const { docId, ...data } = args
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/content/consequences`,
            payload: data,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisConsequence
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const genConsequenceAI = createAsyncThunk<RiskAnalysisConsequence[], { docId: AppId, defectId: AppId }, AppThunkAPIType>(
    'risk-analysis/gen-consequence-ai',
    async ({ docId, defectId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/risk-analysis/${docId}/defects/${defectId}/consequences/generate`,
            payload: {},
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisConsequence[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    },
)

export interface UpdateConsequencePayload {
    docId: AppId
    id: AppId
    name: string
    defectId: AppId
    severity: number
}
export const updateConsequence = createAsyncThunk<RiskAnalysisConsequence, UpdateConsequencePayload, AppThunkAPIType>(
    'risk-analysis/update-consequence',
    async (args, { dispatch, rejectWithValue }) => {
        const { docId, id, ...data } = args
        const result = await dispatch(putWithAuth({
            url: `api/risk-analysis/${docId}/content/consequences/${id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as RiskAnalysisConsequence
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteConsequence = createAsyncThunk<AppId, { docId: AppId, id: AppId }, AppThunkAPIType>('risk-analysis/delete-consequence', async (args, api) => {
    const { docId, id } = args
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/risk-analysis/${docId}/content/consequences/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})
