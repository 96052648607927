import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId, load } from "../../app/crud";
import { RootState } from "../../app/store";
import { getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";

export interface Device {
    id: AppId,
    name: string,
    deviceTypeId: string,
    deviceTypeName: string,
    producerId: string,
    producerName: string,
    model: string,
    serialNo: string,
    productionYear: number,
    description: string
    varrantyStatus: string
    manufacturer: string
}

const adapter = createEntityAdapter<Device>({
    selectId: (device) => device.id,
})

export type DraftDevice = Omit<Device, "id">

export type DevicesState = EntityState<Device> & { state: AppDataState, moduleId: AppId | undefined }

const initialState: DevicesState = adapter.getInitialState({
    state: { type: "empty" },
    moduleId: undefined,
})



export const loadDeviceDetails = createAsyncThunk<Device, string, AppThunkAPIType>("devices/load-details",
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/devices/${id}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Device
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadDevices = load<AppId, Device>(
    "devices/load",
    moduleId => `api/devices?moduleId=${moduleId}`,
    state => selectDevices(state).state,
)

export const createDevice = createAsyncThunk<Device, DraftDevice, AppThunkAPIType>(
    "devices/create",
    async (device: DraftDevice, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/devices",
            payload: device,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Device
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateDevice = createAsyncThunk<Device, Device, AppThunkAPIType>(
    "devices/update",
    async (device: Device, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/devices/${device.id}`,
            payload: device,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return device
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteDevice = createAsyncThunk("devices/delete", async (id: AppId) => {
    return id;
})


export const deviceSlice = createSlice({
    name: "devices",
    initialState,
    reducers: {
        clearDeviceSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createDevice.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteDevice.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateDevice.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<Device, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        builder.addCase(loadDevices.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadDevices.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadDevices.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
                state.moduleId = action.meta.arg
            }
        })
        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const { clearDeviceSlice } = deviceSlice.actions

export const selectDevices = (state: RootState): DevicesState => state.devices

export const
    { selectAll: selectAllDevices
        , selectById: selectDeviceById
        , selectTotal: selectTotalDevices
    } = adapter.getSelectors<RootState>(selectDevices)

export default deviceSlice.reducer;
