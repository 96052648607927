import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useDataGridCustomLocaleText } from '../../app/hooks'
import { useEffect, useState } from 'react'
import { Box, ButtonGroup } from '@mui/material'
import { store } from '../../app/store'
import { FlexDiv } from '../../app/Utils'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { selectCompanyById } from '../company/companiesSlice'
import { loadSupplierDeviceInstances, SupplierDeviceInstance } from './supplierDeviceInstanceApi'
import { unwrapResult } from '@reduxjs/toolkit'
import { LoadingContainer } from '../../app/LoadingContainer'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'
import { CreateSupplierDeviceInstanceDialog } from './dialogs/CreateSupplierDeviceInstanceDialog'
import { getStructureName } from '../device/Structure'

export const SupplierDeviceInstancesList = () => {
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [devices, setDevices] = useState<SupplierDeviceInstance[]>([]);
    const localeText = useDataGridCustomLocaleText();
    const [createDialogVisible, setCreateDialogVisible] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSupplierDeviceInstances())
            .then(unwrapResult)
            .then(setDevices)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    }, [])


    const hadndleEditClick = (id) => {
        navigate(`//edit/${id}`)
    };


    const handleHideDialog = () => {
        setCreateDialogVisible(false);
    }

    const openCreateDialog = () => {
        setCreateDialogVisible(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'serialNo',
            headerName: l10n.getString("devices-serial-number", null, "Nr fabryczny"),
            minWidth: 200
        },
        {
            field: 'name',
            headerName: l10n.getString("devices-name", null, "Nazwa"),
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("devices-type", null, "Typ"),
            flex: 1,
            valueGetter: (_, row) => {
                return getStructureName(row.deviceTypeId)
            }
        },

        {
            field: 'validationStatus',
            headerName: l10n.getString("devices-validation-status", null, "_validation status"),
            width: 150
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`/supplier-device/edit/${params.row.id}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="go-to">
                                <span>_goto</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                            localeText={localeText}
                            rows={devices}
                            columns={columns}
                            slots={{ toolbar: QuickSearchToolbar }}
                            onRowDoubleClick={({ row }) => hadndleEditClick(row.id)} />
                    </div>
                </div>
                <FlexDiv>
                    <Fab onClick={() => openCreateDialog()} size="small" color="secondary" sx={{ marginTop: 2 }}>
                        <AddIcon />
                    </Fab>
                </FlexDiv>
            </Box>
            {
                createDialogVisible &&
                <CreateSupplierDeviceInstanceDialog onSucces={hadndleEditClick} onCancel={handleHideDialog} />
            }
        </>
    )
}
