import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useQueryStructureId } from '../../app/hooks'
import { useMemo, useState } from 'react'
import { Box, ButtonGroup } from '@mui/material'
import { Skeleton } from '@mui/material'
import { LoadCompanies, LoadDeviceTypes, LoadURSs, LoadUsers } from '../../app/AppDataLoader'
import { selectAllStructure, selectStructureAndChildren } from '../device/structureRelationshipSlice'
import { loadUrsListByFilters, selectAllUrs } from '../urs/ursListSlice'
import { DataGrid, GridColDef, } from '@mui/x-data-grid'
import { plPL, enUS } from '@mui/x-data-grid/locales'
import { selectSettings } from '../settings/settingsSlice'
import { UrsHeader } from './UrsSlice'
import { useNavigate } from 'react-router-dom'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'
import { getStructureName } from '../device/Structure'
import { DocumentFilterPanel, DocumentFilters } from '../documents/utils/DocumentFilterPanel'
import { selectModule } from '../modules/moduleSlice'
import { unwrapResult } from '@reduxjs/toolkit'


export const UrsList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();

    const currentModule = useAppSelector(state => selectModule(state).currentModule) 

    const [filteredUrs, setFilteredUrs] = useState<UrsHeader[]>([])

    const allURSs =
        useAppSelector(state => selectAllUrs(state))
    const structureId = useQueryStructureId()
    const relationship = useAppSelector(state => {
        if (structureId) {
            return selectStructureAndChildren(state, structureId)
        } else {
            return selectAllStructure(state)
        }
    })
    const URSs = useMemo(() => {
        if(filteredUrs.length !== 0) {
            return filteredUrs.filter(({ deviceTypeId }) => relationship.has(deviceTypeId));
        } else {
            return allURSs.filter(({ deviceTypeId }) => relationship.has(deviceTypeId));
        }
    }, [allURSs, relationship, filteredUrs])

    const columns: GridColDef<UrsHeader, any, any>[] = [
        {
            field: 'ursNo',
            headerName: l10n.getString("document-number", null, "_Document number"),
            minWidth: 150
        },
        {
            field: 'version',
            headerName: l10n.getString("document-version", null, "_Document number"),
            valueGetter: (_, row) => {
                return row.version + 1
            }
        },
        {
            field: 'title',
            headerName: l10n.getString("document-title", null, "_Document title"),
            minWidth: 300,
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("structure", null, "_Structure"),
            width: 300,
            valueGetter: (_, row) => {
                return getStructureName(row.deviceTypeId)
            }
        },
        {
            field: 'createdByFullName',
            headerName: l10n.getString("document-created-by", null, "_Author"),
            width: 200
        },
        {
            field: 'createDate',
            type: 'date',    
            headerName: l10n.getString("document-created-date", null, "_Create date"),
            width: 200,
            valueGetter: (_, row) => new Date(row.createDate),
        },
        {
            field: 'status',
            headerName: l10n.getString("document-status", null, "_Status"),
            width: 150,
            valueGetter: (_, row) => {
                var status = row.status.toLowerCase();
                const description = l10n.getString("urs-status-" + status, null, "");
                return description;
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`/urs/edit/${params.row.id}?structureId=${params.row.deviceTypeId}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    const addURL = structureId !== undefined
        ? `/urs/create?structureId=${structureId}`
        : "/urs/create"

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const navigate = useNavigate()

    const handleRowDoubleClick = (params) => {
        navigate(`/urs/edit/${params.row.id}?structureId=${params.row.deviceTypeId}`)
    };

    const handleFilterChange = (data:DocumentFilters) => {
        if(data.dateFrom === "all" && data.dateTo === "all" && data.status.length === 0 && data.role.length === 0) {
            setFilteredUrs([]);
        } else if(currentModule !== null && currentModule.code !== "super") {
            const params = new URLSearchParams();
            params.append("moduleId", currentModule.sysModuleId);

            if(data.dateFrom !== "all" && data.dateTo !== "all") {
                params.append("dateFrom", new Date(data.dateFrom).toISOString());
                params.append("dateTo", new Date(data.dateTo).toISOString());
            }

            if(data.status.length !== 0) {
                params.append("statuses", data.status.join(","));
            }

            if(data.role.length !== 0) {
                params.append("roles", data.role.join(","));
            }

            dispatch(loadUrsListByFilters("?" + params.toString())).then(unwrapResult).then(setFilteredUrs);
        }
    }


    return <>
        <LoadURSs component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
            <LoadUsers component={<Skeleton variant="rectangular" />}>
                <LoadDeviceTypes component={<Skeleton variant="rectangular" />}>
                    
                        <DocumentFilterPanel filterPanelName="ursList" onFilterChange={handleFilterChange}/>

                        <TableContainer component={Paper}>
                            <DataGrid localeText={getCustomLocaleText()}
                                style={{ minHeight: 100, height: 'calc(100vh - 290px)' }}
                                rows={URSs} columns={columns}
                                initialState={{
                                    sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                                }}
                                slots={{ toolbar: QuickSearchToolbar }}
                                onRowDoubleClick={handleRowDoubleClick}
                            />
                        </TableContainer>
                        <Box sx={{
                            display: 'flex',
                        }}>
                            <Fab sx={{
                                marginTop: 2,
                            }} component={Link} to={addURL} color="secondary" disabled={structureId === undefined}>
                                <AddIcon />
                            </Fab>
                        </Box>
                </LoadDeviceTypes>
            </LoadUsers>
        </LoadURSs>
    </>
}
