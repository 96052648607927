import { DialogActions, DialogContent, DialogTitle, Tab } from "@mui/material"
import { TeamMemberPanel } from "../components/TeamMemberPanel"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Localized } from "@fluent/react";
import { useCallback, useState } from "react";
import { DocumentHeader, DocumentStatus } from "../documentsApi";
import { DocumentStatusLabel } from "../DocumentStatusLabel";

interface DocumentDetailsDialogProps {
    documentId: string;
    document?: DocumentHeader;
    onCancel: () => void;
}

export const DocumentDetailsDialog = ({ documentId, document, onCancel }:DocumentDetailsDialogProps) => {
    const [tab, setTab] = useState("Details")

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, []);

    return <>
        <DialogTitle>
            <Localized id="documents-overflow-details">Szczegóły dokumentu</Localized>
        </DialogTitle>
        <DialogContent>
            <TabContext value={tab}>
                <TabList onChange={handleTabChange}>
                    <Tab
                        key={0}
                        value={"Details"}
                        label={<Localized id="details">Details</Localized>} />
                    <Tab
                        key={1}
                        value={"Team"}
                        label={<Localized id="team">Team</Localized>} />
                </TabList>
                <TabPanel value="Details">
                    <p><Localized id="number">Numer</Localized>: {document?.no}</p>
                    <p><Localized id="title">Tytuł</Localized>: {document?.title}</p>
                    <p><Localized id="type">Typ</Localized>: {document?.type}</p>
                    <p><Localized id="project-status">Status</Localized>: <DocumentStatusLabel status={(document !== undefined ? document.status : "") as DocumentStatus} /></p>
                    <p><Localized id="company">Company</Localized>: {document?.companyName}</p>
                    <p><Localized id="created-by">Utworzył</Localized>: {document?.createdByFullName}</p>
                    <p><Localized id="project-create-date">Data utworzenia</Localized>: {document?.createDate}</p>
                    <p><Localized id="document-language">Język</Localized>: {document?.language}</p>
                </TabPanel>
                <TabPanel value="Team">
                    <TeamMemberPanel documentId={documentId} mode={"edit"}></TeamMemberPanel>
                </TabPanel>
            </TabContext>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}