import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Paper, Link, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import Standards from '../project/documentationProcess/Standards';
import { useLocalization } from '@fluent/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSettings } from '../settings/settingsSlice';
import { ErrorContainer } from '../../app/ErrorContainer';
import { LoadingContainer } from '../../app/LoadingContainer';
import { Device, loadDeviceDetails } from './deviceSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { APIError } from '../../app/appTypes';
import { StructureName } from './Structure';


const WarrantyStatus = ({ status }) => {
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "active":
                return "success"; // Zielony
            case "expired":
                return "error"; // Czerwony
            case "pending":
                return "warning"; // Pomarańczowy
            default:
                return "default"; // Szary
        }
    };

    return (
        <Box>
            <Typography variant="body1" gutterBottom>
                Warranty Status:
                <Chip
                    label={status}
                    size='small'
                    color={getStatusColor(status)}
                    sx={{ ml: 1, textTransform: "uppercase" }}
                />
            </Typography>
        </Box>
    );
};


export const DeviceWidget = (props: { deviceId: string }) => {
    const dispatch = useAppDispatch();

    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [device, setDevice] = useState<Device>()
    const [error, setError] = useState<APIError | null>(null)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        dispatch(loadDeviceDetails(props.deviceId))
            .then(unwrapResult)
            .then(setDevice)
            .catch((err) => setError(err))
    }, [])


    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }



    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return (
        <div>
            {/* Device Details Section */}
            <Paper elevation={1} sx={{ padding: 2, flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h6">Device Details</Typography>
                    <IconButton color="primary" size="small">
                        <EditIcon />
                        <Typography variant="body2" sx={{ marginLeft: 0.5 }}>Edit</Typography>
                    </IconButton>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Serial Number: <span style={{ fontWeight: 'bold' }}>{device?.serialNo}</span>
                    </Typography>
                    <Typography variant="body1">
                        Description: <span style={{ fontWeight: 'bold' }}>{device?.name}</span>
                    </Typography>
                    <Typography variant="body1">
                        Device Type: <span style={{ fontWeight: 'bold' }}> <StructureName structureId={device?.deviceTypeId ?? ''} /></span>
                    </Typography>
                    <Typography variant="body1">
                        Production Year: <span style={{ fontWeight: 'bold' }}>{device?.productionYear}</span>
                    </Typography>
                    <Typography variant="body1">
                        Model: <span style={{ fontWeight: 'bold' }}>{device?.model}</span>
                    </Typography>
                    <Typography variant="body1">
                        Manufacturer: <span style={{ fontWeight: 'bold' }}>{device?.manufacturer}</span>
                    </Typography>
                    <WarrantyStatus status={"Active"} />

                </Box>
            </Paper>
        </div>
    );
};

export default DeviceWidget;


