import SaveIcon from '@mui/icons-material/Save'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Tooltip } from "@mui/material"
import { useAppDispatch } from "../../app/hooks"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Grid, } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'
import { AppId } from '../../app/appTypes'
import { AppRequiredMessage } from '../../app/AppRequiredMessage'
import { DeviceTypeCombobox } from '../device/DeviceTypeCombobox'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { postWithAuth } from "../../http"
import { If } from "../../app/If"
import { useLocation } from 'react-router-dom'
import { createRelatedTestSpecification, TestSpecification } from './testSpecificationApi'
import { LoadingButton } from '@mui/lab'


export interface CreateTestSpecificationDialogProps {
    defName: string
    context: string
    defStructureId: AppId | null
    relatedObject: string | undefined
    relatedObjectId: string | undefined
    onSuccess: (test: TestSpecification) => void
    onCancel: () => void
}
export const CreateTestSpecificationDialog = (props: CreateTestSpecificationDialogProps) => {
    const { onSuccess, onCancel, defName, defStructureId, relatedObject, relatedObjectId } = props
    const dispatch = useAppDispatch()
    const { state } = useLocation()

    const [name, setName] = useState(() => {
        if (state && state[`defaultName`]) {
            return state[`defaultName`]
        } else {
            return defName
        }
    })
    
    const [description, setDescription] = useState("")
    const [deviceTypeId, setDeviceTypeId] = useState<AppId | null>(defStructureId)
    const [validationErrors, setValidationErrors] = useState(false)
    const [saving, setSaving] = useState(false)
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (name) {
            setSaving(true)
            try {
                const result = await dispatch(createRelatedTestSpecification({
                    name,
                    description,
                    structureId: deviceTypeId ?? "",
                    relatedObjectType: relatedObject ?? "",
                    relatedObjectId: relatedObjectId ?? ""

                }));
                if (createRelatedTestSpecification.fulfilled.match(result)) {
                    const newTest = result.payload;
                    dispatch(showSuccess("saved"))
                    onSuccess(newTest)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                            dispatch(showError(errorMsg))
                        }
                    }
                }

            } catch (error) {

                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    const handleGenerateDescriptionClick = async () => {
        if (name) {
            setWaitingForAnswer(true)
            try {
                const result = await dispatch(postWithAuth({
                    url: `api/ai/ask`,
                    payload: {
                        Type: 'test-for-requirement',
                        Question: name
                    }
                }))
                if (postWithAuth.fulfilled.match(result)) {
                    const { payload } = result
                    setDescription(payload.answer)
                }
            }
            finally {
                setWaitingForAnswer(false)
            }
        }
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setName(e.target.value), [setName]);
    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setDescription(e.target.value), [setDescription]);

    const nameError = validationErrors && name === ""
    const deviceError = validationErrors && deviceTypeId === undefined

    return <>
        <Dialog  maxWidth="md" open={true}>
            <DialogTitle>{<Localized id="test-add-new-title">_New test</Localized>}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            error={nameError}
                            helperText={nameError && <AppRequiredMessage />}
                            value={name}
                            inputProps={{ maxLength: 200 }}
                            sx={{ marginTop: "5px" }}
                            multiline={true}
                            onChange={handleNameChange}
                            label={<Localized id="validation-name">Nazwa</Localized>}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={description}
                            onChange={handleDescriptionChange}
                            multiline={true}
                            inputProps={{ maxLength: 1000 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <If condition={!waitingForAnswer} otherwise={
                                            <CircularProgress />
                                        }>
                                            <Tooltip title={<Localized id="test-generate-description">_Generate description</Localized>}>
                                                <IconButton onClick={handleGenerateDescriptionClick}>
                                                    <AutoFixHighIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </If>
                                    </InputAdornment>
                                )
                            }}
                            minRows={2}
                            maxRows={16}
                            label={<Localized id="validation-description">Opis</Localized>}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <DeviceTypeCombobox
                            disabled={defStructureId != null}
                            size='small'
                            onlyLeafs={false}
                            error={deviceError}
                            value={deviceTypeId ?? ""}
                            onChange={setDeviceTypeId}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} size="small" color="success" variant="contained" startIcon={<SaveIcon />} onClick={handleSaveClick}>
                    <Localized id="save">Zapisz</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} size="small" onClick={onCancel} color="primary" variant="outlined">
                    <Localized id="cancel">_cancel</Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
