import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { TextField, Chip, Paper, FormControl, RadioGroup, FormControlLabel, Radio, Container, IconButton, Checkbox, InputAdornment, Tooltip } from '@mui/material';
import { MeetingMinute } from '../meetingMinutesSlice';
import { Localized, useLocalization } from '@fluent/react';
import { validateEmail } from '../../../../app/Utils';
import { AppTextFieldHandler } from '../../../../app/appTypes';
import AddIcon from '@mui/icons-material/Add';
import CloseRounded from '@mui/icons-material/CloseRounded';

interface EmailInputProps {
  meetingMinute: MeetingMinute;
  defaultAttachNoteAttachments: boolean;
  onListOfRecipientsChange: (listOfRecipients: string[]) => void;
  onAttachNoteAttachmentsChanged: (attach: boolean) => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ meetingMinute, defaultAttachNoteAttachments, onListOfRecipientsChange, onAttachNoteAttachmentsChanged }) => {
  const { l10n } = useLocalization()

  const lastMeeting = meetingMinute.meetings.find(meeting => meeting.id === meetingMinute.lastMeetingId);
  const lastMeetingParticipants = lastMeeting ? lastMeeting.attendanceList.map(a => a.email).filter(email => email.trim() !== '') : [];
  const allParticipants = Array.from(new Set(meetingMinute.meetings.flatMap(meeting => meeting.attendanceList.map(a => a.email).filter(email => email.trim() !== ''))));
  const [emailInput, setEmailInput] = useState<string>('');
  const [radioValue, setRadioValue] = useState<string>('allParticipants');
  const [emails, setEmails] = useState<string[]>(allParticipants);
  const [emailError, setEmailError] = useState<string>('');
  const [sendAttachments, setSendAttachments] = useState<boolean>(defaultAttachNoteAttachments);


  useEffect(() => {
    onListOfRecipientsChange(emails);
  }, [emails, onListOfRecipientsChange]);

  useEffect(() => {
    setSendAttachments(defaultAttachNoteAttachments);
    onAttachNoteAttachmentsChanged(defaultAttachNoteAttachments)
  }, [defaultAttachNoteAttachments]);


  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRadioValue(value);

    if (value === 'meetingParticipants') {
      setEmails(lastMeetingParticipants);
    } else if (value === 'allParticipants') {
      setEmails(allParticipants);
    }
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== '') {
      if (!validateEmail(emailInput)) {
        setEmailError(l10n.getString("email-incorrect", null, "_email-incorrect"),);
      } else if (emails.includes(emailInput)) {
        setEmailError(l10n.getString("email-already-on-list", null, "_email-already-on-list"),);
      } else {
        setEmails([...emails, emailInput]);
        setEmailInput('');
        setEmailError('');
      }
    }
  };

  const handleClearEmail = () => {
    setEmailInput('');
    setEmailError('');
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddEmail();
    }
  };

  const handleDelete = (emailToDelete: string) => () => {
    setEmails(emails.filter(email => email !== emailToDelete));
  };

  const handleAttachChange = (event) => {
    setSendAttachments(event.target.checked)
    onAttachNoteAttachmentsChanged(event.target.checked);
  };

  const handleEmailChange: AppTextFieldHandler = (e) => {
    setEmailInput(e.target.value);
    if (e.target.value.trim() === '') {
      setEmailError('');
    }
  };

  return (
    <Container>
      <FormControl component="fieldset">
        <RadioGroup value={radioValue} onChange={handleRadioChange}>
          <FormControlLabel
            value="meetingParticipants"
            control={<Radio />}
            label={<Localized id="meeting-minutes-current-meeting-attendees">_Uczestnicy bieżącego spotkania</Localized>}
          />
          <FormControlLabel
            value="allParticipants"
            control={<Radio />}
            label={<Localized id="meeting-minutes-all-attendees">_Uczestnicy bieżącego spotkania</Localized>}
          />
        </RadioGroup>
      </FormControl>
      <Paper
        component="div"
        style={{ padding: '10px', marginBottom: '10px', maxHeight: '500px', overflowY: 'auto' }}
      >
        {emails.sort((a, b) => a.localeCompare(b)).map((email) => (
          <div key={email}>
            <Chip
              label={email}
              onDelete={handleDelete(email)}
              color="primary"
              style={{ marginLeft: '10px', marginTop: '1px', flex: 1 }}
            />
          </div>
        ))}
      </Paper>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '15px' }}>
        <TextField
          value={emailInput}
          size="small"
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
          label={<Localized id="add-email">_Dodaj email</Localized>}
          variant="outlined"
          type="email"
          autoComplete="email"
          fullWidth
          error={!!emailError}
          helperText={emailError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={<Localized id="add-email">_Dodaj email</Localized>}>
                  <IconButton onClick={handleAddEmail} color="success" aria-label="add email">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<Localized id="clear-email">_Clear email</Localized>}>
                  <IconButton
                    onClick={handleClearEmail}
                    color="primary"
                    aria-label="clear email"
                    disabled={!emailInput.trim()}
                    sx={{
                      opacity: emailInput ? 1 : 0.5,
                      cursor: emailInput ? 'pointer' : 'not-allowed',
                    }}
                  >
                    <CloseRounded fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <FormControlLabel
        control={<Checkbox checked={sendAttachments} onChange={handleAttachChange} />}
        label={<Localized id="meeting-minutes-add-attachements">_dodaj do maila załączniki notatki</Localized>}
      />
    </Container>
  );
};

export default EmailInput;
