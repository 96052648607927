import { useEffect, useState } from "react"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { Box, Button, ButtonGroup, Dialog, Fab, Paper, TableContainer } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from "react-router-dom"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { loadAllRiskAnalysisDocuments, loadAllRiskAnalysisDocumentsByFilters, RiskAnalysisHeader } from "./riskAnalysisApi"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import { DocumentFilterPanel, DocumentFilters } from "../utils/DocumentFilterPanel"

export const RiskAnalysisList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const [documents, setDocuments] = useState<RiskAnalysisHeader[]>([]);
    const { l10n } = useLocalization()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadAllRiskAnalysisDocuments())
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])


    const handleRowDoubleClick = (params) => {
        navigate(`/new-risk-analysis/edit/${params.row.id}?structureId=${params.row.structureId}`)
    };


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleFilterChange = (data:DocumentFilters) => {
        if(data.dateFrom === "all" && data.dateTo === "all" && data.status.length === 0 && data.role.length === 0) {
            dispatch(loadAllRiskAnalysisDocuments())
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
        } else {
            const params = new URLSearchParams();

            if(data.dateFrom !== "all" && data.dateTo !== "all") {
                params.append("dateFrom", new Date(data.dateFrom).toISOString());
                params.append("dateTo", new Date(data.dateTo).toISOString());
            }

            if(data.status.length !== 0) {
                params.append("statuses", data.status.join(","));
            }

            if(data.role.length !== 0) {
                params.append("roles", data.role.join(","));
            }

            dispatch(loadAllRiskAnalysisDocumentsByFilters("?" + params.toString())).then(unwrapResult).then(setDocuments);
        }
    }

    const columns: GridColDef[] =
        [
            {
                field: 'no',
                flex: 1,
                headerName: l10n.getString('', null, 'Numer'),

            },

            {
                field: 'createdByFullName',
                headerName: l10n.getString('meeting-minutes-author', null, 'Autor'),
                width: 400
            },
            {
                field: 'createDate',
                type: 'date',
                width: 150,
                headerName: l10n.getString('meeting-minutes-create-date', null, 'Data utworzenia'),
                valueGetter: (_, row) => new Date(row.createDate),
            },
            {
                field: 'status',
                headerName: l10n.getString('meeting-minutes-status', null, 'Status'),
                width: 100,
                renderCell: (params) => {
                    return (
                        <div>
                            <DocumentStatusLabel status={params.row.status} />
                        </div>
                    )
                }
            },
            {
                field: 'actions', type: 'actions', width: 400,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button component={Link} to={`/new-risk-analysis/edit/${params.id}`} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Przejdz</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];


    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }
    return <>
        <DocumentFilterPanel filterPanelName="riskAnalysisList" onFilterChange={handleFilterChange}/>

        <TableContainer component={Paper}>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                localeText={getCustomLocaleText()}
                rows={documents}
                columns={columns}
                slots={{ toolbar: QuickSearchToolbar }}
                onRowDoubleClick={handleRowDoubleClick}
            />
        </TableContainer>

        <Box sx={{
            display: 'flex',
        }}>
            <Fab sx={{
                marginTop: 2,
            }} component={Link} to={`create`} color="secondary" >
                <AddIcon />
            </Fab>
        </Box>


    </>
}
