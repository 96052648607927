import React from 'react';
import { Box, Typography, IconButton, Paper, Link } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import Standards from './Standards';
import DeviceWidget from '../../device/DeviceWidget';
import { ProjectProcess } from '../projectProcess/ProjectProcessApiClient';

export const MainInformationTab  = (props: { process: ProjectProcess }) => {
  return (
    <div>
      {/* Device Details Section */}
     <DeviceWidget deviceId={props.process.deviceId} />

      {/* Client Details Section */}
      <Paper elevation={1} sx={{ padding: 2, flex: 1, marginTop:"10px" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6">Client Details</Typography>
          <IconButton color="primary" size="small">
            <EditIcon />
            <Typography variant="body2" sx={{ marginLeft: 0.5 }}>Edit</Typography>
          </IconButton>
        </Box>
        <Box>
          <Typography variant="body1">
            Customer Name: <span style={{ fontWeight: 'bold' }}>John Doe</span>
          </Typography>
          <Typography variant="body1">
            URS Request:{' '}
            <Link
              href="http://localhost:3000/ursOffers/edit/131"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span style={{ fontWeight: 'bold' }}>URS-2024-13-09/KP</span>
            </Link>
          </Typography>
          <Typography variant="body1">
            Customer: <span style={{ fontWeight: 'bold' }}>Adamed</span>
          </Typography>
          <Typography variant="body1">
            Contact Person: <span style={{ fontWeight: 'bold' }}>Lukasz Berbec</span>{' '}
            <Link
              href="mailto:lukasz.berbec@example.com?subject=Regarding%20URS%20Request%20URS-2024-13-09/KP&body=Hello,%0A%0AI would like to discuss the following URS request."
            >
              <IconButton size="small" color="primary">
                <EmailIcon />
              </IconButton>
            </Link>
          </Typography>
          <Typography variant="body1">
            Address: <span style={{ fontWeight: 'bold' }}>Poland</span>
          </Typography>
        </Box>
       
      </Paper>
    </div>
  );
};

export default MainInformationTab;
