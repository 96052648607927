import { useAppDispatch } from "../../app/hooks"
import { createSpec, createSpecComponent, defaultSpec, deleteSpec, deleteSpecComponent, loadSpec, SpecComponent, SpecWithDetails, updateSpec, updateSpecComponent } from "./specSlice"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import Tab from "@mui/material/Tab"
import { createContext, useCallback, useContext, useRef, useState, DragEvent, useEffect, useMemo } from "react"
import { RichTextContainer, tiptapExtensions } from "../../app/RichTextEditor"
import { SpecItemsTree } from "./SpecItemsTree"
import { SpecTests } from "./SpecTests"
import { AppData, AppDataProvider, DataSetter, SaveResult } from "../../app/AppDataProvider"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Alert, AppBar, Box, Button, ButtonGroup, Chip, CircularProgress, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material"
import { Editor, useEditor } from "@tiptap/react"
import { APIError } from "../../app/appTypes"
import { Localized } from "@fluent/react"
import { ServerErrorMsg } from "../../app/ServerErrorMsg"
import clsx from "clsx"
import { AppBlobsProvider, BlobsProps } from "../../app/AppBlobsProvider"
import InfoIcon from '@mui/icons-material/Info'
import './gallery.css'
import { unwrapResult } from "@reduxjs/toolkit"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { StructureName } from "../device/Structure"
import EditIcon from '@mui/icons-material/Edit'
import { DeviceTypeCombobox } from "../device/DeviceTypeCombobox"
import DeviceSpecTypeDropdown from "./DeviceSpecTypeDropdown"
import { Print as PrintIcon } from '@mui/icons-material';
import { SpecificationToolbarMenu } from "./utils/SpecificationToolbarMenu"

export const EditSpec = () => {
    const { id: paramId } = useParams()
    const someId = Number.parseInt(paramId ?? '')
    const id = Number.isNaN(someId) ? 0 : someId

    return <AppDataProvider<number, SpecWithDetails>
        id={id}
        def={defaultSpec}
        create={createSpec}
        update={updateSpec}
        load={loadSpec}
        del={deleteSpec}
        form={EditSpecWithData}
    />
}

type SpecTab = 'fs' | 'ds' | 'tests' | 'images' | 'offer' | 'description'

type Data = { data: SpecWithDetails }

const DataSetterContext = createContext<DataSetter<SpecWithDetails>>(() => { })

type HdrField = 'name' | 'description' | 'structure' | 'type'

type SpecDialog
    = { tag: 'none' }
    | { tag: 'add-root-component', specId: number }
    | { tag: 'add-child-component', parentComponentId: number, specId: number }
    | { tag: 'edit-component', data: SpecComponent }
    | { tag: 'add-image', guid: string, resolve: (value: string) => void }
    | { tag: 'delete-component', component: SpecComponent }
    | { tag: 'edit', field: HdrField, data: SpecWithDetails, }

export const DevSpecDialogContext = createContext<(d: SpecDialog) => void>(() => { })

type Saver = Promise<SaveResult<SpecWithDetails>>

const EditSpecWithData = (props: AppData<number, SpecWithDetails>) => {
    const { data, updateDataLocally } = props
    const { description, type, components, guid, name, structureId } = data
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const [_, setDescription] = useState('')

    const [tab, setTab] = useState<SpecTab>('description')
    const [dialog, setDialog] = useState<SpecDialog>({ tag: 'none' })
    const setDialogContext = useCallback((d: SpecDialog) => {
        setDialog(d)
    }, [])

    const handleAddRootComponentClick = () => {
        setDialog({
            tag: 'add-root-component',
            specId: data.id,
        })
    }
    const addImage = useCallback((resolve: (value: string) => void) => {
        setDialog({ tag: 'add-image', guid, resolve })
    }, [])

    const handleTreeItemSelectionChange = useCallback((newId: string) => {
        searchParams.set('componentId', newId)
        setSearchParams(searchParams, { replace: true })
    }, [searchParams])

    let componentId: number | undefined = undefined
    let selectedComponent: SpecComponent | undefined = undefined
    if (searchParams.has('componentId')) {
        const candidateId = parseInt(searchParams.get('componentId') ?? '')
        if (!Number.isNaN(candidateId)) {
            const candidate = findComponent(data.components, candidateId)
            if (candidate) {
                selectedComponent = candidate
                componentId = candidateId
            }
        }

    } else if (data.components.length > 0) {
        componentId = data.components[0].id
        selectedComponent = data.components[0]
    }

    const galleryArgs = useMemo<GalleryProps>(() => {
        return {
            selected: undefined,
            setSelected: () => { },
        }
    }, [])

    let content = <></>
    if (data.components.length === 0) {
        content = <div className="row row-col">
            <Alert severity="info" icon={<InfoIcon />}>
                <Localized id={"spec-empty-tree-warning"}>_Drzewo komponentow jest puste.</Localized>
            </Alert>
            <Button sx={{ mt: 1 }} variant="outlined" onClick={handleAddRootComponentClick}>
                <Localized id={"spec-add-component"}>_Add component</Localized>
            </Button>
        </div>
    } else {
        content = <TabContext value={tab}>
            <TabList onChange={(_, newTab) => setTab(newTab)}>
                {/* <Tab label='Offer' value='offer' /> */}
                <Tab label='Description' value='description' />
                {/* <Tab label='Design specification' value='ds' /> */}
                <Tab label='Tests' value='tests' />
                {/* <Tab label='Images' value='images' /> */}
            </TabList>
            {/* <TabPanel sx={{ px: 0, py: 0.5 }} value='offer'>
                {selectedComponent && <OfferDescription component={selectedComponent} setImage={(resolve) => {
                    addImage(resolve)
                }} />}
            </TabPanel> */}
            <TabPanel sx={{ px: 0, py: 0.5 }} value='description'>
                {selectedComponent && <FuncSpec component={selectedComponent} setImage={(resolve) => {
                    addImage(resolve)
                }} />}
            </TabPanel>
            {/* <TabPanel sx={{ px: 0, py: 0.5 }} value='ds'>
                {selectedComponent && <DesignSpec component={selectedComponent} setImage={(resolve) => {
                    addImage(resolve)
                }} />}
            </TabPanel> */}
            <TabPanel sx={{ px: 0, py: 0.5 }} value='tests'>
                {selectedComponent && <SpecTests componentId={selectedComponent?.id} defStructureId={structureId} />}
            </TabPanel>
            {/* <TabPanel sx={{ px: 0, py: 0.5 }} value='images'>
                <AppBlobsProvider<GalleryProps> guid={data.guid} component={Gallery} args={galleryArgs} />
            </TabPanel> */}
        </TabContext>
    }

    const handleEditNameClick = () => {
        setDialog({
            tag: 'edit',
            field: 'name',
            data,
        })
    }

    const handleEditDescriptionClick = () => {
        setDialog({
            tag: 'edit',
            field: 'description',
            data,
        })
    }

    const handleEditStructureClick = () => {
        setDialog({
            tag: 'edit',
            field: 'structure',
            data,
        })
    }

    const handleEditSpecificationTypeClick = () => {
        setDialog({
            tag: 'edit',
            field: 'type',
            data,
        })
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    
    const onConfirmedDeleted = () => {
        handleBackButtonClick()
    };

    return <DataSetterContext.Provider value={updateDataLocally}>
        <DevSpecDialogContext.Provider value={setDialogContext}>
            <Box display="flex" flexDirection="column" sx={{ height: 'calc(100vh - 150px)' }}>
                <Box display="flex" flexGrow={1} overflow="auto">
                    <div className="container mx-auto space-y-2">
                        <div className="grid grid-cols-6 gap-1">
                            <div className={clsx(containerCss, 'col-span-2')}>
                                <div className={clsx(labelCss)}>
                                    <span>Name</span>
                                    <IconButton sx={{
                                        fontSize: '1rem',
                                        p: 0,
                                        position: 'absolute',
                                        right: 4,
                                    }}
                                        size="small"
                                        onClick={handleEditNameClick}
                                    ><EditIcon fontSize='inherit' /></IconButton>
                                </div>
                                <div className='px-2 py-1 text-lg font-semibold'>
                                    {name}
                                </div>
                            </div>
                            <div className={clsx(containerCss, 'col-span-2')}>
                                <div className={clsx(labelCss)}>
                                    <span>Description</span>
                                    <IconButton sx={{
                                        fontSize: '1rem',
                                        p: 0,
                                        position: 'absolute',
                                        right: 4,
                                    }}
                                        size="small"
                                        onClick={handleEditDescriptionClick}
                                    ><EditIcon fontSize='inherit' /></IconButton>
                                </div>
                                <div className='px-2 py-1'>
                                    {description}
                                </div>
                            </div>
                            <div className={clsx(containerCss)}>
                                <div className={clsx(labelCss)}>
                                    <Localized id='structure'><span>Struktura</span></Localized>
                                    <IconButton sx={{
                                        fontSize: '1rem',
                                        p: 0,
                                        position: 'absolute',
                                        right: 4,
                                    }}
                                        size="small"
                                        onClick={handleEditStructureClick}
                                    ><EditIcon fontSize='inherit' /></IconButton>
                                </div>
                                <div className='px-2 py-1'>
                                    <StructureName structureId={structureId ?? ''} />
                                </div>
                            </div>
                            <div className={clsx(containerCss)}>
                                <div className={clsx(labelCss)}>
                                    <Localized id='spec-type-long'><span>_Specification type</span></Localized>
                                    <IconButton sx={{
                                        fontSize: '1rem',
                                        p: 0,
                                        position: 'absolute',
                                        right: 4,
                                    }}
                                        size="small"
                                        onClick={handleEditSpecificationTypeClick}
                                    ><EditIcon fontSize='inherit' /></IconButton>
                                </div>
                                <div className='px-2 py-1'>
                                    <span
                                        className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-medium">
                                        {type}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="basis-1/4 p-2 shadow-md">
                                <SpecItemsTree selectedItemId={selectedComponent?.id.toString()} data={data} onSelectionChange={handleTreeItemSelectionChange} />
                                {data.components.length > 0 && <Button sx={{ mt: 2 }} onClick={handleAddRootComponentClick}>+ Add</Button>}
                            </div>
                            <div className="basis-3/4">
                                {content}
                            </div>
                        </div>
                        <SpecDialogDispatcher {...dialog} />
                    </div>
                </Box>
                <AppBar
                    position="fixed"
                    color="transparent"

                    sx={{ top: 'auto', bottom: 0, p: 0.5 }}
                    className="bg-gray-200"
                >
                    <div className="flex justify-end">
                        <SpecificationToolbarMenu specId={data.id} onDelete={onConfirmedDeleted } />

                        <Button variant='contained' color="primary" size="small" onClick={handleBackButtonClick}>
                            <Localized id='back'>Wróć</Localized>
                        </Button>
                    </div>
                </AppBar>
            </Box>
        </DevSpecDialogContext.Provider>
    </DataSetterContext.Provider>
}

const containerCss = 'rounded border-solid border border-slate-100'
const labelCss = 'text-xs bg-slate-100 px-2 py-1 relative'

// <div className={clsx(containerCss)}>
//     <div className={clsx(labelCss)}>
//         {props.label}
//         <IconButton sx={{ 
//             fontSize: '1rem',
//             p: 0,
//             position: 'absolute',
//             right: 4,
//         }} size="small"><EditIcon fontSize='inherit' /></IconButton>
//     </div>
//     <div className='px-2 py-1'>
//         {props.children}
//     </div>
// </div>

const findComponent = (cs: SpecComponent[], id: number): SpecComponent | null => {
    for (let i = 0; i < cs.length; i++) {
        if (cs[i].id === id) {
            return cs[i]
        } else {
            const child = findComponent(cs[i].childComponents, id)
            if (child) {
                return child
            }
        }
    }
    return null
}

interface SpecProps {
    component: SpecComponent
    setImage?: (resolveImageUrl: (url: string) => void, reject: (reason?: any) => void) => void
}
const FuncSpec = (props: SpecProps) => {
    const { component, setImage } = props
    const update = useContext(DataSetterContext)
    const dispatch = useAppDispatch()
    const { functionalSpecification, id } = component

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(functionalSpecification)
        }
    }, [component.id])

    const save = useCallback(debounce((valEditor: Editor | null) => {
        const funcSpec = valEditor?.getHTML() ?? ''
        dispatch(updateSpecComponent({
            ...component,
            functionalSpecification: funcSpec,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                update(draft => {
                    const data = findComponent(draft.components, component.id)
                    if (data && valEditor) {
                        data.functionalSpecification = funcSpec
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [component])

    const handleDescriptionChange = () => {
        save(editor)
    }

    const editor = useEditor({
        extensions: tiptapExtensions,
        content: functionalSpecification,
        onUpdate: handleDescriptionChange,
    })

    return <RichTextContainer editor={editor} label={<span>{component.name}</span>} />
}

const DesignSpec = (props: SpecProps) => {
    const { component, setImage } = props
    const update = useContext(DataSetterContext)
    const dispatch = useAppDispatch()
    const { designSpecification, id } = component

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(designSpecification)
        }
    }, [component.id])

    const save = useCallback(debounce((valEditor: Editor | null) => {
        const designSpecification = valEditor?.getHTML() ?? ''
        dispatch(updateSpecComponent({
            ...component,
            designSpecification,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                update(draft => {
                    const data = findComponent(draft.components, component.id)
                    if (data && valEditor) {
                        data.designSpecification = designSpecification
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [component])

    const handleDescriptionChange = () => {
        save(editor)
    }

    const editor = useEditor({
        extensions: tiptapExtensions,
        content: designSpecification,
        onUpdate: handleDescriptionChange,
    })

    return <RichTextContainer editor={editor} label={<span>{component.name}</span>} />
}

const OfferDescription = (props: SpecProps) => {
    const { component, setImage } = props
    const update = useContext(DataSetterContext)
    const dispatch = useAppDispatch()
    const { offerDescription, id } = component

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(offerDescription ?? '')
        }
    }, [component.id])

    const save = useCallback(debounce((valEditor: Editor | null) => {
        const offerDescription = valEditor?.getHTML() ?? ''
        dispatch(updateSpecComponent({
            ...component,
            offerDescription,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                update(draft => {
                    const data = findComponent(draft.components, component.id)
                    if (data && valEditor) {
                        data.offerDescription = offerDescription
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [component])

    const handleDescriptionChange = () => {
        save(editor)
    }

    const editor = useEditor({
        extensions: tiptapExtensions,
        content: offerDescription,
        onUpdate: handleDescriptionChange,
    })

    return <RichTextContainer editor={editor} label={<span>{component.name}</span>} />
}

const SpecDialogDispatcher = (props: SpecDialog) => {
    switch (props.tag) {
        case 'add-root-component':
            return <AddComponent specId={props.specId} parentComponentId={null} />
        case 'add-child-component':
            return <AddComponent specId={props.specId} parentComponentId={props.parentComponentId} />
        case 'edit-component':
            return <EditComponent data={props.data} />
        case 'add-image':
            return <AddImage guid={props.guid} setImageUrl={props.resolve} />
        case 'delete-component':
            return <DeleteSpecComponent component={props.component} />
        case 'edit':
            return <EditField field={props.field} data={props.data} />
        case 'none':
            return null
    }
}

interface DeleteSpecComponentProps {
    component: SpecComponent
}
const DeleteSpecComponent = (props: DeleteSpecComponentProps) => {
    const dispatch = useAppDispatch()
    const dialog = useContext(DevSpecDialogContext)
    const update = useContext(DataSetterContext)
    const { component } = props
    const { id, name, deviceSpecificationId, parentComponentId } = component
    const [deleting, setDeleting] = useState(false)

    const handleConfirmDeleteClick = () => {
        setDeleting(true)
        dispatch(deleteSpecComponent({
            specId: deviceSpecificationId,
            componentId: id,
        })).then(unwrapResult)
            .then(() => {
                dialog({ tag: 'none' })
                update(draft => {
                    if (parentComponentId) {
                        const parent = findComponent(draft.components, parentComponentId)
                        if (parent) {
                            parent.childComponents = parent.childComponents.filter(x => x.id !== id)
                        }
                    } else {
                        draft.components = draft.components.filter(x => x.id !== id)
                    }
                })
            }).finally(() => setDeleting(false))
    }
    const handleCancelDelClick = () => {
        dialog({ tag: 'none' })
    }

    return <Dialog open={true}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={deleting} onClick={handleConfirmDeleteClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

const AddComponent = ({ specId, parentComponentId }: { specId: number, parentComponentId: number | null }) => {
    const dispatch = useAppDispatch()
    const setDialog = useContext(DevSpecDialogContext)
    const setter = useContext(DataSetterContext)
    const [name, setName] = useState('')
    const [numeration, setNumeration] = useState('')
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const save = async () => {
        if (name) {
            setSaving(true)
            try {
                const newComp = await dispatch(createSpecComponent({
                    name,
                    functionalSpecification: '',
                    designSpecification: '',
                    parentComponentId,
                    deviceSpecificationId: specId,
                    offerDescription: '',
                    numeration,
                })).unwrap()
                setter(draft => {
                    if (newComp.parentComponentId) {
                        const parent = findComponent(draft.components, newComp.parentComponentId)
                        if (parent) {
                            parent.childComponents.push(newComp)
                        }
                    } else {
                        draft.components.push(newComp)
                    }
                })
                setDialog({ tag: 'none' })
            } catch (error) {
                setError(error as APIError)
            } finally {
                setSaving(false)
            }
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>New component</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
            <ServerErrorMsg err={error} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
            }}>
                <TextField
                    label={'Numeration'}
                    placeholder={'1.2.3'}
                    sx={{
                        my: 2,
                    }}
                    value={numeration}
                    onChange={e => setNumeration(e.target.value)}
                />
                <TextField
                    label={'Name'}
                    placeholder={'Component name...'}
                    sx={{
                        my: 2,
                    }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                    error={name === ''}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <ButtonGroup>
                <LoadingButton loading={saving} onClick={save}>
                    <Localized id='ok'>OK</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={() => setDialog({ tag: 'none' })}>
                    <Localized id='cancel'>Cancel</Localized>
                </LoadingButton>
            </ButtonGroup>
        </DialogActions>
    </Dialog>
}

const EditComponent = ({ data }: { data: SpecComponent }) => {
    const { deviceSpecificationId, parentComponentId, id, offerDescription, functionalSpecification, designSpecification } = data
    const dispatch = useAppDispatch()
    const setDialog = useContext(DevSpecDialogContext)
    const setter = useContext(DataSetterContext)
    const [name, setName] = useState(data.name)
    const [numeration, setNumeration] = useState(data.numeration)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const save = async () => {
        if (name) {
            setSaving(true)
            try {
                await dispatch(updateSpecComponent({
                    id,
                    name,
                    functionalSpecification,
                    designSpecification,
                    parentComponentId,
                    deviceSpecificationId,
                    offerDescription,
                    numeration,
                })).unwrap()
                setter(draft => {
                    const c = findComponent(draft.components, id)
                    if (c) {
                        c.name = name
                        c.numeration = numeration
                    }
                })
                setDialog({ tag: 'none' })
            } catch (error) {
                setError(error as APIError)
            } finally {
                setSaving(false)
            }
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>Edit component</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
            <ServerErrorMsg err={error} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
            }}>
                <TextField
                    label={'Numeration'}
                    placeholder={'1.2.3'}
                    sx={{
                        my: 2,
                    }}
                    value={numeration}
                    onChange={e => setNumeration(e.target.value)}
                />
                <TextField
                    label={'Name'}
                    placeholder={'Component name...'}
                    sx={{
                        my: 2,
                    }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                    error={name === ''}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <ButtonGroup>
                <LoadingButton loading={saving} onClick={save}>
                    <Localized id='ok'>OK</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={() => setDialog({ tag: 'none' })}>
                    <Localized id='cancel'>Cancel</Localized>
                </LoadingButton>
            </ButtonGroup>
        </DialogActions>
    </Dialog>
}

interface UploadRequest {
    file: File
    uid: string
}

interface GalleryProps {
    selected: string | undefined
    setSelected: (imgId: string | undefined) => void
}

const Gallery = (props: GalleryProps & BlobsProps) => {
    const { guid, blobs, upload, selected, setSelected } = props
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [files, setFiles] = useState<File[]>([])
    const [dragging, setDragging] = useState(false)
    // const [selected, setSelected] = useState<string | undefined>(undefined)

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setDragging(true)
    }, [])
    const handleDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setDragging(false)
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            const fs: File[] = []
            for (const file of dt.files) {
                if (file.type.startsWith('image/'))
                    fs.push(file)
            }
            if (fs.length > 0) {
                setFiles(st => st.concat(fs))
            }
        }
        setDragging(false)
    }, [])

    useEffect(() => {
        if (files.length > 0) {
            upload(files).then(() => setFiles([]))
        }
    }, [files])


    return <div
        id='gallery'
        ref={dropboxRef}
        className={clsx(dragging && 'dragging')}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}

        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
    >
        {blobs.map((b) => <div className={clsx(selected === b.blobId && 'highlight')} onClick={() => setSelected(b.blobId)}>
            <img
                src={`${process.env.REACT_APP_BLOB_API}/blobs/${guid}/${b.blobId}`}
                title={b.fileMeta.fileName}
                alt={b.fileMeta.fileName}
                loading='lazy'
            />
            <div>{b.fileMeta.fileName}</div>
        </div>)}
        {files.map((_, idx) => <UploadRequest key={idx} />)}
    </div>
}

const UploadRequest = () => {
    return <div className="request">
        <CircularProgress />
        <span>Uploading...</span>
    </div>
}

interface AddImageProps {
    guid: string
    setImageUrl: (url: string) => void
}
const AddImage = ({ guid, setImageUrl }: AddImageProps) => {
    const setDialog = useContext(DevSpecDialogContext)
    const [selected, setSelected] = useState<string | undefined>(undefined)

    const setSelectedImage = useCallback((url: string | undefined) => {
        setSelected(url)
    }, [])

    const handleOkClick = () => {
        if (selected) {
            setDialog({ tag: 'none' })
            setImageUrl(`${process.env.REACT_APP_BLOB_API}/blobs/${guid}/${selected}`)
        }
    }

    return <Dialog open={true} fullWidth maxWidth='lg'>
        <DialogTitle>Select image</DialogTitle>
        <DialogContent sx={{ p: 1 }}>
            <AppBlobsProvider<GalleryProps> guid={guid} component={Gallery} args={{
                selected,
                setSelected: setSelectedImage,
            }} />
        </DialogContent>
        <DialogActions>
            <ButtonGroup>
                <Button disabled={selected === undefined} onClick={handleOkClick}>
                    <Localized id='ok'>OK</Localized>
                </Button>
                <Button onClick={() => setDialog({ tag: 'none' })}>
                    <Localized id='cancel'>Cancel</Localized>
                </Button>
            </ButtonGroup>
        </DialogActions>
    </Dialog>
}

const EditField = (props: { field: HdrField, data: SpecWithDetails, }) => {
    const dispatch = useAppDispatch()
    const dialog = useContext(DevSpecDialogContext)
    const setter = useContext(DataSetterContext)

    const { data, field, } = props
    const { name, description, structureId, type } = data

    const [draftName, setDraftName] = useState(name)
    const [draftDescription, setDraftDescription] = useState(description)
    const [draftStructureId, setDraftStructureId] = useState(structureId)
    const [draftType, setDraftType] = useState(type)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    const handleOkClick = async () => {
        if (draftName && draftStructureId && type) {
            setSaving(true)
            try {
                await dispatch(updateSpec({
                    ...data,
                    name: draftName,
                    description: draftDescription,
                    structureId: draftStructureId,
                    type: draftType,
                })).unwrap()
                setter(draft => {
                    draft.name = draftName
                    draft.description = draftDescription
                    draft.structureId = draftStructureId
                    draft.type = draftType
                })
                dialog({ tag: 'none' })
            } catch (error) {
                setError(error as APIError)
            } finally {
                setSaving(false)
            }
        }
    }
    const handleCancelClick = () => {
        dialog({ tag: 'none' })
    }

    return <Dialog open={true} fullWidth maxWidth='lg'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            {field === 'name' && <TextField
                sx={{ my: 2 }}
                value={draftName}
                onChange={e => setDraftName(e.target.value)}
                label={<Localized id='spec-name'>Nazwa</Localized>}
                fullWidth
            />}
            {field === 'description' && <TextField
                sx={{ my: 2 }}
                value={draftDescription}
                onChange={e => setDraftDescription(e.target.value)}
                label={<Localized id='spec-description'>Opis</Localized>}
                fullWidth
                multiline
            />}
            {field === 'structure' && <DeviceTypeCombobox
                sx={{ my: 2 }}
                value={draftStructureId}
                onChange={v => setDraftStructureId(v)}
                onlyLeafs={true}
                label={<Localized id="structure">Struktura</Localized>}
                fullWidth
                required
                error={draftStructureId === ''}
                helperText={structureId === '' && <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>}
            />}
            {
                field === 'type' &&
                <DeviceSpecTypeDropdown
                    value={draftType}
                    onChange={setDraftType}
                />
            }
            <ServerErrorMsg err={error} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelClick}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
