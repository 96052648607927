import { Skeleton } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks";
import { CompanyDialogBrowser } from "../CompanyDialogBrowser";
import { loadAllUrsOffers, Offers } from "../UrsSlice";

export interface PublishDialogProps {
    ursId: AppId
    processing: boolean
    confirmPublish: (ids: Set<AppId>, suppliersIds: Set<AppId>) => void
    cancelPublish: () => void
}

export const PublishDialog = (props: PublishDialogProps) => {
    const { ursId, processing, confirmPublish, cancelPublish } = props
    const dispatch = useAppDispatch()
    const [offers, setOffers] = useState<Offers[] | null>(null)

    useEffect(() => {
        dispatch(loadAllUrsOffers(ursId))
            .then(unwrapResult)
            .then(setOffers)
            .catch((error) => console.error(error))
    }, [])

    if (offers === null) {
        return <Skeleton />
    }

    const supplierFilter = new Set<AppId>(offers.map(x => x.supplierId))

    return <CompanyDialogBrowser
        open={true}
        processing={processing}
        filter={supplierFilter}
        handleConfirmPublishClick={confirmPublish}
        handleCancelPublishClick={cancelPublish}
    />
}
