import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../app/appTypes"
import { BlobResponse, getBlobWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http"

export type DocumentStatus = 'N' | 'D' | 'I' | 'H' | 'F' | 'C' | "A" | "P" | "E" | "O" | "R"
export type DocumentNumberingMode = "ONLY_SIMPLE_REQ" | "UNIQUE_REQ" | "DECIMAL_HIERARCHICAL"
export type DocumentCreationMode = "Empty" | "Template" | "Document"
export type DocumentTypes = "RISK_ANALYSIS" | "URS" 
export type DocumentDescriptionField = "purpose" | "description" | "area" | "title"

export type DocumentContentActions
    = "Edit"
    | "Comment"
    | "ResolveComment"

    | "DeleteDocument"
    | "CreateTemplate"
    | "CopyDocument"
    | "AddDescriptionField"
    | "PrintReport"

    | "ChangeNumber"
    | "ChangeTitle"

    | "Changedeadline"
    | "AddTeamMember"
    | "DeleteTeamMember"
    | "SubstituteTeamMember"

export type DocumentWorkflowActions
    = "SendToOpinion"
    | "WithdrawFromOpinion"
    | "SendToApprove"
    | "Approve"
    | "Reject"
    | "FinishOpinion"
    | "FinishEdition"

export interface DocumentActions {
    documentStatus: string
    workflowActions: DocumentWorkflowActions[]
    documentContentActions: DocumentContentActions[]
}

export type Document = DocumentHeader & {

}

export interface DocumentHeader {
    id: AppId,
    no: string,
    name: string,
    docDesignation: string,
    version: number,
    headDocumentId: number,
    companyId: number,
    companyName: string,
    createDate: string,
    createdBy: string,
    createdByFullName: string,
    guid: string,
    title: string,
    purpose: string,
    area: string,
    description: string,
    readonlyeportTemplate: string,
    language: string,
    type: string,
    isTemplate: boolean
    isSystem: boolean
    status: string
    settings: DocumentSeetings[]
    structureId: string,
    projectProcessId: string
}

export interface DocumentSeetings {
    id: AppId,
    name: string,
    value: string
}

interface DocumentFilters {
    companyId: number | "all";
    dateFrom: string;
    dateTo: string;
    allDate: boolean;
    type: DocumentTypes | "all";
    status: DocumentStatus | "all";
}

export const loadAllDocuments = createAsyncThunk<DocumentHeader[], void, AppThunkAPIType>("/documents-overview/load",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/documents-overview` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadAllDocumentsByFilters = createAsyncThunk<DocumentHeader[], DocumentFilters, AppThunkAPIType>("/documents-overview/load",
    async (filters, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/system/documents-overview?allDate=${filters.allDate}&dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}&type=${filters.type}&status=${filters.status}${filters.companyId !== "all" ? "&companyId=" + filters.companyId : ""}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadDocument = createAsyncThunk<DocumentHeader, string, AppThunkAPIType>(
    "documents/load", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/document/${id}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadDocumentVersions = createAsyncThunk<DocumentHeader[], { documentId: AppId; }, AppThunkAPIType>("/documents-versions/load",
    async ({ documentId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/document/versions/${documentId}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const downloadReport = createAsyncThunk<BlobResponse, { documentId: string; language: string }, AppThunkAPIType>(
    "document-report/download",
    async ({ documentId, language }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getBlobWithAuth({ url: `api/reports/document/${documentId}?language=${language}` }));

        if (getBlobWithAuth.fulfilled.match(result)) {
            return result.payload as BlobResponse;
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' });
        }
    }
);

export const updateDescriptionField = createAsyncThunk<void, { documentId: AppId, fieldName: DocumentDescriptionField, value: string }, AppThunkAPIType>(
    "document/updateDescriptionField", async (data, { dispatch, getState, rejectWithValue }) => {
        const { documentId, fieldName, value } = data
        const result = await dispatch(putWithAuth({
            url: `api/document/${documentId}/description-fields`,
            payload: { fieldName, text: value },
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateDocumentNumber = createAsyncThunk<void, { documentId: AppId, documentNumber: string }, AppThunkAPIType>(
    "document/change-number", async (data, { dispatch, getState, rejectWithValue }) => {
        const { documentId, documentNumber } = data
        const result = await dispatch(putWithAuth({
            url: `api/document/${documentId}/change-number`,
            payload: { documentNumber: documentNumber },
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateDocumentSetting = createAsyncThunk<void, { documentId: AppId, name: string, value: string }, AppThunkAPIType>(
    "document/updateDocumentSetting", async (data, { dispatch, getState, rejectWithValue }) => {
        const { documentId, name, value } = data
        const result = await dispatch(putWithAuth({
            url: `api/document/${documentId}/settings`,
            payload: { name: name, value: value },
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadDocumentActions = createAsyncThunk<DocumentActions, string, AppThunkAPIType>(
    "documents/load-actions", async (documentId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/document-actions/${documentId}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentActions
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export interface PerformActionPayload {
    action: DocumentWorkflowActions
    password: string,
    deadline: string,
    comment: string
}


export const performDocumentAction = createAsyncThunk<DocumentActions, { documentId: AppId, actionPayload: PerformActionPayload }, AppThunkAPIType>(
    "document/performdocumentaction", async (data, { dispatch, rejectWithValue }) => {
        const { documentId, actionPayload } = data
        const result = await dispatch(postWithAuth({
            url: `api/document-actions/${documentId}/runaction`,
            payload: actionPayload,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as DocumentActions
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const resetWorkflow = createAsyncThunk<DocumentHeader, string, AppThunkAPIType>(
    "document/reset-workflow", async (documentId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/system/documents-overview/${documentId}/reset-workflow`, payload: {}
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as DocumentHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })