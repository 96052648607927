import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Chip } from "@mui/material";

const rows = [
    { id: 1, ursNo: "URS-1", OFFERID: ["p.1.1"],  IQ: ["Test 1"], PQ: ["Test A"], DQ: [], OQ: ["Test O1"], FAT: [], SAT: ["Test S1"], DS: ["Test D1"], FS: ["chapter 1.1"] },
    { id: 2, ursNo: "URS-2", OFFERID: ["p.1.2"], IQ: ["Test 2"], PQ: [], DQ: ["Test X"], OQ: ["Test O2"], FAT: [], SAT: ["Test S2"], DS: ["Test D2"], FS: ["chapter 1.2"] },
    { id: 3, ursNo: "URS-3",  OFFERID: ["p.1.3"], IQ: ["Test 3"], PQ: ["Test A", "Test B"], DQ: ["Test Y"], OQ: [], FAT: ["Test F1"], SAT: [], DS: ["Test D1", "Test D3"], FS: ["Test F2"] },
    { id: 4, ursNo: "URS-4", OFFERID: ["p.1.4"],IQ: ["Test 1", "Test 3"], PQ: ["Test B"], DQ: ["Test X", "Test Z"], OQ: ["Test O1", "Test O2"], FAT: ["Test F2"], SAT: ["Test S1", "Test S2"], DS: ["Test D2"], FS: ["Test F1", "Test F2"] },
    { id: 5, ursNo: "URS-5", OFFERID: ["p.1.4"],IQ: [], PQ: ["Test C"], DQ: [], OQ: ["Test O2"], FAT: [], SAT: [], DS: [], FS: [] },
    { id: 6, ursNo: "URS-6", OFFERID: ["p.1.8"],IQ: ["Test 2"], PQ: [], DQ: [], OQ: [], FAT: ["Test F1", "Test F2"], SAT: ["Test S1"], DS: ["Test D1"], FS: ["Test F1"] },
    { id: 7, ursNo: "URS-7", OFFERID: ["p.2"],IQ: ["Test 1"], PQ: ["Test A"], DQ: ["Test Z"], OQ: ["Test O1"], FAT: ["Test F1"], SAT: [], DS: ["Test D3"], FS: [] },
    { id: 8, ursNo: "URS-8", OFFERID: ["p.2.2"],IQ: ["Test 2", "Test 3"], PQ: [], DQ: ["Test X"], OQ: ["Test O2"], FAT: [], SAT: ["Test S2"], DS: ["Test D2"], FS: ["Test F2"] },
    { id: 9, ursNo: "URS-9", OFFERID: ["p.2.5"],IQ: ["Test 1"], PQ: ["Test B"], DQ: [], OQ: ["Test O1"], FAT: [], SAT: [], DS: [], FS: ["Test F1"] },
    { id: 10, ursNo: "URS-10", OFFERID: ["p.5"], IQ: [], PQ: ["Test C"], DQ: ["Test Y"], OQ: [], FAT: ["Test F2"], SAT: [], DS: [], FS: [] },
];

const columns = [
    { field: "ursNo", headerName: "URS No", width: 120 },
    {
        field: "OFFERID",
        headerName: "Offer",
        width: 150,
        renderCell: (params) => renderChips(params.value),
    },
    {
        field: "FS",
        headerName: "FS",
        width: 150,
        renderCell: (params) => renderChips(params.value),
    },
    {
        field: "DS",
        headerName: "DS",
        width: 150,
        renderCell: (params) => renderChips(params.value),
    },
    {
        field: "DQ",
        headerName: "DQ",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    },
    {
        field: "FAT",
        headerName: "FAT",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    },


    {
        field: "IQ",
        headerName: "IQ",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    },

    {
        field: "OQ",
        headerName: "OQ",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    },
    {
        field: "SAT",
        headerName: "SAT",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    },
   
    {
        field: "PQ",
        headerName: "PQ",
        width: 200,
        renderCell: (params) => renderChips(params.value),
    }
    
];

const renderChips = (tests) => {
    if (!tests || tests.length === 0) return "-";
    const colors = ["primary", "secondary", "success", "info", "warning", "error"];

    return (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
            {tests.map((test, index) => (
                <Chip
                    key={index}
                    label={test}
                    clickable
                    component="a"
                    href={`#${test}`} // Możesz podpiąć realne linki
                    variant="filled"
                    color="secondary" // Losowanie kolorów
                />
            ))}
        </Box>
    );
};

const CrosslinkMockupTableWithColoredChips = () => {
    return (
        <Box sx={{ height: 600, width: "100%", p: 2 }}>
            <DataGrid rows={rows} columns={columns} />
        </Box>
    );
};

export default CrosslinkMockupTableWithColoredChips;
