import { Supplier, updateSupplier, selectSupplierById, createSupplier } from "./suppliersSlice"
import SaveIcon from '@mui/icons-material/Save'
import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Box, Card, CardContent, Grid, Paper, Skeleton, Stack, Tab, Typography } from "@mui/material";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Link, useParams } from "react-router-dom";
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { LoadAreas } from "../../app/AppDataLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getWithAuth, putWithAuth } from "../../http";
import { checkStructureNodes, selectStructure, uncheckAllStructureNodes, uncheckStructureNodes } from "../device/structureSlice";
import { store } from "../../app/store";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { selectUserCompanyId } from '../user/userSlice';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {StructureTreeComponent} from "../device/Structure"
import {selectStructureRelationship} from "../device/structureRelationshipSlice"
import {If} from "../../app/If"
import { useNavigate } from "react-router-dom"

const PREFIX = 'SupplierForm';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    paper: `${PREFIX}-paper`,
    shortNameField: `${PREFIX}-shortNameField`,
    nameField: `${PREFIX}-nameField`,
    field: `${PREFIX}-field`
};
interface SupplierStructure {
    supplierId: AppId
    structureId: AppId
    
}
const loadSupplierStructure = 
    createAsyncThunk<SupplierStructure[], AppId, AppThunkAPIType>("supplier_structure_access/load", async (supplierId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/supplier-structure/${supplierId}`,
        }))

        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as SupplierStructure[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
    const saveSupplierStructure = 
    createAsyncThunk<SupplierStructure[], [AppId, SupplierStructure[]], AppThunkAPIType>("supplier_structure_access/save", async ([supplierId, items], { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/supplier-structure/${supplierId}`,
            payload: items,
        }))
        if (putWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
    type FormTabs = "contact" | "areas"

type StructureState = "empty" | "loading" | "loaded" | "error"

export type SupplierFormProps = {
    entity: Supplier | undefined,
}
export const SupplierForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectSupplierById (state, id));
    const [shortName, setShortName] = useState<string>(entity?.shortName ?? "");
    const [name, setName] = useState<string>(entity?.name ?? "");
    const companyId = useAppSelector(state => selectUserCompanyId(state))
    const [address1, setAddress] = useState<string>(entity?.address1 ?? "");
    const [address2, setAddressSecond] = useState<string>(entity?.address2 ?? "");
    const [city, setCity] = useState<string>(entity?.city ?? "");
    const [state, setState] = useState<string>(entity?.state ?? "");
    const [zipcode, setZipcode] = useState<string>(entity?.zipcode ?? "");
    const [country, setCountry] = useState<string>(entity?.country ?? "");
    const [contactPerson, setContactPerson] = useState<string>(entity?.contactPerson ?? "");
    const [email, setEmail] = useState<string>(entity?.email ?? "");
    const [phone, setPhone] = useState<string>(entity?.phone ?? "");
    const [mobile, setMobile] = useState<string>(entity?.mobile ?? "");

    const [shortNameError, setShortNameError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    
    const [tab, setTab] = useState<FormTabs>("contact");

    const [structure, setStructure] = useState<StructureState>("empty")

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const rel = useAppSelector(selectStructureRelationship)
    const handleTreeNodeChecked = useCallback((nodeId: AppId) => {
        // if some non-leaf node is checked all children must be checked also
        const relation = rel.entities[nodeId]
        if (relation) {
            dispatch(checkStructureNodes(relation.succs.map(i => i.structureId)))
        }
    }, [rel, dispatch])
    const handleTreeNodeUnchecked = useCallback((nodeId: AppId) => {
        // if non-leaf node is unchecked all children must be unchecked also
        const relation = rel.entities[nodeId]
        if (relation) {
            dispatch(uncheckStructureNodes(relation.succs.map(i => i.structureId)))
        }
    }, [rel, dispatch])

    useEffect(() => {
        const load = async (supplierId: AppId) => {
            return await dispatch(loadSupplierStructure(supplierId)).unwrap()
        }
        if (tab === "areas" && structure === "empty") {
            setStructure("loading")
            load(id).then(x => {
                dispatch(uncheckAllStructureNodes())
                dispatch(checkStructureNodes(x.map(({ structureId }) => structureId)))
                setStructure("loaded")
            }).catch(() => {
                setStructure("error")
            })

        }
    }, [dispatch, tab, structure, id])


    const handleSaveClick = async () => {
        ClearErrors();
        if (!Validate()){
            return;
        }
        if (companyId === null) return
        if (!entity) {
            try {
                const actionResult = await dispatch(createSupplier({
                    name,
                    shortName,
                    address1,
                    address2,
                    city,
                    state,
                    zipcode,
                    country,
                    contactPerson,
                    email,
                    phone,
                    mobile,
                    companyId
                }));
                const { id: newId } = unwrapResult(actionResult);
                

                dispatch(showSuccess("saved"));

                navigate(`/suppliers/edit/${newId}`, { replace: true });
            } catch (error) {
                dispatch(showError("error"));
            }
        } else {
            try {
                await dispatch(updateSupplier({...entity, 
                    name, 
                    shortName,      
                    address1,
                    address2,
                    city,
                    state,
                    zipcode,
                    country,
                    contactPerson,
                    email,
                    phone,
                    mobile
                }));
                if (structure === "loaded") {
                    const checkNodes = selectStructure(store.getState()).tree.multiselection
                    const items: SupplierStructure[] = Object.entries(checkNodes).map(([structureId, _]) => {
                        return {
                            structureId,
                            supplierId: entity.id,
                        }
                    })
                    await dispatch(saveSupplierStructure([entity.id, items]))
                }
                dispatch(showSuccess("saved"));
            } catch (error) {
                dispatch(showError("error"));
            }
        }
    }


     function ClearErrors(){
        setShortNameError(false);
        setNameError(false); 
        setEmailError(false);       
    }

    function Validate() {
        let valResult = true;
        if(shortName === '')
        {
            valResult = false;
            setShortNameError(true);
        }
        if(name === '')
        {
            valResult = false;
            setNameError(true);
        }
        if(email==='')
        {
            valResult = false;
            setEmailError(true)
        }
              
        return valResult;
    }
    const handleShortNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setShortName(e.target.value), [setShortName]);

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setName(e.target.value), [setName]);
    
    const handleAddressChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setAddress(e.target.value), [setAddress]);

    const handleAddressSecond: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setAddressSecond(e.target.value), [setAddressSecond]);
                
    const handleCityChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setCity(e.target.value), [setCity]);
    
    const handleStateChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setState(e.target.value), [setState]);        
    
    const handleZipCodeChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setZipcode(e.target.value), [setZipcode]);        
                            
    const handleCountryChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setCountry(e.target.value), [setCountry]);        
                                                    
    const handleContactPersonChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setContactPerson(e.target.value), [setContactPerson]);        
                                                                 
     const handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setEmail(e.target.value), [setEmail]); 

    const handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setPhone(e.target.value), [setPhone]);                 
                                
    const handleMobileChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setMobile(e.target.value), [setMobile]);                 
    

    const handleTabChange = useCallback((_, newTab: FormTabs) => {
            setTab(newTab)
    }, [])

    return (
        <LoadAreas component={<Skeleton height="50vh" variant="rectangular" />}>
            <Container component={Paper} maxWidth={false} sx={{paddingTop:2}}>
                <Grid container spacing={2} >
                    <Grid  item xs={12}>
                        <TextField 
                            required                            
                            value={shortName}
                            sx = {{width: "25%"}}
                            onChange={handleShortNameChange}   
                            error={shortNameError}                      
                            label={<Localized id="companies-short-name">Nazwa skrócona</Localized>}></TextField>
                    </Grid>
                    <Grid  item xs={12}>                        
                        <TextField
                            required    
                            fullWidth
                            value={name}
                            onChange={handleNameChange}
                            error={nameError}                      
                            label={<Localized id="companies-name">Nazwa</Localized>}></TextField>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        
                        <Tab 
                            key={0}
                            value={"contact"}
                            label={<Localized id="companies-contact-data">Dane Kontaktowe</Localized>} />
                        <Tab 
                            key={1}
                            value={"areas"}
                            label={<Localized id="companies-areas">Obszary działalności</Localized>} />

                    </TabList>
                    <TabPanel value="contact" >
                        <Stack  direction='row'spacing={3}>
                            <Grid container item xs={6} direction="column" >
                                <Card className={classes.root} variant="outlined">
                                    <CardContent>                                  
                                        <Stack direction="column" spacing={2}>
                                            <Typography className={classes.title} color="textSecondary" >
                                                {<Localized id="companies-address-data">Dane adresowe</Localized> }
                                            </Typography>
                                            <TextField
                                                className={classes.field}
                                                value={address1}
                                                onChange={handleAddressChange}
                                                label={<Localized id="companies-address-first">Adres 1</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={address2}
                                                onChange={handleAddressSecond}
                                                label={<Localized id="companies-address-second">Adres 2</Localized> }></TextField>
                                            <Stack direction="row" spacing={2}>
                                                <TextField                                          
                                                    sx={{width: "50%"}}
                                                    value={city}
                                                    onChange={handleCityChange}
                                                    label={<Localized id="companies-address-city">Miasto</Localized> }></TextField>
                                                <TextField
                                                
                                                    sx={{width: "50%"}}
                                                    value={state}
                                                    onChange={handleStateChange}
                                                    label={<Localized id="companies-address-state">Stan/Region</Localized> }></TextField>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    sx={{width: "50%"}}
                                                    value={zipcode}
                                                    onChange={handleZipCodeChange}
                                                    label={<Localized id="companies-address-zip-code">Zip/Postal code</Localized> }></TextField>
                                                <TextField
                                                    sx={{width: "50%"}}
                                                    value={country}
                                                    onChange={handleCountryChange}
                                                    label={<Localized id="companies-address-country">Country</Localized> }></TextField>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>  
                        
                            <Grid container item xs={6} direction="column" >
                                <Card className={classes.root} variant="outlined">
                                    <CardContent>                

                                        <Stack direction="column" spacing={2}>
                                            <Typography className={classes.title} color="textSecondary" >
                                                {<Localized id="companies-contact-data">Dane kontaktowe</Localized> }
                                            </Typography>
                                            <TextField
                                                className={classes.field}
                                                value={contactPerson}
                                                onChange={handleContactPersonChange}
                                                label={<Localized id="companies-contact-person">Osoba kontaktowa</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                required      
                                                value={email}
                                                onChange={handleEmailChange}
                                                error={emailError}  
                                                label={<Localized id="companies-email">Email</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                label={<Localized id="companies-phone">Tel</Localized> }></TextField>
                                            <TextField
                                                className={classes.field}
                                                value={mobile}
                                                onChange={handleMobileChange}
                                                label={<Localized id="companies-mobile">Mobile</Localized> }></TextField>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>    
                        </Stack>                               
                    </TabPanel>

                    <TabPanel value="areas">
                        <Box>
                            <If condition={structure === "loaded"} otherwise={<Skeleton height={200} />}>
                                <StructureTreeComponent 
                                    editable={false} 
                                    multiselect={true} 
                                    showCounter={true}
                                    onNodeChecked={handleTreeNodeChecked}
                                    onNodeUnchecked={handleTreeNodeUnchecked}
                                />
                            </If>
                        </Box>
                    </TabPanel>
                    
                    
                </TabContext>
                <Box sx={{paddingBottom:2}}>
                    <ButtonGroup sx={{marginTop:4}}  color="secondary" variant="contained">
                        <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                        </Button>
                        <Button  component={Link} to={`/supplier`} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </Box>
               
                </Container>
            </LoadAreas>
    );
}   
