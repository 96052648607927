import { createAsyncThunk } from "@reduxjs/toolkit"
import { ProjectProcess, ProjectProcessHeader } from "../projectProcess/ProjectProcessApiClient"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { getWithAuth, postWithAuth } from "../../../http"

export const loadSupplierDocumentationProcesses = createAsyncThunk<ProjectProcessHeader[], void, AppThunkAPIType>(
    "documentation-processes/supplier/load-documentation", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/documentation-processes/supplier" }))
        const { payload } = result

        if (getWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export interface CreateSupplierDocProcessPayload {
    no: string
    name: string
    ursRequestId: string | null
    reqisterNewDevice: boolean
    serialNo: string
    deviceName: string
}

export const createSupplierDocProcess = createAsyncThunk<ProjectProcessHeader, CreateSupplierDocProcessPayload, AppThunkAPIType>(
    "documentation-processes/supplier/createSupplierDocProcess",
    async (createProcessPayload: CreateSupplierDocProcessPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/documentation-processes/supplier",
            payload: createProcessPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const generateSupplierDocuments = createAsyncThunk<ProjectProcess, { processId: AppId, specId: number, document: string}, AppThunkAPIType>(
    "documentation-processes/supplier/generateSupplierDocuments",
    async ({  processId , specId, document}, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/documentation-processes/supplier/generate/`,
            payload: {
                processId: processId,
                specId: specId,
                document: document
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcess
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
