import { UrsOfferRequirement } from "./ursOfferSlice"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import Tab from "@mui/material/Tab"
import { Comment, DiscussOld } from "../../app/Discuss";
import './specMatching.css'
import { SpecComponent, SpecWithDetails } from "../specs/specSlice";
import { SpecItemsTree } from "../specs/SpecItemsTree";
import { useCallback, useState } from "react";
import { Alert, Box, Chip, Divider, Fab } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectLoggedUserId } from "../user/userSlice";
import CableIcon from '@mui/icons-material/Cable';

export interface MatchRequirementWithSpecProps {
    requirement: UrsOfferRequirement
    publicComments: Comment[]
    privateCommenst: Comment[]
    specDetails: SpecWithDetails
    match: (specComponentId: string) => void
}

type Tab = 'offer' | 'fs' | 'ds'

const findComponent = (cs: SpecComponent[], id: number): SpecComponent | null => {
    for (let i = 0; i < cs.length; i++) {
        if (cs[i].id === id) {
            return cs[i]
        } else {
            const child = findComponent(cs[i].childComponents, id)
            if (child) {
                return child
            }
        }
    }
    return null
}

export const MatchRequirementWithSpec = (props: MatchRequirementWithSpecProps) => {
    const { match, requirement, publicComments, privateCommenst, specDetails } = props
    const currentUserId = useAppSelector(selectLoggedUserId)

    const [tab, setTab] = useState<Tab>('offer')
    const [componentId, setComponentId] = useState<string | undefined>(requirement.deviceComponentId ?? undefined)
    const handleComponentSelection = useCallback((newId: string) => {
        setComponentId(newId)
    }, [])

    const idNum = parseInt(componentId ?? '')
    const selectedComponent: SpecComponent | null = findComponent(specDetails.components, idNum)

    const showMatchBtn = componentId !== null && componentId !== requirement.deviceComponentId
    const matched = requirement.deviceComponentId && componentId === requirement.deviceComponentId

    let content = <></>
    if (specDetails.components.length === 0) {
        content = <div className="">
            Drzewo komponentow jest puste.
        </div>
    } else {
        content = <TabContext value={tab}>
            <TabList onChange={(_, newTab) => setTab(newTab)}>
                <Tab label='Offer' value='offer' />
                <Tab label='Functional specification' value='fs' />
                <Tab label='Design specification' value='ds' />
            </TabList>
            <TabPanel sx={{ px: 0, py: 0.5, }} value='offer'>
                {matched && <Alert sx={{ mt: 1 }} severity='success'>
                    <span>{`${selectedComponent?.name ?? ''} matched with requirement ${requirement.numeration}`}</span>
                </Alert>}
                {selectedComponent !== null && <div dangerouslySetInnerHTML={{ __html: selectedComponent.offerDescription ?? '' }}  />}
                {selectedComponent !== null && showMatchBtn && <Fab sx={{
                    position: 'absolute',
                    right: 32,
                    bottom: 100,
                }} color='secondary' onClick={() => {
                    if (componentId) {
                        match(componentId)
                    }
                }}>
                    <CableIcon />
                </Fab>}
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 0.5 }} value='fs'>
                {selectedComponent !== null && <div dangerouslySetInnerHTML={{ __html: selectedComponent.functionalSpecification ?? '' }}  />}
            </TabPanel>
            <TabPanel sx={{ px: 0, py: 0.5 }} value='ds'>
                {selectedComponent !== null && <div dangerouslySetInnerHTML={{ __html: selectedComponent.designSpecification ?? '' }}  />}
            </TabPanel>
        </TabContext>
    }

    return <div className="spec-matching">
        <div className="tile header">
            <div className="title">
                Requirement description
                {componentId}&nbsp;{requirement.deviceComponentId}
            </div>
            <Divider />
            <Chip sx={{ mt: 1 }} variant="filled" label={requirement.numeration}></Chip>
            <div dangerouslySetInnerHTML={{ __html : requirement.name}}></div>
        </div>
        <div className="tile header">
            <div className="title">
                Internal discussion
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={privateCommenst}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile header">
            <div className="title">
                Discussion with client
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={publicComments}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile content">
            <div className="title">
                Specification items
            </div>
            <Divider />
            <Box sx={{ my: 1 }}>
                <SpecItemsTree readonly selectedItemId={componentId} data={specDetails} onSelectionChange={handleComponentSelection} />
            </Box>
        </div>
        <div className="tile spec-detail content">
            {content}
        </div>
    </div>
}
