import React from 'react';
import { Box, Typography, Paper, IconButton, List, ListItem, ListItemText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const Tools = () => {
  return (
    
    <Paper elevation={1} sx={{ padding: 2, maxWidth: 600, margin: '0 auto', marginTop: 2 }}>
      {/* Header with Edit Icon */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h6">Tools, Technologies and Methods</Typography>
        <IconButton color="primary" size="small">
          <EditIcon />
        </IconButton>
      </Box>

      {/* Content */}
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Tools approved for use on this project include those for documentation
      </Typography>

      {/* Tool Categories */}
      <Box>
        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
          WORD PROCESSING
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Microsoft Word for Windows" secondary="(file type for documentation is .pdf)" />
          </ListItem>
        </List>

        <Typography variant="subtitle1" sx={{ marginBottom: 1, marginTop: 2 }}>
          DRAWINGS
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="AutoCAD" secondary="(file type for documentation is .dxf or .dwg)" />
          </ListItem>
        </List>

        <Typography variant="subtitle1" sx={{ marginBottom: 1, marginTop: 2 }}>
          CIRCUIT DIAGRAMS
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="TREE CAD" secondary="(file type for documentation is .pdf)" />
          </ListItem>
        </List>

        <Typography variant="subtitle1" sx={{ marginBottom: 1, marginTop: 2 }}>
          FILE COMPRESSION
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="WINZIP" secondary="(Any compressed files will be compressed using WINZIP)" />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};

export default Tools;
