import { styled } from '@mui/material/styles'
import { format, isAfter, parseISO } from 'date-fns'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const FlexDiv = styled('div')(() => ({
    display: 'flex',
}))

const f = "dd-LL-uuuu"
const h = "dd-LL-uuuu HH:mm"

export const parseAndFormatISODate = (inputISO: string, withhour: boolean = true): string => {
    if (inputISO) {
        return format(parseISO(inputISO), withhour ? h : f)
    }
    return ""
}

export const parseAndFormatISODateWithTodayCompare = (inputISO: string, withhour: boolean = true, checkDeadline: boolean = true) => {
    if (inputISO) {
        const date = parseISO(inputISO);
        const formattedDate = format(date, withhour ? h : f);

        const currentDate = new Date();

        if (checkDeadline && isAfter(currentDate, date)) {
            return <div>{formattedDate} <PriorityHighIcon fontSize='small' color='error' /></div>;
        }

        return formattedDate;
    }
    return ""
}

export const parseISODate = (inputISO: string): Date => parseISO(inputISO)

export const formatDate = (d: Date, withhour: boolean = true): string => format(d, withhour ? h : f)

export const formatTime = (d: Date): string => format(d, 'HH:mm')

export function debounce<F extends (...args: any[]) => any>(func: F, waitFor: number) {
    let timeout: NodeJS.Timeout
    return (...args: Parameters<F>): Promise<ReturnType<F>> => {
        return new Promise((resolve) => {
            if (timeout) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => resolve(func(...args)), waitFor)
        })
    }
}

export const debounceTest = debounce((a: number, b: number) => console.log(a + b), 1000)

export const parseIntOrDef = (input: string | null | undefined, def: number): number => {
    const x = parseInt(input ?? '')
    return isNaN(x) ? def : x
}

export const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};