import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrintIcon from '@mui/icons-material/Print';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Localized } from '@fluent/react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from '../../../app/hooks';
import { selectSettings } from '../../settings/settingsSlice';
import { Divider } from '@mui/material';
import { DeleteSpecDialog } from './DeleteSpecDialog';

export interface SpecificationToolbarMenuProps {
    specId: number
    onDelete: (specId: number) => void
}

export const SpecificationToolbarMenu = ({ specId, onDelete }: SpecificationToolbarMenuProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { locale } = useAppSelector(selectSettings);
    const [deteleDialogVisible, setDeteleDialogVisible] = useState<boolean>(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDeteleDialogVisible(false)
    };

    const onConfirmedDeleted = () => {
        setAnchorEl(null);
        setDeteleDialogVisible(false)
        onDelete(specId)
    };

    const handlePrintClick = () => {
        setAnchorEl(null);
        if (specId) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/report/specification/${specId}?language=${locale}`, '_blank')
        }
    }

    const handleDeleteClick = () => {
        setDeteleDialogVisible(true)
    }

    return (
        <div>
            <Button
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
            >
                <Localized id="menu">
                    <span>Menu</span>
                </Localized>
            </Button>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handlePrintClick()}>
                    <PrintIcon sx={{ mr: 1 }} />
                    <Localized id="print">
                        <span>_Print</span>
                    </Localized>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleDeleteClick()}>
                    <DeleteIcon sx={{ mr: 1 }} />
                    <Localized id="delete">
                        <span>_delete</span>
                    </Localized>
                </MenuItem>
            </Menu>
            {
                deteleDialogVisible &&
                <DeleteSpecDialog
                    specId={specId}
                    onDelete={onConfirmedDeleted}
                    onCancel={handleClose} />
            }
        </div>
    );
};