import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Chip, Typography } from "@mui/material";

const ValidationProcessesTable = () => {
  const [processes, setProcesses] = useState([
    {
      id: 1,
      name: "Validation process 2020 for Client XYZ",
      startDate: "2020-01-01",
      endDate: "2020-12-31",
      status: "finished",
    },
    {
      id: 2,
      name: "Revalidation process 2025",
      startDate: "2025-01-01",
      endDate: "2025-12-31",
      status: "inprogress",
    },
  ]);

  const [expandedRow, setExpandedRow] = useState(null);

  const documents = [
    { id: 1, name: "FS" },
    { id: 2, name: "DS" },
    { id: 3, name: "IQ" },
    { id: 4, name: "OQ" },
    { id: 5, name: "PQ" },
    { id: 6, name: "DQ" },
    { id: 7, name: "FAT" },
    { id: 8, name: "SAT" },
    { id: 9, name: "Crosslink" },
  ];

  const generateDocumentRows = (processId) => {
    const deviceId = "5554w5235w5w5";
    const processData = processes.find((p) => p.id === processId);
    if (!processData) return [];

    const headerRow = {
      id: `header-${processId}`,
      name: "Documents",
      isHeader: true,
    };

    const documentRows = documents.map((doc) => ({
      id: `${processId}-${doc.id}`, // Unikalny ID dla dokumentu
      name: `${doc.name} for device ${deviceId}`,
      number: `${doc.name}/${deviceId}/${processData.startDate}`,
      status: "Approved",
      isDocument: true, // Flaga pomocnicza dla wierszy dokumentów
    }));

    return [headerRow, ...documentRows];
  };

  const rows = processes.flatMap((process) => {
    const processRow = {
      id: process.id,
      name: process.name,
      startDate: process.startDate,
      endDate: process.endDate,
      status: process.status,
      isDocument: false, // Flaga pomocnicza dla wierszy procesów
    };

    // Dodajemy dokumenty z nagłówkiem, jeśli wiersz jest rozwinięty
    if (expandedRow === process.id) {
      return [processRow, ...generateDocumentRows(process.id)];
    }
    return [processRow];
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => {
        if (params.row.isHeader) {
          return (
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {params.value}
            </Typography>
          );
        }
        if (params.row.isDocument) {
          return (
            <Typography sx={{ marginLeft: 4 }}>{params.value}</Typography> // Wcięcie dla dokumentów
          );
        }
        return params.value;
      },
    },
    { field: "number", headerName: "Number", width: 300 },
    { field: "startDate", headerName: "Start Date", width: 150 },
    { field: "endDate", headerName: "End Date", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) =>
        params.row.isDocument ? (
          <Chip label={params.value} color="success" size="small" />
        ) : params.row.isHeader ? null : (
          <Chip
            label={params.value}
            color={
              params.value === "inprogress"
                ? "primary"
                : params.value === "finished"
                ? "success"
                : "default"
            }
            variant="outlined"
          />
        ),
    },
  ];

  const handleRowClick = (row) => {
    if (row.isDocument || row.isHeader) return; // Kliknięcie na dokumenty/nagłówki nie zmienia stanu
    setExpandedRow((prev) => (prev === row.id ? null : row.id)); // Rozwijanie/Zwijanie procesów
  };

  return (
    <Box sx={{ width: "100%", height: 600 }}>
      <Typography variant="h6" gutterBottom>
        Validation Processes with Documents
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={(params) => handleRowClick(params.row)}
       
      />
    </Box>
  );
};

export default ValidationProcessesTable;
