import React from "react";
import { MenuItem, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { DeviceSpecificationType } from "./specSlice";
import { Localized } from "@fluent/react";

interface DeviceSpecTypeDropdownProps {
    value: DeviceSpecificationType | "";
    onChange: (value: DeviceSpecificationType) => void;
}

const DeviceSpecTypeDropdown: React.FC<DeviceSpecTypeDropdownProps> = ({ value, onChange }) => {
    const handleChange = (event: SelectChangeEvent<DeviceSpecificationType>) => {
        onChange(event.target.value as DeviceSpecificationType);
    };

    return (
        <FormControl required fullWidth sx={{ minWidth: 80 , marginTop:1}}  error={value === ''}>
            <InputLabel id="dropdown-label"><Localized id='spec-type'>_Spec type</Localized></InputLabel>
            <Select
                labelId="dropdown-label"
                value={value}
                required
                onChange={handleChange}
                label={<Localized id='spec-type'>_Spec type</Localized>}
            >
                <MenuItem value="FS">FS</MenuItem>
                <MenuItem value="DS">DS</MenuItem>
                <MenuItem value="FDS">FDS</MenuItem>
            </Select>
        </FormControl>
    );
};

export default DeviceSpecTypeDropdown;
