import { Box, Divider, FormHelperText, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Editor, EditorConsumer, EditorContent, EditorContext, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import './RichTextEditor.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { useCallback, useEffect, useRef, useState } from 'react';
import Mention from '@tiptap/extension-mention';
import { MentionUser, mkSuggestion } from './suggestion';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import { Localized } from '@fluent/react';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Link from '@tiptap/extension-link';
import Text from '@tiptap/extension-text';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AttachFileIcon from '@mui/icons-material/AttachFile';

//TODO: incorporate tiptap-extension-resizable-image into cobdebase and remove from package.json
import 'tiptap-extension-resizable-image/styles.css';
import { ResizableImage } from 'tiptap-extension-resizable-image';
import Placeholder from '@tiptap/extension-placeholder';
import { useAppDispatch } from './hooks';
import { BlobMeta, buildBlobUri } from '../features/urs/ursAttachementsSlice';
import { isFileAnImage } from '../features/urs/Attachements';

export interface RichTextEditorProps {
    value         : string
    onValueChange : (newValue : string) => void
    label         : React.ReactNode | null
    uploadFile?    : (fs : File[]) => Promise<[string,BlobMeta]>
    placeholder?  : string
}

const BoxWithBorder = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2),
}));

interface MenuBarProps {
    uploadFile?    : (fs : File[]) => Promise<[string,BlobMeta]>
}
const MenuBar = (props: MenuBarProps) => {
    const { uploadFile } = props
    const { editor } = useCurrentEditor()

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleTableClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleTableClose = () => {
        setAnchorEl(null)
    }

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [])
    const handleInputFileChange = useCallback(async () => {
        if (fileInputRef.current && editor) {
            const dt = fileInputRef.current.files
            if (dt && dt.length > 0) {
                for (const file of dt) {
                    if (isFileAnImage(file)) {
                        const reader = new FileReader()
                        reader.onload = () => {
                            const imgSrc = reader.result as string
                            editor.chain().focus().setResizableImage({ src: imgSrc }).run()
                        }
                        reader.readAsDataURL(file)
                    } else if (uploadFile) {
                        uploadFile([file])
                            .then(([resId, bm]) => {
                                const uri = buildBlobUri(resId, bm)
                                //TODO: fix space added at the end as workaround
                                // without space tiptap is not abe to recognize when is end of label for a element
                                const link = `<a href="${uri}">${bm.fileMeta.fileName}</a> `
                                const pos = editor.state.selection.$anchor.pos
                                editor.chain().focus().insertContentAt(pos, link).run()
                            })
                    }
                }
            }
        }
        if (formRef.current) {
            formRef.current.reset()
        }
    }, [])

    if (!editor) {
        return null
    }

    const handleAddNewTableClick = () => {
        editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
        setAnchorEl(null)
    }
    const handleDeleteTableClick = () => {
        editor.chain().focus().deleteTable().run()
        setAnchorEl(null)
    }
    const handleAddColumnAfterClick = () => {
        editor.chain().focus().addColumnAfter().run()
        setAnchorEl(null)
    }
    const handleAddColumnBeforeClick = () => {
        editor.chain().focus().addColumnBefore().run()
        setAnchorEl(null)
    }
    const handleAddRowAfterClick = () => {
        editor.chain().focus().addRowAfter().run()
        setAnchorEl(null)
    }
    const handleAddRowBeforeClick = () => {
        editor.chain().focus().addRowBefore().run()
        setAnchorEl(null)
    }
    const handleDeleteColumnClick = () => {
        editor.chain().focus().deleteColumn().run()
        setAnchorEl(null)
    }
    const handleDeleteRowClick = () => {
        editor.chain().focus().deleteRow().run()
        setAnchorEl(null)
    }

    const editable = editor.isEditable

    return <Box sx={{ mb: 1 }}>
        <form ref={formRef}>
            <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
                multiple={false}
                value=''
                onChange={handleInputFileChange}
                accept="*"
            />
        </form>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editable || !editor.can().chain().focus().toggleBold().run()}
            color={editor.isActive('bold') ? 'secondary' : 'primary'}
        ><FormatBoldIcon /></IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editable || !editor.can().chain().focus().toggleItalic().run()}
            color={editor.isActive('italic') ? 'secondary' : 'primary'}
        ><FormatItalicIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            color={editor.isActive('bulletList') ? 'secondary' : 'primary'}
        ><FormatListBulletedIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            color={editor.isActive('orderedList') ? 'secondary' : 'primary'}
        ><FormatListNumberedIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            color={editor.isActive('blockquote') ? 'secondary' : 'primary'}
        ><FormatQuoteIcon /></IconButton>
        {uploadFile && <IconButton
            size='small'
            disabled={!editable}
            onClick={handleSelectFilesClick}
        ><AttachFileIcon fontSize='small' /></IconButton>}
        <IconButton size='small' onClick={handleTableClick}>
            <TableRowsIcon />
        </IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editable || !editor.can().chain().focus().undo().run()}
        ><UndoIcon /></IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editable || !editor.can().chain().focus().redo().run()}
        ><RedoIcon /></IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleTableClose}>
            <MenuItem key='create' onClick={handleAddNewTableClick}><Localized id='table-add'>Add table</Localized></MenuItem>
            <MenuItem key='delete' onClick={handleDeleteTableClick}><Localized id='table-delete'>Delete table</Localized></MenuItem>
            <Divider />
            <MenuItem key='add-col-before' onClick={handleAddColumnBeforeClick}><Localized id ='table-add-col-before'>Add col before</Localized></MenuItem>
            <MenuItem key='add-col-after' onClick={handleAddColumnAfterClick}><Localized id='table-add-col-after'>Add col after</Localized></MenuItem>
            <MenuItem key='delete-col' onClick={handleDeleteColumnClick}><Localized id='table-delete-col'>Delete col</Localized></MenuItem>
            <Divider />
            <MenuItem key='add-row-before' onClick={handleAddRowBeforeClick}><Localized id='table-add-row-before'>Add row before</Localized></MenuItem>
            <MenuItem key='add-row-after' onClick={handleAddRowAfterClick}><Localized id='table-add-row-after'>Add row after</Localized></MenuItem>
            <MenuItem key='delete-row' onClick={handleDeleteRowClick}><Localized id='table-delete-row'>Delete row</Localized></MenuItem>
        </Menu>
    </Box>
}

export function mkTipTapExtWithMention(persons: MentionUser[]) {
    return [...tiptapExtensions, Mention.configure({
        suggestion: mkSuggestion(persons),
        HTMLAttributes: {
            class: 'mention',
        },
    })]
}

export const tiptapExtensions = [
    StarterKit.configure({
        // bulletList: {
        //     keepMarks: true,
        //     keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
        // orderedList: {
        //     keepMarks: true,
        //     keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
        heading: {
            levels: [1, 2, 3],
        },
    }),
    TaskList,
    TaskItem.configure({
        nested: true,
    }),
    // Image,
    ResizableImage.configure({
        onUpload(file) {
            return new Promise(resolve => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve({
                        src: reader.result as string
                    })
                }
                reader.readAsDataURL(file)
            })
        },
    }),
    Link.configure({
        openOnClick: false,
    }),
    Table,
    TableRow,
    TableHeader,
    TableCell,
]

export const RichTextEditor = (props: RichTextEditorProps) => {
    const { value, onValueChange, label, uploadFile, placeholder } = props

    let exts : any[] = tiptapExtensions
    if (placeholder) {
        exts.push(
            Placeholder.configure({
                placeholder,
            })
        )
    }

    return <Box>
        <FormHelperText>{!label ? 'Nazwa wymagania*' : label}</FormHelperText>
        <BoxWithBorder>
            <EditorProvider onUpdate={e => onValueChange(e.editor.getHTML())} slotBefore={<MenuBar uploadFile={uploadFile} />} extensions={tiptapExtensions} content={value}>
                <></>
            </EditorProvider>
        </BoxWithBorder>
    </Box>
}

export interface RichTextContainerProps {
    label         : React.ReactNode | null
    editor        : Editor | null
    uploadFile?    : (fs : File[]) => Promise<[string,BlobMeta]>
}
export const RichTextContainer = (props: RichTextContainerProps) => {
    const { label, editor, uploadFile } = props

    if (!editor) return null

    return <Box>
        <FormHelperText>{!label ? 'Nazwa wymagania*' : label}</FormHelperText>
        <BoxWithBorder>
            <EditorContext.Provider value={{editor}}>
                <MenuBar uploadFile={uploadFile} />
                <EditorConsumer>
                    {({ editor: currentEditor }) => (
                        <EditorContent editor={currentEditor} />
                    )}
                </EditorConsumer>
            </EditorContext.Provider>
        </BoxWithBorder>
    </Box>
}

export const RichTextEditorExample = () => {
    const editor = useEditor({
        extensions: tiptapExtensions,
        content: '<p>Initial content</p>',   // initial value
        onUpdate: (e) => {                   // callback to get the updated value
            console.log(e.editor.getHTML())
        },
    })

    useEffect(() => {
        if (editor) {
            editor.commands.setContent('<p>Initial content</p>')
        }
    }, [editor])

    return <RichTextContainer label="Example" editor={editor} />
}

export const useJustText = (props: { defaultValue: string, onChange: () => void, editable? : boolean | undefined }) : Editor | null => {
    const editor = useEditor({
        extensions: [Document, Paragraph, Text],
        content: props.defaultValue,
        editable: props.editable,
        onUpdate: e => props.onChange(),
    })
    return editor
}

export const JustText = ({ editor }: { editor: Editor | null }) => {
    if (!editor) {
        return null
    }
    return <EditorContent editor={editor} />
}
