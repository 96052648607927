import { Localized, useLocalization } from "@fluent/react";
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider, Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    styled,
    useTheme
} from "@mui/material";
import { Skeleton } from '@mui/material';
import { generatePath } from "react-router";
import { LoadDeviceTypes, LoadRequirements, LoadRequirementsTypes, LoadSuppliers, LoadUsers } from "../../app/AppDataLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DeviceType, selectDeviceTypeById } from "../device/deviceTypesSlice";
import { updateURS, TeamMember, loadUrs, ActionLink, selectSupplierOffer, selectSupplierOfferWihtNewVersion, publishAction, Urs, changeDeadline, substituteUrsTeamMember, UrsActionType, sendReminder, useUrsState, DescriptionField, isUrsLinkAvail } from "./UrsSlice";
import AddIcon from '@mui/icons-material/Add';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { If } from "../../app/If";
import { AppId, AppTextFieldHandler, AppThunkAPIType } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Box, padding } from "@mui/system";
import { UrsStatusLabel } from "./UrsStatusLabel";
import { selectSettings } from '../settings/settingsSlice';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { TeamMemberDialogForm } from "./TeamMemberDialogForm";
import { selectUserByName } from "../users/usersSlice";
import { UrsOffers } from "./UrsOffers";
import { format, parseJSON } from "date-fns";
import { BottomAppBar } from "../../app/BottomAppBar";
import { selectAllURSComments, selectURSComments, URSComment } from "./URSCommentSlice";
import { Comments } from "./Comments";
import { TeamActionStatusLabel } from "../documents/components/teamMembers/TeamActionStatusLabel";
import { ActionRunner } from "./ActionRunner";
import { RequirementsPanel } from "./RequirementsPanel";
import { AsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { createTemplateFromUrs, loadUrsTemplate, updateUrsTemplate } from "../urstemplates/ursTemplatesSlice";
import PrintIcon from '@mui/icons-material/Print';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AppLink } from "../../app/AppLink";
import { Attachements } from "./Attachements";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ShareIcon from '@mui/icons-material/Share';
import { FullName } from "../users/FullName";
import { EditDescriptionField } from "./dialogs/EditDescriptionField";
import { EditSimpleDescriptionField } from "./dialogs/EditSimpleDescriptionField";
import { DeleteTeamMemberDialog } from "./dialogs/DeleteTeamMemberDialog";
import { EditDocNumberDialog } from "./dialogs/EditDocNumberDialog";
import { PublishDialog } from "./dialogs/PublishDialog";
import { TeamMemberRoleLabel } from "../documents/components/teamMembers/TeamMemberRoleLabel";
import { AddExternalTeamMember } from "./AddExternalTeamMember";
import clsx from "clsx";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { canUserComment, canUserEditComment, canUserResolveComment } from "./ursHelper";
import { selectLoggedUser } from "../user/userSlice";
import { UrsDiff } from "./UrsDiff";
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DocumentVersionsPanel } from "../documents/components/DocumentVersionsPanel";
import { UrsFormHeader } from "./UrsFormHeader";
import { UrsDetailsPanel } from "./UrsDetailsPanel";

const getDeviceTypeName = (dt: DeviceType) => dt.name

type MainTabs = "reqs" | "description" | "team" | "offers" | "attachements" | "changelog" | "versions"

const LoadComponent = () => <Skeleton variant="rectangular" height="50vh" />

type Loader = AsyncThunk<Urs, AppId, AppThunkAPIType>
type Updater = AsyncThunk<Urs, Urs, AppThunkAPIType>

type UrsView = 'form' | 'diff'

export interface EditURSProps {
    template: boolean
    view: UrsView
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));


export const EditURS = (props: EditURSProps) => {
    return <LoadRequirementsTypes component={<Skeleton height='50vh' />}>
        <LoadRequirements component={<Skeleton height='50vh' />}>
                <EditURSInternal {...props} />
        </LoadRequirements>
    </LoadRequirementsTypes>
}

export const EditURSInternal = ({ template, view }: EditURSProps) => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const load: Loader = template ? loadUrsTemplate : loadUrs
    const dispatch = useAppDispatch()
    const [tab, setTab] = useState<MainTabs>("reqs")
    const [data, setData] = useState<Urs | null>(null)

    const comments = useAppSelector(state => {
        if (selectURSComments(state).ursId === id) {
            return selectAllURSComments(state)
        } else {
            return []
        }
    })


    useEffect(() => {
        const fetchData = async () => {
                try {
                    setData(await dispatch(load(id)).unwrap())
                } catch (err) {
                    if (typeof err === 'object' && err !== null && err['name'] && err['name'] === 'ConditionError') {
                        // action cancelled
                    } else {
                        dispatch(showError("error"))

                    }
                    console.log(err)
                }
        }
        setData(null)

        fetchData()
    }, [id])

    if (data === null) return <LoadComponent />

    return <LoadDeviceTypes component={<LoadComponent />}>
        <LoadRequirementsTypes component={<LoadComponent />}>
            <LoadSuppliers component={<LoadComponent />}>
                <LoadRequirements component={<LoadComponent />}>
                    {view === 'form' && <Form entity={data} tab={tab} setTab={setTab} comments={comments} template={template} />}
                    {view === 'diff' && <UrsDiff ursId={id} />}
                </LoadRequirements>
            </LoadSuppliers>
        </LoadRequirementsTypes>
    </LoadDeviceTypes>
}

type DialogState 
    = "none" 
    | "editUrsNumber" 
    | "editRequirement" 
    | "requirementBrowser" 
    | "editTeamMember" 
    | "comments" 
    | "attachements" 
    | "changeTMDeadline" 
    | "substituteTM" 
    | "inviteTM"
    | "sendReminder" 
    | "deleteTM"
    | { tag: 'editDescriptionField', field: DescriptionField, value: string, ursId: AppId }
    | { tag: 'editSimpleDescriptionField', field: DescriptionField, value: string, ursId: AppId }
    | { tag: "confirmTeamMemberDelete", teamMemberId: AppId, user: string }


interface FormProps {
    entity: Urs,
    tab: MainTabs,
    setTab: Dispatch<SetStateAction<MainTabs>>,
    comments: URSComment[],
    template: boolean
}

export const Form = (props: FormProps) => {
    const { entity: initialEntity, comments, template } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const update: Updater = template ? updateUrsTemplate : updateURS
    const deviceType = useAppSelector(state => selectDeviceTypeById(state, initialEntity.deviceTypeId))
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const user = useAppSelector(selectLoggedUser)

    const entity = useUrsState(initialEntity)

    const canUpload = entity.links.save !== undefined
    const [dialog, setDialog] = useState<DialogState>("none")
    const handleEditDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area', value: string) => {
        setDialog({ tag: 'editDescriptionField', field, value, ursId: entity.id })
    }, [])
    const handleEditSimpleDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area' | 'title', value: string) => {
        setDialog({ tag: 'editSimpleDescriptionField', field, value, ursId: entity.id })
    }, [])

    const [docDesignation, setDocDesignation] = useState(entity.docDesignation)
    const [newTemplateDocDesignation, setNewTemplateDocDesignation] = useState("")
    const [newTemplateNumber, setNewTemplateNumber] = useState("")

    const [saving, setSaving] = useState(false)
    const [teamMemberToEdit, setTeamMemberToEdit] = useState<TeamMember | undefined>(undefined)

    const [actionToRun, setActionToRun] = useState<ActionLink & { name: string, description: string } | null>(null)
    const [selectedOfferId, setSelectedOfferId] = useState<AppId>("")

    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [newDeadline, setNewDeadline] = useState(today)
    const [deadlineForAll, setDeadlineForAll] = useState<boolean>(false)

    const [commentsOpen, setCommentsOpen] = useState(false)

    const handleNewTemplateNumberChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateNumber(e.target.value)
    }, [])

    const handleNewTemplateDocDesignationChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateDocDesignation(e.target.value)
    }, [])

    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setNewDeadline(e.target.value)
    }, [])

    const handleChangeTMDeadlineClick = useCallback((entity: TeamMember) => {
        setTeamMemberToEdit(entity)
        setDeadlineForAll(false)
        setDialog("changeTMDeadline")
    }, [])

    const handleChangeAllTMDeadlineClick = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDeadlineForAll(true)
        setDialog("changeTMDeadline")
    }, [])

    const handleSendReminderClick = useCallback(() => {
        setDialog("sendReminder")
    }, [])

    const handleSubstituteTMClick = useCallback((entity: TeamMember) => {
        setTeamMemberToEdit(entity)
        setDialog("substituteTM")
    }, [teamMemberToEdit])

    const handleConfirmSubstituteTMClick = useCallback(async (userName: string | undefined) => {
        try {
            setSaving(true)
            await dispatch(substituteUrsTeamMember({
                ursId: entity.id,
                userName: teamMemberToEdit?.userName ?? "",
                substitution: userName ?? ""
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setTeamMemberToEdit(undefined)
        setDialog("none")
        setSaving(false)
    }, [teamMemberToEdit])


    const handleDeleteTMClick = useCallback((entity: TeamMember, user: string) => {
        setDialog({ tag: "confirmTeamMemberDelete", teamMemberId: entity.id, user })
    }, [])

    const handleDeleteTeamMemberClick = useCallback((entity: TeamMember, user: string) => {
        setDialog({ tag: "confirmTeamMemberDelete", teamMemberId: entity.id, user })
    }, [])

    const handleEditUrsNumber = useCallback(() => {
        setDialog("editUrsNumber")
    }, [])


    const handleInviteUser = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDialog("inviteTM")
    }, [])

    const handleAddTeamMemberClick = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDialog("editTeamMember")
    }, [])

    const handleDocDesignationChange: AppTextFieldHandler = useCallback(e => setDocDesignation(e.target.value), [])

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])


    const handleOfferSelected = useCallback((offerId: AppId) => {
        setSelectedOfferId(offerId);
    }, [])

    const handleSelectOfferWithNewVersionClick = async () => {
        setSaving(true)
        try {
            const result = await dispatch(selectSupplierOfferWihtNewVersion(selectedOfferId))
            if (selectSupplierOfferWihtNewVersion.fulfilled.match(result)) {
                const newEntity = unwrapResult(result)
                navigate(`/urs/edit/${newEntity.id}?structureId=${newEntity.deviceTypeId}`, { replace: true })
                dispatch(showSuccess("saved"))
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }

            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
    }

    const handleSelectOfferWithoutVersionClick = async () => {
        setSaving(true)
        try {
            const result = await dispatch(selectSupplierOffer(selectedOfferId))
            if (selectSupplierOffer.fulfilled.match(result)) {
                dispatch(showSuccess("saved"))
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
    }

    const handleSaveButtonClick = async () => {
        setSaving(true)
        const result = await dispatch(update(entity))

        if (update.fulfilled.match(result)) {
            dispatch(showSuccess("saved"))
        } else {
            dispatch(showError("error"))
        }

        setSaving(false)
    }

    const [showSaveAsUrsTemplateDialog, setShowSaveAsUrsTemplateDialog] = useState(false);

    const [showRiskAnalysisConfMessage, setShowRiskAnalysisConfMessage] = useState(false);
    const [showPublishMessage, setShowPublishMessage] = useState(false);

    const handlePublishClick = async () => {
        setShowPublishMessage(true)
    }

    const handleCancelPublishClick = useCallback(() => {
        setShowPublishMessage(false)
    }, [setShowPublishMessage])

    const handleConfirmPublishClick = async (ids: Set<AppId>, suppliersIds: Set<AppId>) => {
        const { publish } = entity.links
        if (publish) {
            setSaving(true)
            try {
                const result = await dispatch(publishAction({
                    url: publish.href,
                    companiesIds: Array.from(ids),
                    suppliersIds: Array.from(suppliersIds)
                }))

                if (publishAction.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))

                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            setShowPublishMessage(false)
            setSaving(false)
        }
    }

    const handleAsUrsTemplateClick = useCallback(() => {
        setShowSaveAsUrsTemplateDialog(true)
    }, [setShowSaveAsUrsTemplateDialog])

    const handleConfirmChangeTMDeadlineClick = async () => {
        try {
            setSaving(true)
            await dispatch(changeDeadline({
                ursId: entity.id,
                teamMemberId: teamMemberToEdit?.id ?? "",
                deadline: newDeadline,
                forAllMembers: deadlineForAll
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const [showUrsCorrectMessage, setShowUrsCorrectMessage] = useState(false);

    const handleCancelCorrectUrsClick = useCallback(() => {
        setShowUrsCorrectMessage(false)
    }, [])

    const handleConfirmCorrectUrsClick = async () => {
        try {
            await handleSaveButtonClick()
        } catch (error) {

        }
        setShowUrsCorrectMessage(false)
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as MainTabs || 'reqs'
    const setTab = (tab: MainTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [setTab])

    const handleStartRiskAnalysisClick = async () => {
        setShowRiskAnalysisConfMessage(true)
    }

    const handleCancelRiskAnlasisClick = useCallback(() => {
        setShowRiskAnalysisConfMessage(false)
    }, [])

    const handleCancelSaveAsTemplate = useCallback(() => {
        setShowSaveAsUrsTemplateDialog(false)
    }, [])


    const handleConfirmSaveAsTemplate = async () => {
        if (newTemplateDocDesignation) {
            setSaving(true)
            try {
                const result = await dispatch(createTemplateFromUrs({
                    ursId: entity.id,
                    templateNo: newTemplateNumber,
                    description: newTemplateDocDesignation
                }))

                if (createTemplateFromUrs.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    setShowSaveAsUrsTemplateDialog(false)
                    setSaving(false)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    const handleConfirmRiskAnlasisClick = async () => {
        try {
            setShowRiskAnalysisConfMessage(false)
            navigate(`/riskAnalysis/edit/${entity.id}`)
        }
        catch (error) {

        }
        setShowRiskAnalysisConfMessage(false)
    }

    const handlePrintUrsClick = async () => {
        const { report } = entity.links
        if (report) {
            const { href } = report
            window.open(`${process.env.REACT_APP_REST_API}${href.startsWith("/") ? href.substring(1) : href}?language=${locale}`, '_blank')
        }
    }


    const handleCancelTeamMemberDeleteClick = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDialog("none")
    }, [])


    const handleConfirmSendReminderClick = async () => {
        try {
            setSaving(true)
            await dispatch(sendReminder(entity.id))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const handleCancelSendReminderClick = useCallback(() => {
        setDialog("none")
    }, [])

    const allTeamMembers = entity.teamMembers
    const riskAnalysisVisible = false
    const teamMembersSelection = useMemo(() => new Set(allTeamMembers.map(u => u.userName)), [allTeamMembers])

    const isAutor = isUrsLinkAvail("save", entity)
    const finishOpinionDescription = isAutor ? l10n.getString("urs-finish-opinion-author-description") : l10n.getString("urs-finish-opinion-description")
    const finishEditionDescription = l10n.getString("urs-finish-edition-description")
    const deleteConfirmationMessage = l10n.getString("urs-confirm-delete")
    const newVersionURSQuestion = l10n.getString("urs-new-version-question")
    
    const { save, del, report, publish, sendToOpinion, sendToApprove, finishOpinion, finishEdition, approve, reject, newversion, changedeadline, changeursnumber } = entity.links
    const editable = save !== undefined

    const userCanSendComment = useCallback(() => {
        return canUserComment(entity)
    }, [entity])
    const userCanApproveComment = useCallback(() => {
        return canUserResolveComment(entity)
    }, [entity])
    const userCanEditComment = useCallback((comment: URSComment) => {
        return canUserEditComment(user, entity, comment)
    }, [entity, user])

    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset w milisekundach
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime.split('T')[0];
    }

    const actions = [
        <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
            <Localized id="back">Wróć</Localized>
        </Button>,
        riskAnalysisVisible && <Button key="analysis" color="primary" onClick={() => handleStartRiskAnalysisClick()} variant="outlined">
            <Localized id="urs-action-risk-analysis">
                <span>Rozpocznij analize</span>
            </Localized>
        </Button>,
        publish && <Button key="publish" color="primary" onClick={() => handlePublishClick()} variant="outlined">
            <Localized id="urs-action-publish">
                <span>Publikuj</span>
            </Localized>
        </Button>,
        sendToApprove && <Button key="sendToApprove" color="info" onClick={() => setActionToRun({ ...sendToApprove, name: 'sendToApprove', description: '' })}>
            <Localized id="urs-action-send-to-approve">
                <span>Wyślij do zatwierdzenia</span>
            </Localized>
        </Button>,
        sendToOpinion && <Button key="sendToOpinion" color="info" onClick={() => setActionToRun({ ...sendToOpinion, name: 'sendToOpinion', description: '' })}>
            <Localized id="urs-action-send-to-opinion">
                <span>Wyślij do opiniowania</span>
            </Localized>
        </Button>,
        finishOpinion && <Button key="finishOpinion" color="info" onClick={() => setActionToRun({ ...finishOpinion, name: "finishOpinion", description: finishOpinionDescription })}>
            <Localized id="urs-action-finish-opinion">
                <span>Zakończ opiniowanie</span>
            </Localized>
        </Button>,
        finishEdition && <Button key="finishEdition" color="info" onClick={() => setActionToRun({ ...finishEdition, name: "finishEdition", description: finishEditionDescription })}>
            <Localized id="urs-action-finish-edtition">
                <span>Zakończ edytowanie</span>
            </Localized>
        </Button>,
        approve && <Button key="approve" color="info" onClick={() => setActionToRun({ ...approve, name: "approve", description: '' })}>
            <Localized id="urs-action-approve">
                <span>Zatwierdź</span>
            </Localized>
        </Button>,
        reject && <Button key="reject" color="error" onClick={() => setActionToRun({ ...reject, name: "reject", description: '' })}>
            <Localized id="urs-action-reject">
                <span>Odrzuć</span>
            </Localized>
        </Button>,
        newversion && <Button key="newversion" color="primary" variant="outlined" onClick={() => setActionToRun({ ...newversion, name: "newversion", description: newVersionURSQuestion })}>
            <Localized id="urs-action-new-version">
                <span>Nowa wersja</span>
            </Localized>
        </Button>,
    ]

    return <>
        <Box sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <If condition={!entity.isTemplate}>
                <UrsFormHeader doc={entity}></UrsFormHeader>
            </If>
            <Grid container spacing={4}>
                <If condition={entity.isTemplate}>
                    <Grid item xs={3}>
                        <Card>
                            <CardHeader
                                title={entity.ursNo}
                                subheader={<Localized id="urs-template-no"><span>Symbol dokumentu</span></Localized>} />
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader
                                title={deviceType ? getDeviceTypeName(deviceType) : ""}
                                subheader={<Localized id="urs-device-type"><span>Typ urządzenia</span></Localized>}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={1}>
                        <Card>
                            <CardHeader
                                title={entity.version + 1}
                                subheader={<Localized id="urs-version"><span>Wersja</span></Localized>}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Card>
                            <CardHeader
                                title={<UrsStatusLabel status={entity.status}></UrsStatusLabel>}
                                subheader={<Localized id="urs-status"><span>Status</span></Localized>}
                            />
                        </Card>
                    </Grid>
                </If>

                <Grid item xs={12}>
                  
                    <TabContext value={tab}>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TabList onChange={handleMainTabChange} sx={{
                                    marginTop: 1,
                                }}>
                                    <Tab
                                        key={0}
                                        value="reqs"
                                        label={<Localized id="urs-requirements">Lista wymagań</Localized>} />
                                    <Tab
                                        key={1}
                                        value="description"
                                        label={<Localized id="document-details">Szczegóły dokumentu</Localized>} />
                                    <Tab
                                        key={2}
                                        value="team"
                                        label={<Localized id="urs-team">Zespół</Localized>}
                                        disabled={template}
                                    />
                                    <Tab
                                        key={3}
                                        value="offers"
                                        label={<Localized id="urs-published-urs">Opublikowane URS'Y</Localized>}
                                        disabled={template}
                                    />
                                    <Tab
                                        key={4}
                                        value="attachements"
                                        label={<Localized id="attachements">Załączniki</Localized>}
                                        disabled={template}
                                    />
                                    <Tab
                                        key={5}
                                        value="versions"
                                        label={<Localized id="versions">_Versions</Localized>}
                                        disabled={template}
                                    />
                                    {/*<Tab
                                key={5}
                                value="changelog"
                                label={<Localized id="urs-changelog">Dziennik zmian</Localized>}
                                disabled={template}
                                />*/}
                                </TabList>
                            </Grid>
                            <Grid item xs={4}>
                                <div>

                                    <If condition={!entity.isTemplate} otherwise={
                                        <TextField
                                            fullWidth
                                            multiline={true}
                                            minRows={4}
                                            onChange={handleDocDesignationChange}
                                            value={docDesignation}
                                            label={<Localized id="urs-description"><span>Opis</span></Localized>}>
                                        </TextField>
                                    }>
                                        <Box display="flex" justifyContent="flex-end"  flexDirection="column" gap={2} marginTop={2}>
                                            <Stack justifyContent="flex-end" direction="row" spacing={4}>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <StyledToggleButtonGroup
                                                        value={'left'}
                                                        exclusive
                                                        aria-label="text alignment"
                                                    >
                                                        <Tooltip title={<Localized id="urs-change-deadline">deadline</Localized>} sx={{ display: changedeadline !== undefined ? 'flex' : 'none' }}>
                                                            <ToggleButton value="deadline" aria-label="deadline" onClick={() => handleChangeAllTMDeadlineClick()}>
                                                                <MoreTimeIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-send-reminder">send-reminder</Localized>} sx={{ display: changedeadline !== undefined ? 'flex' : 'none' }}>
                                                            <ToggleButton value="send-reminder" aria-label="send-reminder" onClick={() => handleSendReminderClick()}>
                                                                <ScheduleSendIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-action-history">Historia</Localized>}>
                                                            <ToggleButton value="history" aria-label="history"  onClick={() => navigate(`/urs/diff/${entity.id}`)}>
                                                                <HistoryIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-action-report">Raport</Localized>}>
                                                            <ToggleButton value="report" aria-label="report" disabled={report === undefined} onClick={() => handlePrintUrsClick()}>
                                                                <PrintIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip sx={{ display: del !== undefined ? 'flex' : 'none' }} title={<Localized id="delete">Delete</Localized>}>
                                                            <ToggleButton value="delete" aria-label="delete" onClick={() => del && setActionToRun({ ...del, name: "del", description: deleteConfirmationMessage })}>
                                                                <DeleteIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                    </StyledToggleButtonGroup>
                                                    <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                                                    <StyledToggleButtonGroup>
                                                        <Tooltip title={<Localized id="urs-create-copy"> Utwórz kopię URS</Localized>}>
                                                            <ToggleButton
                                                                component={AppLink}
                                                                to={{
                                                                    pathname: `/urs/create/`,
                                                                    state: { defaultUrsId: entity.id }
                                                                }}
                                                                value="copy-urs" aria-label="copy-urs">
                                                                <ContentCopyIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-save-as-template">Zapisz jako szablon</Localized>}>
                                                            <ToggleButton value="template" aria-label="template" onClick={() => handleAsUrsTemplateClick()}>
                                                                <DocumentScannerIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                    </StyledToggleButtonGroup>
                                                </Paper>
                                            </Stack>

                                        </Box>
                                    </If>
                                </div>
                            </Grid>
                        </Grid>
                        <TabPanel value="reqs">
                            
                            <div className="flex flex-row gap-4">
                                <div className={clsx(commentsOpen ? 'basis-3/4' : 'basis-full')}>
                                    <RequirementsPanel template={template} urs={entity} />
                                </div>
                                <div className={clsx(commentsOpen ? 'basis-1/4' : 'basis-8', 'mt-9')}>
                                    <Paper className="p-2">
                                        <IconButton className={clsx({ 'hidden': !commentsOpen })} onClick={() => setCommentsOpen(false)}><ChevronRightIcon /></IconButton>
                                        <IconButton className={clsx({ 'hidden': commentsOpen })} onClick={() => setCommentsOpen(true)}><ChevronLeftIcon /></IconButton>
                                        <Divider className={clsx(commentsOpen ? '' : 'hidden', 'mb-2')} />
                                        <If condition={!template && commentsOpen}>
                                            {comments && <Comments 
                                                            comments={comments} 
                                                            ursId={entity.id}
                                                            requirement={null}
                                                            isCommentEnabled={userCanSendComment}
                                                            isResolvingEnabled={userCanApproveComment}
                                                            isEditEnabled={userCanEditComment}
                                                        />}
                                        </If>
                                    </Paper>
                                </div>
                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={9}>

                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="description" sx={{padding: 1}}>
                            <UrsDetailsPanel
                                entity={entity} 
                                editable={editable} 
                                isUrsLinkAvail={isUrsLinkAvail} 
                                handleEditUrsNumber={handleEditUrsNumber} 
                                handleEditSimpleDescriptionFieldClick={handleEditSimpleDescriptionFieldClick } 
                                handleEditDescriptionFieldClick={handleEditDescriptionFieldClick}/>
                        </TabPanel>

                        <TabPanel value="team">
                            <LoadUsers component={<Skeleton variant="rectangular" height="200px" />}>
                                <TableContainer component={Paper} sx={{
                                    maxHeight: 'calc(100vh - 400px)',
                                }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell sx={{
                                                    minWidth: 200,
                                                }}>
                                                    <Localized id="user-first-name">
                                                        <span>Imię</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{
                                                    minWidth: 200,
                                                }}>
                                                    <Localized id="user-last-name">
                                                        <span>Nazwisko</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell>
                                                    <Localized id="user-position">
                                                        <span>Stanowisko</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell>
                                                    <Localized id="user-company">Firma</Localized>
                                                </TableCell>
                                                <TableCell>
                                                    <Localized id="user-role">
                                                        <span>Rola</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: "center", width: 200 }}>
                                                    <Localized id="opinion-status">
                                                        <span>Status</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{ width: 160, textAlign: "center" }}>
                                                    <Localized id="edition-date">
                                                        <span>Data edycji</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{ width: 160, textAlign: "center" }}>
                                                    <Localized id="opinion-date">
                                                        <span>Data zaopiniowania</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{ width: 160, textAlign: "center" }}>
                                                    <Localized id="approvement-date">
                                                        <span>Data zatwierdzenia</span>
                                                    </Localized>
                                                </TableCell>
                                                <TableCell sx={{
                                                    width: 100,
                                                }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{allTeamMembers/*.sort((a, b) => {
                                            if (!a.substitutionName && b.substitutionName) {
                                                return -1; // a ma puste sustitution, więc jest mniejszy
                                            } else if (a.substitutionName && !b.substitutionName) {
                                                return 1; // b ma puste sustitution, więc jest mniejszy
                                            } else {
                                                return 0; // oba elementy mają albo puste, albo wypełnione sustitution
                                            }
                                        })*/

                                            .map(tm => <TeamMemberRow
                                                editable={isUrsLinkAvail("save", entity)}
                                                actions={entity.links}
                                                author={entity.createdBy}
                                                entity={tm}
                                                key={tm.userName}
                                                onChangeDeadlineClick={handleChangeTMDeadlineClick}
                                                onSubstituteTMClick={handleSubstituteTMClick}
                                                onDeleteClick={handleDeleteTeamMemberClick}
                                                onDeleteMemberClick={handleDeleteTMClick}
                                            />)}</TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{
                                    padding: 1,
                                }}>
                                    <If condition={save !== undefined}>
                                        <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleAddTeamMemberClick}>
                                            <Localized id="add">
                                                <span>Dodaj</span>
                                            </Localized>
                                        </Button>
                                        <Button sx={{marginLeft: "5px", marginTop:"1px"}}variant="outlined"  startIcon={<ShareIcon />} onClick={handleInviteUser} >
                                            <Localized id="invite">
                                                <span>Zaproś</span>
                                            </Localized>
                                        </Button>

                                    </If>
                                </Box>
                            </LoadUsers>
                        </TabPanel>
                        <TabPanel value="offers">
                            <UrsOffers
                                ursId={entity.id}
                                offerSelected={handleOfferSelected}
                                handleNewVersion={handleSelectOfferWithNewVersionClick}
                                handleWithoutNewVersion={handleSelectOfferWithoutVersionClick} />
                        </TabPanel>
                        <TabPanel value="attachements">
                            <Attachements resourceId={entity.guid} canDelete={canUpload} canUpload={canUpload} />
                        </TabPanel>
                        <TabPanel value="versions">
                            <DocumentVersionsPanel documentId={entity.id}   />
                        </TabPanel>
                        {/*<TabPanel value="changelog">
                            <ChangeLog />
                            </TabPanel>*/}
                    </TabContext>
                   
                </Grid>
            </Grid>
            {dialog === 'editUrsNumber' && <EditDocNumberDialog ursId={entity.id} value={entity.ursNo} onSuccess={() => setDialog('none')} onCancel={() => setDialog('none')} />}
            <Dialog key="aditRequirementDialog" open={dialog === "editRequirement"} fullWidth maxWidth="md">
            </Dialog>
            <Dialog key="sendReminderDialog" open={dialog === "sendReminder"} fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-confirm-send-reminder">
                            <span>Czy wysłać przypomnienie o bieżących zadaniach do członków zespołu?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSendReminderClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleCancelSendReminderClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {typeof dialog === 'object' && dialog.tag === "confirmTeamMemberDelete" && <DeleteTeamMemberDialog 
                 id={dialog.teamMemberId} 
                 userFullName={dialog.user}
                onCancel={handleCancelTeamMemberDeleteClick}
                onSuccess={() => setDialog("none")}
             />}
            <Dialog key="riskAnalysisDialog" open={showRiskAnalysisConfMessage}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-open-risk-analysis-question">
                            <span>Czy przejść do analizy ryzyka?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmRiskAnlasisClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelRiskAnlasisClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog key="saveAsTemplateDialog" open={showSaveAsUrsTemplateDialog} fullWidth maxWidth="md" keepMounted={false}>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        <Localized id="urs-save-as-template-question">
                            <span>Zapisz jako szablon</span>
                        </Localized>
                    </DialogTitle>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateNumber}
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    onChange={handleNewTemplateNumberChange}
                                    label={
                                        <Localized id="urs-template-no">
                                            <span>Numer szablonu</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateDocDesignation}
                                    fullWidth
                                    inputProps={{ maxLength: 2000 }}
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    onChange={handleNewTemplateDocDesignationChange}
                                    label={
                                        <Localized id="urs-template-description">
                                            <span>Opis</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSaveAsTemplate}>
                        <Localized id="save"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelSaveAsTemplate}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog key="changeTMDeadline" open={dialog === "changeTMDeadline"} maxWidth="xs" keepMounted={false}>
                <DialogContent>
                    <DialogContentText>
                        <If condition={deadlineForAll} otherwise={
                            <Typography>
                                <Localized id="urs-new-deadline-single-user">
                                    <span>Wprowadź nowy termin wykonania zadania dla wybranego użytkownika</span>
                                </Localized>
                            </Typography>
                        }>
                            <Localized id="urs-new-deadline-team">
                                <span>Wprowadź nowy termin wykonania zadania dla całego zespołu</span>
                            </Localized>
                        </If>

                    </DialogContentText>
                    <TextField
                        sx={{
                            marginTop: 2
                        }}
                        type="date"
                        fullWidth
                        value={newDeadline}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            min: getLocalISODate(new Date())
                        }}
                        onChange={handleDeadlineFieldChange}
                        label={<Localized id="deadline"><span>Termin</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmChangeTMDeadlineClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog key="ursCorrectDialog" open={showUrsCorrectMessage}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-open-correction-question">
                            <span>Czy otworzyć do korekty URS?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmCorrectUrsClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelCorrectUrsClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {showPublishMessage && <PublishDialog ursId={entity.id} processing={saving} confirmPublish={handleConfirmPublishClick} cancelPublish={handleCancelPublishClick} />}
            <Dialog key="substituteTMDialog" open={dialog === "substituteTM"} maxWidth="md" fullWidth keepMounted={false}> 
                <TeamMemberDialogForm ursId={entity.id} mode={"substitute"} excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={(username) => handleConfirmSubstituteTMClick(username)}
                />
            </Dialog>
            <Dialog key="inviteTMDialog" open={dialog === "inviteTM"} maxWidth="md" fullWidth keepMounted={false}> 
                <AddExternalTeamMember ursId={entity.id} onCancelClick={handleDialogCancelClick} onOKClick={() => { setDialog('none')}} />
            </Dialog>
            <Dialog key="editTeamMemberDialog" open={dialog === "editTeamMember"} maxWidth="md" fullWidth keepMounted={false}>
                <TeamMemberDialogForm
                    ursId={entity.id}
                    mode={teamMemberToEdit ? "edit" : "create"}
                    excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={() => { setDialog("none") }}
                />
            </Dialog>
            {typeof dialog === 'object' && dialog.tag === 'editDescriptionField' && <EditDescriptionField 
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            {typeof dialog === 'object' && dialog.tag === 'editSimpleDescriptionField' && <EditSimpleDescriptionField 
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            <>{actionToRun && <ActionRunner
                action={actionToRun}
                onError={() => { }}
                onSuccess={updatedURS => {
                    if (updatedURS.id !== entity.id) {
                        // new version - redirection needed
                        setActionToRun(null)
                        navigate(generatePath("/urs/edit/:id", {
                            id: updatedURS.id,
                        }), { replace: true })
                    } else {
                        setActionToRun(null)
                    }
                }}
                onCancel={() => setActionToRun(null)} />}</>
        </Box>
        <BottomAppBar>
            <ButtonGroup variant="contained" color="secondary">{actions}</ButtonGroup>
        </BottomAppBar>
    </>
}

interface TeamMemberRowProps {
    entity: TeamMember
    author: string
    actions: Partial<Record<UrsActionType, ActionLink>>
    onChangeDeadlineClick: (entity: TeamMember) => void
    onSubstituteTMClick: (entity: TeamMember) => void
    onDeleteClick: (entity: TeamMember, user: string) => void
    onDeleteMemberClick: (entity: TeamMember, user: string) => void
    editable: boolean
}
const TeamMemberRow = ({ entity, author, onChangeDeadlineClick, onSubstituteTMClick, onDeleteClick, editable, actions }: TeamMemberRowProps) => {
    const { userName, approvementDate, finishEditionAction, finishEditionDate ,opinionDate, opinionAction,  approveAction, opinionClosed } = entity
    const { firstName, surname, position, companyName } = entity
    const { changedeadline, substituteMember, deleteMember } = actions

    const user = useAppSelector(state => selectUserByName(state, userName))
    const approvementFormatedDate = approvementDate ? format(parseJSON(approvementDate), "yyyy-MM-dd HH:mm") : ""
    const opinionDateFormatedDate = opinionDate ? format(parseJSON(opinionDate), "yyyy-MM-dd HH:mm") : ""
    const finishEditionFormatedDate = finishEditionDate ? format(parseJSON(finishEditionDate), "yyyy-MM-dd HH:mm") : ""
    const approveDeadline = approveAction?.deadline ? format(parseJSON(approveAction?.deadline), "yyyy-MM-dd") : ""
    const opinionDeadline = opinionAction?.deadline ? format(parseJSON(opinionAction?.deadline), "yyyy-MM-dd") : ""
    const today = new Date();
    const hasSubstitution = entity.substitutionName !== null && entity.substitutionName !== ""
    const hasActiveAction = approveAction !== null || opinionAction !== null || finishEditionAction !== null

    const changedeadlineActive = changedeadline !== undefined && hasActiveAction && !hasSubstitution
    const substitutionActive = substituteMember !== undefined && hasActiveAction && !hasSubstitution
    const deleteActive = deleteMember === undefined && substituteMember === undefined && editable
    const deleteMemberActive = deleteMember !== undefined

    let textStyle = {};
    const theme = useTheme();
    let substitutionRowColor = {};

    if (hasSubstitution) {
        substitutionRowColor = { backgroundColor: theme.palette.grey[200] }
    }

    if (opinionAction?.deadline) {
        const deadlineDate = new Date(opinionAction.deadline);
        today.setDate(today.getDate() + 2);
        if (today > deadlineDate) {
            textStyle = { color: 'red' };
        }
    }

    if (approveAction?.deadline) {
        const deadlineDate = new Date(approveAction.deadline);
        today.setDate(today.getDate() + 2);
        if (today > deadlineDate) {
            textStyle = { color: 'red' };
        }
    }

    const handleDeleteClick = useCallback(() => {
            onDeleteClick(entity, `${firstName} ${surname}`)
    }, [entity, onDeleteClick])

    return <TableRow style={substitutionRowColor}>
        <TableCell>{firstName}</TableCell>
        <TableCell>{surname}</TableCell>
        <TableCell>{position}</TableCell>
        <TableCell>{companyName}</TableCell>
        <TableCell><TeamMemberRoleLabel role={entity.role}></TeamMemberRoleLabel></TableCell>
        <TableCell>
            <If condition={hasSubstitution}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <Localized id="urs-team-substituted-by">
                        <span>Zastąpiony przez </span>
                    </Localized> <FullName userName={entity.substitutionName}></FullName>
                </Box>
            </If>
            <If condition={opinionAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={opinionAction?.status}></TeamActionStatusLabel>
                    </div>
                    <div style={textStyle}>
                        <Localized id="urs-action-deadline">
                            <span>Termin</span>
                        </Localized> {opinionDeadline}
                    </div>
                </Box>
            </If>
            <If condition={approveAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={approveAction?.status}></TeamActionStatusLabel>
                    </div>
                    <div style={textStyle}>
                        <Localized id="urs-action-deadline">
                            <span>Termin</span>
                        </Localized> {approveDeadline}
                    </div>
                </Box>
            </If>
            <If condition={opinionClosed != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <Localized id="urs-action-no-opinion">
                            <span>brak opiniowania</span>
                        </Localized>
                    </div>
                </Box>
            </If>
            <If condition={finishEditionAction != null}>
                <Box display="flex" style={{ textAlign: "center" }} flexDirection="column">
                    <div>
                        <TeamActionStatusLabel status={finishEditionAction?.status}></TeamActionStatusLabel>
                    </div>
                </Box>
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={finishEditionFormatedDate !== ""} otherwise={<span>-</span>}>
                {finishEditionFormatedDate}
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={opinionDateFormatedDate !== ""} otherwise={<span>-</span>}>
                {opinionDateFormatedDate}
            </If>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <If condition={approvementFormatedDate !== ""} otherwise={<span>-</span>}>
                {approvementFormatedDate}
            </If>
        </TableCell>
        <TableCell>
            <ButtonGroup>
                <If condition={changedeadlineActive}>
                    <Tooltip title={<Localized id="urs-change-deadline">Zmień deadline</Localized>}>
                        <IconButton size="small" onClick={() => onChangeDeadlineClick(entity)}>
                            <MoreTimeIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={substitutionActive}>
                    <Tooltip title={<Localized id="urs-appoint-substitution">Wyznacz zastępstwo</Localized>}>
                        <IconButton size="small" disabled={author == entity.userName} onClick={() => onSubstituteTMClick(entity)}>
                            <SwapVertIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={deleteActive}>
                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                        <IconButton size="small" disabled={author == entity.userName} onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={deleteMemberActive}>
                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                        <IconButton size="small" disabled={author == entity.userName} onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </If>
            </ButtonGroup>
        </TableCell>
    </TableRow>
}
