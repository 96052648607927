import { Box, Button, ButtonGroup, Checkbox, Chip, Fab, Paper, TableContainer } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useCallback, useEffect, useState } from "react"
import { unwrapResult } from "@reduxjs/toolkit"
import { ErrorContainer } from "../../app/ErrorContainer"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../settings/settingsSlice"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { FlexDiv } from "../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { AppId } from "../../app/appTypes"
import { loadRelatedTests, TestSpecification, TestSubType } from "../testSpecification/testSpecificationApi"
import { DeleteConfirmationDialog } from "../utils/DeleteConfirmationDialog"
import { CreateTestSpecificationCreator } from "./CreateTestSpecificationCreator"
import { EditTestSpecificationDialog } from "./EditTestSpecificationDialog"

export interface RelatedTestSpecificationsOverviewProps {
    relatedObjectType: string
    relatedObjectId: string,
    defStructureId: AppId | null
}

export const RelatedTestSpecificationsOverview = ({ relatedObjectType, relatedObjectId, defStructureId }: RelatedTestSpecificationsOverviewProps) => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [testsOverview, setTestsOverview] = useState<TestSpecification[]>([]);
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const [dialogAddTestVisible, setDialogAddTestVisible] = useState<boolean>(false)
    const [dialogEditTestVisible, setDialogEditTestVisible] = useState<boolean>(false)
    const [selectedTest, setSelectedTest] = useState<TestSpecification>()
    const [datachanged, setDatachanged] = useState<boolean>(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadRelatedTests({ relatedObjectType, relatedObjectId }))
            .then(unwrapResult)
            .then(setTestsOverview)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [relatedObjectId, datachanged])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };


    type ChipProperties = {
        label: string;
        color: "default" | "success" | "warning" | "primary" | "error";
    };

    const getChipProperties = (subType: TestSubType): ChipProperties => {
        switch (subType) {
            case "SAT":
                return { label: "SAT", color: "success" };
            case "FAT":
                return { label: "FAT", color: "warning" };
            case "FATSAT":
                return { label: "FAT & SAT", color: "error" };
            default:
                return { label: "NONE", color: "default" };
        }
    };

    const renderCheckboxWithChip = (isChecked: boolean, subType: TestSubType) => {
        const { label, color } = getChipProperties(subType);

        return (
            <Box display="flex" alignItems="start" paddingTop={0.5} paddingLeft={0}>
                <Checkbox checked={isChecked} color="primary" disabled sx={{paddingLeft:0}}/>
                {isChecked && subType && subType != "NONE" &&
                    <Chip label={label} color={color} variant="outlined" />
                }
            </Box>
        );
    };

    const handleAddTest = useCallback(() => {
        setDialogAddTestVisible(true)
    }, [])

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelete = () => setItemToDelete(undefined);

    const handleConfirmDelete = async () => {
        console.log("Usunięto element" + itemToDelete);
        setItemToDelete(undefined);
    };

    const handleEditTest = useCallback((row: TestSpecification) => {
        setSelectedTest(row)
        setDialogEditTestVisible(true)
    }, [])

    const handleOnSuccess = useCallback(() => {
        setDialogAddTestVisible(false)
        setDialogEditTestVisible(false)
        setDatachanged(x => !x)
    }, [])

    const handleOnClose = useCallback(() => {
        setDialogAddTestVisible(false)
        setDialogEditTestVisible(false)
        setDatachanged(x => !x)
    }, [])

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    const columns: GridColDef<TestSpecification, any, any>[] = [
        {
            field: 'name',
            headerName: l10n.getString("", null, "Test"),
            minWidth: 200
        },
        {
            field: 'description',
            headerName: l10n.getString("", null, "Description"),
            flex:1
        },
        {
            field: "isDQ",
            headerName: "DQ",
            width: 150,
            renderCell: (params) => {
                const dqPhase = params.row.testPhases.find(
                    (phase) => phase.phaseType === "DQ"
                );

                if (dqPhase) {
                    return renderCheckboxWithChip(dqPhase.isActive, dqPhase.subType);
                } else {
                    return <span>-</span>;
                }
            },
        },
        {
            field: "isIQ",
            headerName: "IQ",
            width: 150,
            renderCell: (params) => {
                const dqPhase = params.row.testPhases.find(
                    (phase) => phase.phaseType === "IQ"
                );

                if (dqPhase) {
                    return renderCheckboxWithChip(dqPhase.isActive, dqPhase.subType);
                } else {
                    return <span>-</span>;
                }
            },
        },
        {
            field: "isOQ",
            headerName: "OQ",
            width: 150,
            renderCell: (params) => {
                const dqPhase = params.row.testPhases.find(
                    (phase) => phase.phaseType === "OQ"
                );

                if (dqPhase) {
                    return renderCheckboxWithChip(dqPhase.isActive, dqPhase.subType);
                } else {
                    return <span>-</span>;
                }
            },
        },
        {
            field: "isPQ",
            headerName: "PQ",
            width: 150,
            renderCell: (params) => {
                const dqPhase = params.row.testPhases.find(
                    (phase) => phase.phaseType === "PQ"
                );

                if (dqPhase) {
                    return renderCheckboxWithChip(dqPhase.isActive, dqPhase.subType);
                } else {
                    return <span>-</span>;
                }
            },
        },

        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button color="primary" onClick={() => handleEditTest(params.row)} >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        <Button color="primary" onClick={() => handleDeleteClick(params.row.id)} >
                            <Localized id="delete">
                                <span>Delete</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    return <>
        <TableContainer component={Paper}>
            <DataGrid localeText={getCustomLocaleText()}
                getRowId={(row) => `${row.id}`}
                style={{ minHeight: 100, height: 'calc(100vh - 360px)' }}
                rows={testsOverview}
                columns={columns}
                onRowDoubleClick={({ row }) => handleEditTest(row)}
            />
            <FlexDiv>
                <Fab
                    size="small"
                    sx={{
                        m:1 
                    }}
                    onClick={handleAddTest}
                    color="secondary"
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </TableContainer>
        {
            dialogAddTestVisible &&
            <CreateTestSpecificationCreator
                defName={""}
                context={""}
                defStructureId={defStructureId}
                relatedObject={"DEVICE_COMPONENT"}
                relatedObjectId={relatedObjectId.toString()}
                onSuccess={handleOnSuccess}
                onCancel={handleOnClose} />
        }
        {
            dialogEditTestVisible && selectedTest &&
            <EditTestSpecificationDialog testSpecification={selectedTest}
                onSave={handleOnSuccess}
                onCancel={handleOnClose} />
        }
        {
            itemToDelete != null &&
            <DeleteConfirmationDialog
                open={true}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete} />
        }

    </>
}
