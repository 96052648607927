import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import { Button, ButtonGroup, Grid, MenuItem, Paper } from "@mui/material"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Link, useParams } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { ChangeEvent, useMemo, useState } from "react"
import SaveIcon from '@mui/icons-material/Save'
import { createDevice, selectDeviceById, updateDevice } from './deviceSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectAllDeviceTypes } from './deviceTypesSlice'
import { Skeleton } from '@mui/material'
import { selectAllCompanies } from '../company/companiesSlice'
import { LoadCompanies, LoadDeviceTypes } from '../../app/AppDataLoader'
import { AppLoader } from '../../app/AppLoader'
import { useNavigate } from 'react-router-dom'


const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const DeviceForm = () => {

    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectDeviceById(state, id));
    
    const [name, setName] = useState<string>(entity?.name ?? "");    
    const [description, setDescription] = useState<string>(entity?.description ?? "");
    const [varrantyStatus, setVarrantyStatus] = useState<string>(entity?.varrantyStatus ?? "");
    const [manufacturer, setManufacturer] = useState<string>(entity?.manufacturer ?? "");
    const [producerId, setProducerId] = useState<string>(entity?.producerId ?? "");
    const [producerName] = useState<string>(entity?.producerName ?? "");
    const [model, setModel] = useState<string>(entity?.model ?? "");
    const [productionYear, setProductionYear] = useState<number>(entity?.productionYear ?? 0);
    const [serialNo, setSerialNo] = useState<string>(entity?.serialNo ?? "");
    const [deviceTypeId, setDeviceTypeId] = useState<string>(entity?.deviceTypeId ?? "");
    const [deviceTypeName] = useState<string>(entity?.deviceTypeName ?? "");
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    
    const allDeviceTypes = useAppSelector(selectAllDeviceTypes)
    const allCompanies = useAppSelector(selectAllCompanies)

    
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if(name && deviceTypeId && producerId){
            if (!entity) {
                try {
                    const actionResult = await dispatch(createDevice({                     
                        name, deviceTypeId, deviceTypeName, producerId, producerName, model, serialNo, productionYear, description, manufacturer, varrantyStatus
                    }));
                    const { id: newId } = unwrapResult(actionResult);

                    dispatch(showSuccess("saved"));

                    navigate(`/devices/edit/${newId}`, { replace: true });
                } catch (error) {
                    dispatch(showError("error"));
                }
            } else {
                try {
                    await dispatch(updateDevice({...entity, 
                        name, deviceTypeId, producerId, producerName, model, serialNo, productionYear, description, manufacturer, varrantyStatus
                    }));
                    dispatch(showSuccess("saved"));
                } catch (error) {
                    dispatch(showError("error"));
                }
            }
        }

        setSaving(false)
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setName(e.target.value), [setName]);

    const handleProductionYearChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setProductionYear(parseInt(e.target.value)), [setProductionYear]);

    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setDescription(e.target.value), [setDescription]);

    const handleProducerIdChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setProducerId(e.target.value), [setProducerId]);
                                
    const handleModelChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setModel(e.target.value), [setModel]);   

    const handleSerialNoChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setSerialNo(e.target.value), [setSerialNo]);   

    const handleTypeChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setDeviceTypeId(e.target.value), [setDeviceTypeId]);  


    const nameError = validationErrors && name === ""
    const deviceTypeIdError = validationErrors && deviceTypeId === ""
    const producerIdError = validationErrors && producerId === ""

    return (
        <>
            <Container maxWidth="md" sx={{paddingTop: 2, paddingBottom: 2}}>
                <Paper >
                <Grid container spacing={2} sx={{padding:2}}>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={nameError}
                            helperText={nameError && <RequiredError />}
                            multiline={true}       
                            inputProps={{ maxLength: 200 }}                                 
                            value={name}   
                            onChange={handleNameChange}                        
                            label={<Localized id="devices-name">Nazwa urzadzenia</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}>
                        <LoadDeviceTypes component={<Skeleton variant="rectangular"/>}>
                            <TextField 
                                select 
                                required
                                error={deviceTypeIdError}
                                helperText={deviceTypeIdError && <RequiredError />}
                                fullWidth                               
                                value={deviceTypeId}  
                                onChange={handleTypeChange} 
                                label={<Localized id="devices-type">Typ</Localized>}>
                                    {allDeviceTypes.map((devType) => <MenuItem key={devType.id} value={devType.id}>{devType.name}</MenuItem>)}
                            </TextField>
                        </LoadDeviceTypes>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadCompanies component={<Skeleton variant="rectangular" />}>
                            <TextField 
                                select
                                fullWidth  
                                required  
                                error={producerIdError}
                                helperText={producerIdError && <RequiredError />}                                  
                                value={producerId}      
                                onChange={handleProducerIdChange}                             
                                label={<Localized id="devices-producer">Producent</Localized>}>
                                    {allCompanies.filter(c => c.isProducer === true).map((company) => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
                            </TextField>
                        </LoadCompanies>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            fullWidth
                            value={model}     
                            onChange={handleModelChange}                             
                            label={<Localized id="devices-model">Model</Localized>}>                            
                        </TextField>
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField 
                            fullWidth
                            value={serialNo}           
                            onChange={handleSerialNoChange}                                                       
                            label={<Localized id="devices-serial-number">Numer fabryczny</Localized>}>                                
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>  
                        <TextField 
                            fullWidth
                            type="number"      
                            onChange={handleProductionYearChange}
                            value={productionYear} 
                            onInput={(e) => {(e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 4)}}                               
                            label={<Localized id="devices-production-year">Rok produkcji</Localized>}>                            
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={description}                 
                            onChange={handleDescriptionChange}                 
                            multiline     
                            minRows={4}                     
                            label={<Localized id="devices-description">Opis</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <ButtonGroup color="secondary" variant="contained">
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                            <Button  component={Link} to={`/devices`} color="secondary" variant="outlined">
                                <Localized id="back">Wróć</Localized>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>                    
                </Paper>
            </Container>
            <AppLoader open={saving} />
        </>
    );
}
