import { FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, TableContainer } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { SystemEvent, getSystemEventsTypes, loadSystemEvents, loadSystemEventsForCompany } from "./systemEventsApi"
import { Localized, useLocalization } from "@fluent/react"
import { LoadCompanies } from "../../../app/AppDataLoader"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../../settings/settingsSlice"
import { selectUserCompanyId } from "../../user/userSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import SyncIcon from '@mui/icons-material/Sync';
import { selectModule } from "../../modules/moduleSlice"
import { If } from "../../../app/If"
import { Company, loadCompanies, selectCompanyById } from "../../company/companiesSlice"
import { store } from "../../../app/store"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { DateMenuFilter } from "../../../app/DateMenuFilter"

export const SystemEvents = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();
    const [systemEvents, setSystemEvents] = useState<SystemEvent[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [eventType, setEventType] = useState('all');
    const [saving, setSaving] = useState(false);
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string

    const [company, setCompany] = useState(loggedUserCompanyId ? loggedUserCompanyId : 'all');

    const currentDate = new Date();
    var dateFromFilter = new Date();
    dateFromFilter.setDate(currentDate.getDate() - 30);
    const [dateFrom, setDateFrom] = useState(dateFromFilter);
    const [dateTo, setDateTo] = useState(new Date());

    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const isSuper = currentModule?.code === "super"

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSystemEventsForCompany({ companyId: loggedUserCompanyId, query: `?eventType=${eventType}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}` }))
            .then(unwrapResult)
            .then(setSystemEvents)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
        dispatch(getSystemEventsTypes())
            .then(unwrapResult)
            .then(setEventTypes)
            .catch((error) => console.error(error))
        if(isSuper) {
            dispatch(loadCompanies())
            .then(unwrapResult)
            .then(setCompanies)
            .catch((error) => console.error(error))
        }
    }, [dispatch, loggedUserCompanyId])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleSubmit = (date) => {
        setDateFrom(date.dateFrom);
        setDateTo(date.dateTo);
    }

    const handleEventTypeChange = (event) => {
        setSaving(false);
        setEventType(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setSaving(false);
        setCompany(event.target.value);
    };

    const handleRefreshClick = () => {
        setSaving(true);
        setIsLoadingData(true);
        if(!isNaN(dateFrom.getTime()) && !isNaN(dateTo.getTime())) {
            if(company === 'all') {
                dispatch(loadSystemEvents(`?eventType=${eventType}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`)) // &dateFrom=${dateFrom}&dateTo=${dateTo}
                    .then(unwrapResult)
                    .then(setSystemEvents)
                    .catch((error) => console.error(error))
                    .finally(() => setIsLoadingData(false))
            } else {
                dispatch(loadSystemEventsForCompany({ companyId: company, query: `?eventType=${eventType}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}` })) // &dateFrom=${dateFrom}&dateTo=${dateTo}
                    .then(unwrapResult)
                    .then(setSystemEvents)
                    .catch((error) => console.error(error))
                    .finally(() => setIsLoadingData(false))
            }
            setSaving(false);
        }
    }

    const columns: GridColDef<SystemEvent, any, any>[] = [
        {
            field: 'createdBy',
            headerName: l10n.getString("username", null, "username"),
            width: 300
        },
        {
            field: 'companyId',
            headerName: l10n.getString("company", null, "Firma"),
            flex: 1,
            valueGetter: (_, row) => {
                const entity = selectCompanyById(store.getState(), row.companyId)

                if (!entity) {
                    return ""
                } else {
                    return entity.shortName
                }
            }
        },
        {
            field: 'eventType',
            headerName: l10n.getString("system-events-type", null, "Zdarzenie"),
            flex: 1,
        },
        {
            field: 'eventDate',
            headerName: l10n.getString("system-events-date", null, "Data zdarzenia"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.eventDate !== null) return new Date(row.eventDate).toLocaleString();
            }
        },
        {
            field: 'description',
            headerName: l10n.getString("system-events-description", null, "Opis"),
            flex: 1,
        },
        // {
        //     field: 'actions', type: 'actions', width: 150,
        //     renderCell: (params) => {
        //         return (
        //             <ButtonGroup size="small">
        //                 <Button
        //                     style={{ width: 100 }}
        //                     component={Link}
        //                     to={`/user/edit/${params.row.id}`}
        //                     color="secondary"
        //                     variant="outlined" >
        //                     <Localized id="details">
        //                         <span>Szczegóły</span>
        //                     </Localized>
        //                 </Button>
        //             </ButtonGroup>
        //         )
        //     }
        // }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <div>
                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                    <If condition={isSuper}>
                        <FormControl>
                            <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                            <Select
                                labelId="select-company-label"
                                value={company}
                                onChange={handleCompanyChange}
                                label={<Localized id="company">Firma</Localized>}
                                sx={{ width: '200px' }}
                            >
                                <MenuItem value={'all'}>
                                    <Localized id="all">All</Localized>
                                </MenuItem>
                                {companies.map((option) =>
                                    <MenuItem value={option.id}>{option.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </If>
                    <FormControl>
                        <InputLabel id="select-event-type-label"><Localized id="system-events-type">Typ zdarzenia</Localized></InputLabel>
                        <Select
                            labelId="select-event-type-label"
                            value={eventType}
                            onChange={handleEventTypeChange}
                            label={<Localized id="system-events-type">Typ zdarzenia</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {eventTypes.map((option) =>
                                <MenuItem value={option}>{option}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <DateMenuFilter onSubmit={handleSubmit}/>
                    <IconButton
                        onClick={handleRefreshClick}
                        disabled={saving}
                        >
                            <SyncIcon />
                    </IconButton>
                </Stack>
            </div >
            <LoadCompanies component={<Skeleton variant="rectangular" />}>
                <TableContainer component={Paper}>
                {isLoadingData ? (<LoadingContainer/>) : (
                    <DataGrid localeText={getCustomLocaleText()}
                        style={{ minHeight: 100, height: 'calc(100vh - 300px)' }}
                        rows={systemEvents}
                        columns={columns}
                        initialState={{
                            sorting: { sortModel: [{ field: 'eventDate', sort: 'desc' }] }
                        }}
                    />
                )}
                </TableContainer>
            </LoadCompanies >
        </>
    )
}
