import { Localized, useLocalization } from "@fluent/react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { If } from "../../app/If";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Box } from "@mui/system";
import produce from "immer";
import { selectSettings } from '../settings/settingsSlice';
import MenuItem from '@mui/material/MenuItem';
import { ReqCriticalityLabel } from "../urs/ReqCriticalityLabel";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Check, Clear } from "@mui/icons-material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { UrsOfferStatusLabel } from "../ursOffer/UrsOfferStatusLabel";
import { NoteLabel } from "../ursOffer/NoteLabel";
import { useLocation } from "react-router-dom";
import { Blobs, loadManyBlobs, Uuid } from "../urs/ursAttachementsSlice";
import DownloadIcon from '@mui/icons-material/Download';
import { ExternalRequirementNote, ExternalUrsOffer, ExternalUrsOfferRequirement, addNote, finishCommenting, loadExternalUrsOffer, rejectExternalOffer, selectAllExternalOfferRequirements, sendExternalToEvaluation, postExternalUrsRequirementComment, ExternalRequirementComment, deleteExternalUrsRequirementComment, updateExternalUrsRequirementComment, allExternalOfferRequirements } from "./externalOfferSlice";
import { Comment, CommentKind, DiscussOld } from "../../app/Discuss";
import { parseISODate } from "../../app/Utils";

type MainTabs = "reqs" | "description" | "attachments"

const LoadComponent = () => <Skeleton variant="rectangular" height="50vh" />

export const ExternalOfferForm = () => {
    const { search } = useLocation()

    const params = new URLSearchParams(search)

    const token = params.has("t") ? params.get("t") : "";
    const mode = "external"

    const dispatch = useAppDispatch()

    const [urs, setUrs] = useState<undefined | "error" | ExternalUrsOffer>(undefined)
    const [blobs, setBlobs] = useState<Blobs[]>([])

    function* allURSRequirements(urs: ExternalUrsOffer) {
        for (const type of urs.types) {
            for (const req of type.requirements) {
                yield req
            }
            for (const group of type.groups) {
                for (const req of group.requirements) {
                    yield req
                }
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (urs === undefined && token) {
                try {

                    const entity = await dispatch(loadExternalUrsOffer(token)).unwrap()

                    const allRequirements = Array.from(allURSRequirements(entity))
                    const blobs = await dispatch(loadManyBlobs([{
                        docType: 'offer',
                        docId: entity.id
                    }, [entity.guid, ...allRequirements.map(x => x.guid)]])).unwrap()

                    setUrs(entity)
                    setBlobs(blobs)
                } catch (err) {
                    if (err !== null && typeof err === 'object' && err['name'] && err['name'] === 'ConditionError') {
                        // dispatch cancelled
                        console.log(err)
                    } else {
                        setUrs("error")
                    }
                }
            }
        }
        fetchData()
    }, [dispatch, urs, token])

    switch (urs) {
        case undefined:
            return <LoadComponent />
        case "error":
            return <Alert severity="error">
                <Localized id="linkExpired"><span>Link nieaktywny</span></Localized>
            </Alert>
    }

    return <>
        <Form entity={urs} blobs={blobs} mode={mode} token={token ?? ""} />
    </>
}

interface URSRequirementRowProps {
    requirement: ExternalUrsOfferRequirement
    onEditClick: (req: ExternalUrsOfferRequirement) => void
    canEdit: boolean
}

const URSRequirementRow = ({ requirement, onEditClick, canEdit }: URSRequirementRowProps) => {
    return <TableRow>
        <TableCell>{requirement.numeration ?? 'null'}</TableCell>
        <TableCell>
            <div dangerouslySetInnerHTML={{ __html: requirement?.name ?? "" }}></div>
        </TableCell>
        <TableCell>{<ReqCriticalityLabel criticality={requirement.criticality ?? ""} />}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <NoteLabel note={requirement.note} />
        </TableCell>
        <TableCell>{requirement.comment}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <ButtonGroup variant="outlined" color="secondary" size="small">
                <If condition={canEdit}>
                    <Button size="small" onClick={() => onEditClick(requirement)}>
                        <Localized id="urs-offer-action-rate">
                            <span>Oceń</span>
                        </Localized>
                    </Button>
                </If>
            </ButtonGroup>
        </TableCell>
    </TableRow>
}

type DialogState = "none" | "addReqComment" | "confirmSendOffer" | "confirmRejectOffer" | "confirmSendToEvaluate" | "offerDetail" | "selectDevice" | "confirmAutoMatch"

export const Form = ({ entity, mode, blobs, token }: { token: string, entity: ExternalUrsOffer, mode: string, blobs: Blobs[] }) => {
    const dispatch = useAppDispatch()
    const { l10n } = useLocalization()
    const [draftEntity, setDraftEntity] = useState(entity)
    const { locale } = useAppSelector(selectSettings);

    const [tab, setTab] = useState<MainTabs>("reqs")
    const [dialog, setDialog] = useState<DialogState>("none")
    const [saving, setSaving] = useState(false)
    const [comments, setComments] = useState<ExternalRequirementComment[]>(entity.comments)

    const handleReqCommentChange: AppTextFieldHandler = useCallback(e => setReqComment(e.target.value), [])
    const handleReqCommentStatusChange: AppTextFieldHandler = useCallback(e => setReqNote(e.target.value as ExternalRequirementNote), [])

    const [requirement, setRequirement] = useState<ExternalUrsOfferRequirement | null>(null)
    const [reqNote, setReqNote] = useState<ExternalRequirementNote>("")
    const [reqComment, setReqComment] = useState("")
    const [offerComment, setOfferComment] = useState("")
    const [registrationType, setRegistrationType] = useState('withregistration');

    const [email, setEmail] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [emailError, setEmailError] = useState("");

    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [state, setState] = useState("");
    const [companyName, setCompanyName] = useState(draftEntity.supplierName);
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyNameError, setCompanyNameError] = useState(false);
    
    const handleEmailChange: AppTextFieldHandler = useCallback(e => setEmail(e.target.value), [setEmail])
    const handleEmailConfrimationChange: AppTextFieldHandler = useCallback(e => setEmailConfirmation(e.target.value), [setEmailConfirmation])
    const handleFirstNameChange: AppTextFieldHandler = useCallback(e => setFirstName(e.target.value), [setFirstName])
    const handleLastNameChange: AppTextFieldHandler = useCallback(e => setLastName(e.target.value), [setLastName])
    const handleCompanyNameChange: AppTextFieldHandler = useCallback(e => setCompanyName(e.target.value), [setCompanyName])
    const handlePhoneChange: AppTextFieldHandler = useCallback(e => setPhone(e.target.value), [setPhone])
    const handleAddressChange: AppTextFieldHandler = useCallback(e => setAddress(e.target.value), [setAddress])
    const handleCityChange: AppTextFieldHandler = useCallback(e => setCity(e.target.value), [setCity])
    const handleStateChange: AppTextFieldHandler = useCallback(e => setState(e.target.value), [setState])
    const handleZipCodeChange: AppTextFieldHandler = useCallback(e => setZipcode(e.target.value), [setZipcode])
    const handleCountryChange: AppTextFieldHandler = useCallback(e => setCountry(e.target.value), [setCountry])
    
    const clearErrors = () => {
        setEmailError("")
        setFirstNameError(false);
        setLastNameError(false);
        setCompanyNameError(false);
    }
    const handleRadioChange = (event) => {
      setRegistrationType(event.target.value);
    };
    const requirementComments = useMemo(() => {
        return comments.filter(({ ursRequirementId }) => ursRequirementId.toString() === requirement?.id ?? "").map(({ id, message, author, parentMessageId, userId, date }) => {
            return {
                someId: id,
                content: message,
                author: author ?? "Bez nazwy",
                parentId: parentMessageId,
                authorId: userId,
                date: parseISODate(date),
                companyName: companyName
            } as Comment
        })
    }, [requirement, comments])

    const postComment = async (message: string, parentCommentId: AppId | null, kind: CommentKind): Promise<Uuid | undefined> => {
        if (requirement !== null) {
            try {
                const newComment = await dispatch(postExternalUrsRequirementComment({
                    token,
                    comment: {
                        id: "",
                        ursOfferId: entity.id,
                        ursRequirementId: requirement.id,
                        message,
                        author: "",
                        userId: null,
                        date: new Date().toISOString(),
                        parentMessageId: parentCommentId,
                        subMessages: [],
                        status: "",
                        guid: '',
                        type: kind,
                    },
                })).unwrap() 
                setComments(produce(draft => {
                    draft.push(newComment)
                }))
                return newComment.guid
            } catch (e) {
                /* handle error */
                console.log(e)
            }
        }
    }
    const updateComment = async (commentId: AppId, message: string) => {
        const comment = comments.find(x => x.id === commentId)
        if (requirement !== null && comment) {
            try {
                const newComment = await dispatch(updateExternalUrsRequirementComment({
                    token,
                    comment: {
                        ...comment,
                        message,
                    },
                })).unwrap() 
                setComments(produce(draft => {
                    const i = draft.findIndex(x => x.id === commentId)
                    if (i >= 0) {
                        draft[i] = newComment
                    }
                }))
            } catch (e) {
                /* handle error */
                console.log(e)
            }
        }
    }
    const deleteComment = async (id: AppId) => {
        try {
            await dispatch(deleteExternalUrsRequirementComment({
                token,
                commentId: id,
            })).unwrap()
            setComments(oldComments => oldComments.filter(comment => comment.id !== id))
        } catch (e) {
            /* handle error */
            console.log(e)
        }
    }

    const [contextTypeId, setContextTypeId] =
        useState<AppId | undefined>(entity.types.length > 0 ? entity.types[0].id : undefined)
    const contextType = useMemo(() => draftEntity.types.find(x => x.id === contextTypeId), [contextTypeId, draftEntity.types])

    const handleReqsTabChange = useCallback((_, newType) => {
        setContextTypeId(newType)
    }, [])

    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleCommentOKButtonClickk = async () => {
        setSaving(true)
        if (requirement) {
            try {
                const newOffer = await dispatch(addNote({
                    token: entity.token,
                    requirementId: requirement.id,
                    note: reqNote,
                    comment: reqComment
                })).unwrap()

                dispatch(showSuccess("saved"));

                setDraftEntity(newOffer)
            } catch (error) {
                dispatch(showError("error"));
            }

        }

        setDialog("none")
        setSaving(false)

    }

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])

    const handlePrintUrsOfferClick = async () => {
        if (draftEntity.id) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs-offer/${draftEntity.id}?language=${locale}`, '_blank')
        }
    }

    const handleFinishOfferClick = async () => {
        setDialog("confirmSendOffer")
    }

    const handleRejectOfferClick = async () => {
        setOfferComment("")
        setDialog("confirmRejectOffer")
    }

    const handleSendToEvaluateClick = async () => {
        setOfferComment("")
        setDialog("confirmSendToEvaluate")
    }

    const handleOfferCommentChange: AppTextFieldHandler = useCallback(e => setOfferComment(e.target.value), [])

    

    const handleConfirmSendOfferClick = async () => {
        try {
            clearErrors()
            if (!Validate()) {

                return;
            }
            setSaving(true)
            const result = await dispatch(finishCommenting({
                token: draftEntity.token,
                email: email,
                emailConfirmation: emailConfirmation,
                option: registrationType,
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                address: address,
                companyName: companyName,
                city: city,
                state: state,
                zipcode: zipcode,
                country: country
            }));

            if (finishCommenting.fulfilled.match(result)) {
                const updatedEntity = result.payload as ExternalUrsOffer;
                setDraftEntity(updatedEntity)
                dispatch(showSuccess("saved"))

            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }


    const handleConfirmRejectOfferClick = async () => {
        try {
            setSaving(true)

            const updatedEntity = await dispatch(rejectExternalOffer({ token: draftEntity.token, comment: offerComment })).unwrap()
            setDraftEntity(updatedEntity)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)

    }


    const handleConfirmSendToEvaluateClick = async () => {
        try {
            setSaving(true)
            const updatedEntity = await dispatch(sendExternalToEvaluation({ token: draftEntity.token, comment: offerComment })).unwrap()
            setDraftEntity(updatedEntity)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }


    const handleEditRequirementClick = useCallback((requirement: ExternalUrsOfferRequirement) => {

        const { note, comment } = requirement
        setRequirement(requirement)
        setReqNote(note)
        setReqComment(comment)
        setDialog("addReqComment")

    }, [])

    function Validate() {
        let valResult = true;
        validateEmail()

        if (firstName === '') {
            valResult = false;
            setFirstNameError(true);
        }

        if (lastName === '') {
            valResult = false;
            setLastNameError(true);
        }

        if (companyName === '') {
            valResult = false;
            setCompanyNameError(true);
        }

        if (emailError != '') {
            valResult = false;

        }
        return valResult;
    }
    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let valResult = true;

        if (!emailRegex.test(email)) {
            setEmailError(l10n.getString("email-incorrect", null, "_Nieprawidłowy adres e-mail"));
            valResult = false;
        }
        
        
        else if (email !== '' && emailConfirmation !== email) {
            setEmailError(l10n.getString("emails-do-not-match", null, "_emails not match"));
            valResult = false;
        }
        else {
            setEmailError("");
        }
        return valResult;
    };

    const editable = (draftEntity.status === "N" || draftEntity.status === "E") && mode !== "preview"

    if (contextTypeId === undefined || contextType === undefined) return <div>Nothing to display</div>

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }} component={Paper} maxWidth={false} >
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Card>
                        <CardHeader
                            title={draftEntity.ursNo}
                            subheader={<Localized id="urs-number"><span>Symbol dokumentu</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <CardHeader
                            title={draftEntity.deviceTypeName}
                            subheader={<Localized id="urs-device-type"><span>Typ urządzenia</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={draftEntity.supplierName}
                            subheader={<Localized id="urs-offer-supplier"><span>Dostawca</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={draftEntity.companyName}
                            subheader={<Localized id="urs-offer-client"><span>Klient</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={<UrsOfferStatusLabel status={draftEntity.status}></UrsOfferStatusLabel>}
                            subheader={<Localized id="urs-offer-status"><span>Status</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleMainTabChange}>
                            <Tab
                                key={0}
                                value="reqs"
                                label={<Localized id="urs-requirements">Lista wymagań</Localized>} />
                            <Tab
                                key={1}
                                value="description"
                                label={<Localized id="urs-purpose-and-description">Cel i opis</Localized>} />
                            <Tab
                                key={2}
                                value="attachments"
                                label={<Localized id="urs-attachements">Załączniki</Localized>} />
                        </TabList>
                        <TabPanel value="reqs">
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                            }}>
                                <Tabs
                                    orientation="vertical"
                                    variant="standard"
                                    value={contextTypeId}
                                    onChange={handleReqsTabChange}
                                    sx={{
                                        borderRight: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    {Array.from(draftEntity.types, t => <Tab
                                        key={t.id}
                                        label={t.code}
                                        value={t.id}
                                    />)}
                                </Tabs>
                                <Stack sx={{
                                    paddingLeft: 4,
                                    width: '100%',
                                }} direction="column" spacing={2}>
                                    <Typography variant="subtitle2">
                                        <If condition={contextTypeId !== undefined}
                                            otherwise={<></>}
                                        >{`${contextType.numeration} ${contextType.code} ${contextType.name}`}</If>
                                    </Typography>
                                    <TableContainer sx={{
                                         maxHeight: 'calc(100vh - 400px)',
                                         overflowY: 'auto',
                                    }} component={Paper}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ minWidth: 50, width: 50, maxWidth: 50 }}>
                                                        <Typography component="span" variant="subtitle2" color="textSecondary">
                                                            <Localized id="urs-item-lp">Lp</Localized>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography component="span" variant="subtitle2" color="textSecondary">
                                                            <Localized id="urs-item-name">Nazwa</Localized>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                                        <Typography component="span" variant="subtitle2" color="textSecondary">
                                                            <Localized id="urs-item-criticality">Krytyczność</Localized>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: 100, width: 100, maxWidth: 100, textAlign: "center" }}>
                                                        <Typography component="span" variant="subtitle2" color="textSecondary">
                                                            <Localized id="urs-offer-note">Ocena</Localized>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: 200 }}>
                                                        <Typography component="span" variant="subtitle2" color="textSecondary">
                                                            <Localized id="urs-offer-comment">Komentarz</Localized>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ minWidth: 100, width: 100, maxWidth: 100, justifyContent: 'flex-end' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {contextType.requirements.map(requirement => <URSRequirementRow
                                                    key={requirement.id}
                                                    requirement={requirement}
                                                    onEditClick={handleEditRequirementClick}
                                                    canEdit={editable}
                                                />)}
                                                {contextType.groups.map(group => 
                                                    <>
                                                        <TableRow sx={{ bgcolor: '#f1f1f1' }}>
                                                            <TableCell colSpan={5}>{`${group.numeration} ${group.name}`}</TableCell>
                                                        </TableRow>
                                                        {group.requirements.map(
                                                            requirement => <URSRequirementRow
                                                                key={requirement.id}
                                                                requirement={requirement}
                                                                onEditClick={handleEditRequirementClick}
                                                                canEdit={editable} />)
                                                        }
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </Box>
                        </TabPanel>
                        <TabPanel value="description">
                            <Stack direction="column" spacing={4} >
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-title"><span>Tytuł dokumentu</span></Localized>
                                    </Typography>
                                    <div>{entity.title}</div>
                                 </Box>
                                 <Box>
                                     <Typography variant="h5"component="h5" gutterBottom>
                                         <Localized id="urs-purpose"><span>Cel</span></Localized>
                                     </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: entity.purpose }} />
                                 </Box>
                                 <Box>
                                     <Typography variant="h5" component="h5" gutterBottom>
                                         <Localized id="urs-description"><span>Opis</span></Localized>
                                     </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: entity.description }} />
                                 </Box>
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-area"><span>Obszar działania</span></Localized>
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: entity.area }} />
                                 </Box>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="attachments">
                            <AttachmentsTable blobs={blobs} entity={entity} />
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="secondary">
                        <Tooltip title={<Localized id="">Wydruk dostępny po zakończeniu komentowania</Localized>} arrow>
                            <span>
                            <Button disabled={true} color="primary" onClick={() => handlePrintUrsOfferClick()} variant="outlined"  >
                                <Localized id="urs-offer-action-print">
                                    <span>Wydruk</span>
                                </Localized>
                            </Button>
                            </span>
                        </Tooltip>
                        <Button color="primary" onClick={() => handleSendToEvaluateClick()} variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                            <Localized id="urs-offer-action-send-to-evaluation">
                                <span>Wyślij do oceny</span>
                            </Localized>
                        </Button>
                        <Button color="primary" onClick={() => handleRejectOfferClick()} variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                            <Localized id="urs-offer-action-reject">
                                <span>Zrezygnuj</span>
                            </Localized>
                        </Button>
                        <Button onClick={() => handleFinishOfferClick()} color="secondary" variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                            <Localized id="urs-offer-action-send-offer">
                                <span>Złóż ofertę</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Dialog open={dialog === "addReqComment"} fullWidth maxWidth="md">
                <DialogTitle>
                    <Localized id="urs-offer-evaluation-title">
                        <span>Wprowadź ocenę wymagania</span>
                    </Localized>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                value={reqNote}
                                fullWidth
                                select
                                label={<Localized id="urs-offer-req-opinion"><span>Ocena</span></Localized>}
                                onChange={handleReqCommentStatusChange}
                            >

                                <MenuItem value={"F"}><Check fontSize="small" color='success' /> <Localized id="urs-offer-req-f"><span> Spełnia</span></Localized></MenuItem>
                                <MenuItem value={"U"}><Clear fontSize="small" color='error' /><Localized id="urs-offer-req-u"><span> Nie spełnia</span></Localized></MenuItem>
                                <MenuItem value={"D"}><QuestionMarkIcon fontSize="small" color='warning' /><Localized id="urs-offer-req-d"><span> Do oceny</span></Localized></MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                inputProps={{ maxLength: 1000 }}
                                value={reqComment}
                                onChange={handleReqCommentChange}
                                label={<Localized id="urs-offer-req-comment"><span>Komentarz</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DiscussOld 
                                postComment={postComment} 
                                deleteComment={deleteComment} 
                                updateComment={updateComment} 
                                allComments={requirementComments} 
                                currentUserId={null}
                                readOnly={false}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommentOKButtonClickk}>
                        <Localized id="ok">
                            <span>Ok</span>
                        </Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel">
                            <span>Cancel</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "confirmSendToEvaluate"}>
                <DialogTitle>
                    <Localized id="urs-offer-action-send-to-evaluation-question">
                        <span>Czy wysłać oferte do oceny klienta?</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-offer-action-send-to-evaluation-question-descripton">
                            <span>Komentowanie nadal możliwe po wysyłce?</span>
                        </Localized>
                    </DialogContentText>
                    <TextField
                        multiline
                        rows={5}
                        onChange={handleOfferCommentChange}
                        value={offerComment}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        fullWidth={true}
                        sx={{ marginTop: "20px", minWidth: "400px" }}
                        label={<Localized id="urs-offer-evaluate-comment"><span>Komentarz</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving}  onClick={handleConfirmSendToEvaluateClick}>
                        <Localized id="send"><span>Wyślij</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="md" fullWidth={true} open={dialog === "confirmSendOffer"}>
                <DialogTitle>
                    <Localized id="urs-offer-action-send-offer-question">
                        <span>Czy napewno zakończyć komentowanie</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-offer-action-send-offer-question-description">
                            <span>Zakończenie komentowania?</span>
                        </Localized>
                    </DialogContentText>
                    <Container>
                        <RadioGroup row value={registrationType} onChange={handleRadioChange}>
                            <FormControlLabel
                                value="withregistration"
                                control={<Radio />}
                                label="Zarejestruj się"
                            />
                            <FormControlLabel
                                value="noregistration"
                                control={<Radio />}
                                label="Bez rejestracji"
                            />
                        </RadioGroup>
                        <Grid container spacing={3}>
                            {registrationType === 'withregistration' && (
                            <>
                                <Grid item xs={12}>
                                    <Paper elevation={3} style={{ padding: 16 }}>
                                        <Typography variant="body2">Rejestrując się:</Typography>
                                        <Typography variant="body2"> - będziesz mógł dokończyć proces z aplikacji wraz z klientem</Typography>
                                        <Typography variant="body2"> - uzyskasz dostęp do wirtualnej kopii dokumentu</Typography>
                                        <Typography variant="body2"> - zapoznasz się z innymi funkcjonalnościami</Typography>
                                    </Paper>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <TextField
                                        value={email}
                                        fullWidth
                                        required
                                        onBlur={validateEmail}
                                        error={!!emailError}
                                        helperText={emailError}
                                        type={"email"}
                                        onChange={handleEmailChange}
                                        inputProps={{ maxLength: 100 }}
                                        label={<Localized id="user-email">Email</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={emailConfirmation}
                                        required
                                        fullWidth
                                        onBlur={validateEmail}
                                        error={!!emailError}
                                        helperText={emailError}
                                        type={"email"}
                                        onChange={handleEmailConfrimationChange}
                                        inputProps={{ maxLength: 100 }}
                                        label={<Localized id="user-email-confirm">Potwierdź</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        value={firstName}
                                        error={firstNameError}
                                        onChange={handleFirstNameChange}
                                        label={<Localized id="user-first-name">Imię</Localized>}>
                                    </TextField>
                                </Grid>
                               <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={lastName}
                                        error={lastNameError}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleLastNameChange}
                                        label={<Localized id="user-last-name">Nazwisko</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid  item xs={12}>                        
                                    <TextField
                                        required    
                                        fullWidth
                                        value={companyName}
                                        onChange={handleCompanyNameChange}
                                        error={companyNameError}                      
                                        label={<Localized id="">Nazwa firmy</Localized>}></TextField>
                                </Grid>
                                <Grid  item xs={12}>   
                                    <TextField
                                        fullWidth
                                        value={phone}
                                        inputProps={{ maxLength: 20 }}
                                        onChange={handlePhoneChange}
                                        label={<Localized id="companies-phone">Phone</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        value={address}
                                        onChange={handleAddressChange}
                                        label={<Localized id="companies-address">Adres </Localized> }>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField    
                                        fullWidth                                      
                                        value={city}
                                        onChange={handleCityChange}
                                        label={<Localized id="companies-address-city">Miasto</Localized> }>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        value={state}
                                        onChange={handleStateChange}
                                        label={<Localized id="companies-address-state">Stan</Localized> }>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        value={zipcode}
                                        onChange={handleZipCodeChange}
                                        label={<Localized id="companies-address-zip-code">Zip/Postal code</Localized> }>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        value={country}
                                        onChange={handleCountryChange}
                                        label={<Localized id="companies-address-country">Country</Localized> }>
                                    </TextField>
                                </Grid>
                            </>
                            )}
                            {registrationType === 'noregistration' && (
                            <>
                                <Grid item xs={12}>
                                    <Paper elevation={3} style={{ padding: 16 }}>
                                        <Typography variant="body2">Kontynuując bez rejestracji otrzymasz potwierdzenie zakończenia komentowania na podany adres email</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={email}
                                        fullWidth
                                        required
                                        onBlur={validateEmail}
                                        error={!!emailError}
                                        helperText={emailError}
                                        type={"email"}
                                        onChange={handleEmailChange}
                                        inputProps={{ maxLength: 100 }}
                                        label={<Localized id="user-email">Email</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={emailConfirmation}
                                        required
                                        fullWidth
                                        onBlur={validateEmail}
                                        error={!!emailError}
                                        helperText={emailError}
                                        type={"email"}
                                        onChange={handleEmailConfrimationChange}
                                        inputProps={{ maxLength: 100 }}
                                        label={<Localized id="user-email-confirm">Potwierdź</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        value={firstName}
                                        error={firstNameError}
                                        onChange={handleFirstNameChange}
                                        label={<Localized id="user-first-name">Imię</Localized>}>
                                    </TextField>
                                </Grid>
                               <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={lastName}
                                        error={lastNameError}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleLastNameChange}
                                        label={<Localized id="user-last-name">Nazwisko</Localized>}>
                                    </TextField>
                                </Grid>
                                <Grid  item xs={12}>                        
                                    <TextField
                                        required    
                                        fullWidth
                                        value={companyName}
                                        disabled={true}
                                        onChange={handleCompanyNameChange}
                                        error={companyNameError}                      
                                        label={<Localized id="">Nazwa firmy</Localized>}></TextField>
                                </Grid>
                            </>
                            )}
                        </Grid>
                        </Container>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSendOfferClick}>
                        <Localized id="finish"><span>Zakończ</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialog === "confirmRejectOffer"}>
                <DialogTitle>
                    <Localized id="urs-offer-action-resign-question">
                            <span>Czy napewno zrezygnować ze składania oferty?</span>
                        </Localized>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-offer-action-resign-question-description">
                            <span>Komentowanie nie bedzie juz mozliwe</span>
                        </Localized>
                    </DialogContentText>
                    <TextField
                        multiline
                        fullWidth={true}
                        rows={5}
                        onChange={handleOfferCommentChange}
                        value={offerComment}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{ marginTop: "20px", minWidth: "400px" }}
                        label={<Localized id="urs-offer-resign-comment"><span>Komentarz</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmRejectOfferClick}>
                        <Localized id="urs-offer-action-reject"><span>Zrezygnuj</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel"><span>Zakończ</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

const AttachmentsTable = ({ blobs, entity }: { blobs: Blobs[], entity: ExternalUrsOffer }) => {
    const [heads, ...items] = blobs
    const headBlobs = heads.blobs ?? []
    const allRequirements = useMemo(() => Array.from(allExternalOfferRequirements(entity)), [entity])
    return <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: '50%' }}></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {headBlobs.map(blob => {
                    return <TableRow key={blob.blobId}>
                        <TableCell colSpan={2}>
                            <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${heads.resourceId}/${blob.blobId}`}>
                                <IconButton><DownloadIcon /></IconButton>
                            </a>&nbsp;{blob.fileMeta.fileName}
                        </TableCell>
                    </TableRow>
                })}
                {items.map(({ resourceId, blobs }) => {
                    if (blobs === null || blobs.length === 0) {
                        return null
                    } else {
                        const requirement = allRequirements.find(x => x.guid === resourceId)
                        const [blob0, ...rest] = blobs
                        return [blob0].map(blob => {
                            return <TableRow key={blob.blobId}>
                                <TableCell>
                                    <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blob.blobId}`}>
                                        <IconButton><DownloadIcon /></IconButton>
                                    </a>&nbsp;{blob.fileMeta.fileName}
                                </TableCell>
                                <TableCell rowSpan={blobs.length}>{requirement?.name ?? ""}</TableCell>
                            </TableRow>
                        }).concat(rest.map(blob => {
                            return <TableRow key={blob.blobId}>
                                <TableCell>
                                    <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blob.blobId}`}>
                                        <IconButton><DownloadIcon /></IconButton>
                                    </a>&nbsp;{blob.fileMeta.fileName}
                                </TableCell>
                            </TableRow>
                        }))
                    }
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

