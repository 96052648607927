import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Chip } from "@mui/material";

const ValidationProcessesTable = () => {
  // Przykładowe dane procesów walidacyjnych
  const [processes, setProcesses] = useState([
    {
      id: 1,
      number: "001",
      description: "Initial Validation",
      startDate: "2025-01-01",
      endDate: "2025-01-10",
      status: "finished",
    },
    {
      id: 2,
      number: "002",
      description: "Hardware Check",
      startDate: "2025-01-15",
      endDate: "2025-01-20",
      status: "inprogress",
    },
    {
      id: 3,
      number: "003",
      description: "Software Validation",
      startDate: "2025-01-21",
      endDate: "2025-01-25",
      status: "pending",
    },
  ]);

  // Funkcja zmiany statusu na "inprogress"
  const handleSetInProgress = (id) => {
    setProcesses((prevProcesses) =>
      prevProcesses.map((process) => {
        if (process.id === id) {
          return { ...process, status: "inprogress" };
        } else if (process.status === "inprogress") {
          return { ...process, status: "finished" };
        }
        return process;
      })
    );
  };

  // Kolumny DataGrid
  const columns = [
    { field: "number", headerName: "Number", width: 100 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "startDate", headerName: "Start Date", width: 150 },
    { field: "endDate", headerName: "End Date", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === "inprogress"
              ? "primary"
              : params.value === "finished"
              ? "success"
              : "default"
          }
          variant="outlined"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={params.row.status === "inprogress"}
          onClick={() => handleSetInProgress(params.row.id)}
        >
          Set In Progress
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={processes}
        columns={columns}
      />
    </div>
  );
};

export default ValidationProcessesTable;
