import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Switch, TextField, Typography } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useState } from "react"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { ProjectProcess } from "../projectProcess/ProjectProcessApiClient"
import { createSupplierDocProcess } from "./documentationProcessApiClient"
import { selectAllUrsOffers } from "../../ursOffer/ursOfferSlice"
import SaveIcon from '@mui/icons-material/Save'

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface CreateSupplierDocProcessDialogProps {
    no: string
    name: string
    onSucces: (projectProcess: ProjectProcess) => void
    onCancel: () => void
}

export const CreateSupplierDocProcess = (props: CreateSupplierDocProcessDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [name, setName] = useState(props.name)
    const [no, setNo] = useState(props.no)
    const [validationErrors, setValidationErrors] = useState(false)
    const [registerDevice, setRegisterDevice] = useState(false);
    const [serialNo, setSerialNo] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [ursRequest, setSelectedValue] = useState<string | null>(null);
    const allOffers = useAppSelector(selectAllUrsOffers);

    const noError = validationErrors && no === ""
    const nameError = validationErrors && name === ""
    const deviceSerialNoError = validationErrors && registerDevice && serialNo === ""
    const deviceNameError = validationErrors && registerDevice && deviceName === ""

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (no && name && ursRequest) {
            try {
                setSaving(true)

                const result =
                    await dispatch(createSupplierDocProcess({
                        no: no,
                        name: name,
                        ursRequestId: ursRequest,
                        reqisterNewDevice: registerDevice,
                        serialNo: serialNo,
                        deviceName: deviceName
                    }))

                if (createSupplierDocProcess.fulfilled.match(result)) {
                    const proc = result.payload as ProjectProcess;
                    dispatch(showSuccess("saved"))
                    props.onSucces(proc)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='process-new-creating'>_Nowy process</Localized></DialogTitle>
        <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
                <TextField
                    fullWidth
                    autoFocus
                    error={noError}
                    helperText={noError && <RequiredError />}
                    inputProps={{ maxLength: 100 }}
                    value={no}
                    required
                    onChange={e => setNo(e.target.value)}
                    label={<Localized id='process-number'>_Process number</Localized>}
                />
                <TextField
                    fullWidth
                    autoFocus
                    error={nameError}
                    helperText={nameError && <RequiredError />}
                    inputProps={{ maxLength: 500 }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={<Localized id='process-name'>_Name</Localized>}
                />
                <Autocomplete
                    options={allOffers}
                    getOptionLabel={(option) => option.ursNo}
                    onChange={(event, newValue) => setSelectedValue(newValue ? newValue.id : null)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Link URS request"
                            variant="outlined"
                        />
                    )}
                    sx={{ width: '100%' }}
                />
                <Box sx={{ padding: 2, maxWidth: 400, margin: '0 auto' }}>
                    <Typography variant="body1" >
                        <Localized id='process-register-new-device'>_register</Localized>
                    </Typography>

                    {/* Toggle */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={registerDevice}
                                onChange={() => setRegisterDevice(!registerDevice)}
                                color="primary"
                            />
                        }
                        label={registerDevice}
                        sx={{ marginBottom: 2 }}
                    />

                    {/* ComboBox */}
                    {registerDevice && (
                        <>
                            <TextField
                                fullWidth
                                autoFocus
                                error={deviceSerialNoError}
                                helperText={deviceSerialNoError && <RequiredError />}
                                inputProps={{ maxLength: 500 }}
                                value={serialNo}
                                onChange={e => setSerialNo(e.target.value)}
                                label={<Localized id='devices-serial-number'>_serial</Localized>}
                            />
                            <TextField
                                fullWidth
                                autoFocus
                                error={deviceNameError}
                                helperText={nameError && <RequiredError />}
                                inputProps={{ maxLength: 500 }}
                                sx={{ marginTop: 2 }}
                                value={deviceName}
                                onChange={e => setDeviceName(e.target.value)}
                                label={<Localized id='devices-name'>_name</Localized>}
                            />
                        </>
                    )}
                </Box>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} size="small" color="success" variant="contained" startIcon={<SaveIcon />} onClick={handleSaveClick}>
                <Localized id="save">Zapisz</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} size="small" onClick={props.onCancel} color="primary" variant="outlined">
                <Localized id="cancel">_cancel</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
