import { Dialog, DialogContent } from "@mui/material"
import { AppId } from "../../app/appTypes"
import { useCallback, useState } from "react"
import { TestSpecification } from "./testSpecificationApi"
import { EditTestSpecificationDialog } from "./EditTestSpecificationDialog"
import { CreateTestSpecificationDialog } from "./CreateTestSpecificationDialog"

export interface CreateTestSpecificationCreatorProps {
    defName: string
    context: string
    defStructureId: AppId | null
    relatedObject: string | undefined
    relatedObjectId: string | undefined
    onSuccess: () => void
    onCancel: () => void
}

export const CreateTestSpecificationCreator = (props: CreateTestSpecificationCreatorProps) => {
    const { onSuccess, onCancel, defName, context, defStructureId, relatedObject, relatedObjectId } = props

    const [newTest, setNewTest] = useState<TestSpecification | undefined>(undefined)

    const handleTestCreated = useCallback((test: TestSpecification) => {
        setNewTest(test)
    }, [])

    return <Dialog  maxWidth="md" open={true}>
        <DialogContent>
            {!newTest ?
                <CreateTestSpecificationDialog
                    onSuccess={handleTestCreated}
                    onCancel={onCancel}
                    context={context}
                    defName={defName}
                    defStructureId={defStructureId}
                    relatedObject={relatedObject}
                    relatedObjectId={relatedObjectId} />
                :
                <EditTestSpecificationDialog 
                    testSpecification={newTest}
                    onSave={onSuccess} 
                    onCancel={onCancel}/>
            }
        </DialogContent>
    </Dialog>
}