import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../app/appTypes"
import { getWithAuth, postWithAuth, putWithAuth } from "../../http"

export type TestPhaseType = "NONE" | "IQ" | "OQ" | "PQ" | "DQ"
export type TestSubType = "NONE" | "SAT" | "FAT" | "FATSAT"


export interface TestSpecification {
    id: AppId
    name: string
    description: string
    structureId: AppId
    testPhases: Array<TestPhase>
}

export interface TestPhase {
    id: number
    phaseType: TestPhaseType
    subType: TestSubType
    isActive: boolean
    testSpecificationId: AppId
    steps: Array<TestPhaseStep>
}

export interface TestPhaseStep {
    id: number
    name: string
    order: number
    phaseId: number
    criterias: Array<TestPhaseStepCriteria>
}

export interface TestPhaseStepCriteria {
    id: number
    name: string
    order: number
    stepId: number
}

export interface CreateRelatedTestSpecification {
    name: string
    description: string
    structureId: AppId
    relatedObjectType: string
    relatedObjectId: string
}

export const createRelatedTestSpecification = createAsyncThunk<TestSpecification, CreateRelatedTestSpecification, AppThunkAPIType>(
    "test-specifications/create",
    async (testData, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: "api/test-specifications",
            payload: testData,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as TestSpecification
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateTestSpecification = createAsyncThunk<TestSpecification, TestSpecification, AppThunkAPIType>(
    "test-specifications/update",
    async (testData, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/test-specifications/${testData.id}`,
            payload: testData,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as TestSpecification
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const loadRelatedTests = createAsyncThunk<TestSpecification[], { relatedObjectType: string, relatedObjectId: string }, AppThunkAPIType>(
    "test-specifications/load-by-related-object",
    async ({ relatedObjectType, relatedObjectId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/test-specifications/by-related-object/${relatedObjectType}/${relatedObjectId}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as TestSpecification[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadTest = createAsyncThunk<TestSpecification, string, AppThunkAPIType>(
    "test-specifications/load",
    async (testId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/test-specifications/${testId}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as TestSpecification
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

