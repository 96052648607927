import { Box, Container, Paper, Fab, TableContainer, Button } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector, useDataGridCustomLocaleText } from "../../app/hooks"
import { loadSpecs, selectAllSpecs, selectSpecs, Spec } from "./specSlice"
import AddIcon from '@mui/icons-material/Add'
import {  Localized, useLocalization } from "@fluent/react"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FlexDiv, parseIntOrDef } from "../../app/Utils"
import { QuickSearchToolbar } from "../../app/QuickSearchToolbar"

export const SpecList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { l10n } = useLocalization()
    const [searchParams, setSearchParams] = useSearchParams()
    const { state } = useAppSelector(selectSpecs)
    const allSpecs = useAppSelector(selectAllSpecs)
    const localeText = useDataGridCustomLocaleText();

    const handleCreateNewSpecClick = async () => {
        navigate(`/specs/create`)
    }

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadSpecs())
        }
    }, [state])

    const page: number = parseIntOrDef(searchParams.get('page'), 0)
    const pageSize: number = parseIntOrDef(searchParams.get('pageSize'), 20)

    const isToday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const handleGoToClick = (id) => {
        navigate(`/specs/edit/${id}`)
    };


    const cols: GridColDef<Spec, any, any>[] = useMemo(() => {
        return [
            {
                field: 'type',
                width: 150,
                headerName: l10n.getString('spec-type', null, '_type')
            },
            {
                field: 'name',
                headerName: l10n.getString('spec-name', null, 'Nazwa'),
                flex: 1
            },
            {
                field: 'createDate',
                width: 150,

                headerName: l10n.getString('spec-created', null, 'Data utworzenia'),
                valueGetter: (_, row) => {
                    return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
                },
                renderCell: (params) => {
                    const isNew = isToday(params.row.createDate);
                    return (
                        <>
                            {params.value}
                            {isNew && (
                                <span
                                    className="ml-1 px-1 py-0.5 rounded bg-green-200 text-green-800 font-semibold text-sm relative"
                                    style={{ top: '-5px', fontSize: '0.7rem' }}
                                >
                                    NEW
                                </span>
                            )}
                        </>

                    );
                },
            },
            {
                field: 'actions', type: 'actions', width: 150,
                renderCell: (params) => {
                    return (
                        <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                                <span>go-to</span>
                            </Localized>
                        </Button>
                    )
                }
            }
        ]
    }, [l10n])

    const qfInitialValue: string[] = useMemo(() => searchParams.getAll('qf'), [])

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <LoadingContainer />
        case 'error':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Data not loaded due to error!</h5>
            </Container>
        case 'loaded':
            return <> <TableContainer component={Paper}>
                <DataGrid
                    columns={cols}
                    rows={allSpecs}
                    slots={{ toolbar: QuickSearchToolbar }}
                    localeText={localeText}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        }
                    }}
                    paginationModel={{ pageSize, page }}
                    onPaginationModelChange={(m) => {
                        searchParams.set('page', m.page.toString())
                        searchParams.set('pageSize', m.pageSize.toString())
                        setSearchParams(searchParams, { replace: true })
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: qfInitialValue,
                            },
                        },
                        sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                    }}
                    onFilterModelChange={(m) => {
                        searchParams.delete('qf')
                        m.quickFilterValues?.forEach(val => {
                            searchParams.set('qf', val)
                        })
                        setSearchParams(searchParams)
                    }}
                />
                <FlexDiv>
                    <Fab sx={{ m:1 }} color="secondary" size="small" onClick={handleCreateNewSpecClick}>
                        <AddIcon />
                    </Fab>
                </FlexDiv>
            </TableContainer>
        </>
    }
}
