import { Localized } from "@fluent/react";

export const DocumentationProcessStatusLabel = ({ status }: { status: string }) => {
    switch (status) {
        case "D":
            return <Localized id="project-process-draft">
                <span>DRAFT</span>
            </Localized>
        case "I":
            return <Localized id="project-process-inprogress">
                <span>INPROGRESS</span>
            </Localized>
        case "H":
            return <Localized id="project-process-onhold">
                <span>ONHOLD</span>
            </Localized>
        case "F":
            return <Localized id="project-process-finished">
                <span>FINISHED</span>
            </Localized>
        case "C":
            return <Localized id="project-process-cancelled">
                <span>CANCELLED</span>
            </Localized>
        default:
            return <span></span>
    }
}