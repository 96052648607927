import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Chip } from "@mui/material";

// Generate example data
const generateTestData = () => {
    const statuses = ["Approved", "Rejected", "Warning"];
    const names = [
        "Alice", "Bob", "Charlie", "Diana", "Ethan", "Fiona", "George", "Hannah",
    ];

    return Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        shortName: `Test-${index + 1}`,
        number: index + 1,
        plannedDate: `2025-01-${String(10 + index).padStart(2, "0")}`,
        executionDate: `2025-01-${String(15 + index).padStart(2, "0")}`,
        status: statuses[index % statuses.length],
        performedBy: names[index % names.length],
        approvedBy: names[(index + 2) % names.length],
        verifiedBy: names[(index + 4) % names.length],
    }));
};

const rows = generateTestData();

const columns = [
    { field: "number", headerName: "Number", width: 100 },
    { field: "shortName", headerName: "Short Name", width: 150 },

    { field: "plannedDate", headerName: "Planned Date", width: 150 },
    { field: "executionDate", headerName: "Execution Date", width: 150 },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            const getColor = (status) => {
                switch (status) {
                    case "Approved":
                        return "success"; // Green
                    case "Rejected":
                        return "error"; // Red
                    case "Warning":
                        return "warning"; // Yellow
                    default:
                        return "default"; // Grey
                }
            };

            return (
                <Chip
                    label={params.value}
                    size="small"
                    color={getColor(params.value)}
                    sx={{ textTransform: "uppercase" }}
                />
            );
        },
    },
    { field: "performedBy", headerName: "Performed By", width: 150 },
    { field: "approvedBy", headerName: "Approved By", width: 150 },
    { field: "verifiedBy", headerName: "Verified By", width: 150 },
];

const TestTable = () => {
    return (
        <Box >
            <DataGrid
                rows={rows}
                columns={columns}
                style={{ minHeight: 100, height: 'calc(100vh - 300px)' }}
            />
        </Box>
    );
};

export default TestTable;
