import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { Localized, useLocalization } from "@fluent/react";
import { useCallback, useEffect, useState } from "react";
import { loadSupplierDeviceInstance, SupplierDeviceInstance } from "./supplierDeviceInstanceApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { APIError } from "../../app/appTypes";
import { ServerErrorMsg } from "../../app/ServerErrorMsg";
import { LoadingContainer } from "../../app/LoadingContainer";
import { Button, Card, CardContent, Container, Grid, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BottomAppBar } from "../../app/BottomAppBar";
import { DataGrid } from "@mui/x-data-grid";
import { Attachements } from "../urs/Attachements";
import { SupplierDeviceInstanceHeader } from "./utils/SupplierDeviceInstanceHeader";
import SupplierDeviceCard from "./utils/SupplierDeviceCard";
import ValidationProcessesTable from "./utils/ValidationProcessesTable";

type FormTabs = "main" | "processes" | "attachments"


export const SupplierDeviceInstanceForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''

    return <>
        <EditForm deviceId={id} />
    </>
}
const EditForm = (props: { deviceId: string }) => {
    const { deviceId } = props
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const [device, setDevice] = useState<SupplierDeviceInstance>()
    const [error, setError] = useState<APIError | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    //loading data
    useEffect(() => {
        setIsLoading(true);

        dispatch(loadSupplierDeviceInstance(deviceId))
            .then(unwrapResult)
            .then(setDevice)
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }, [])


    // Tab handling
    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'main'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (error || !device) {
        return <ServerErrorMsg err={error} />
    }

    return <>
        <Container maxWidth={false} sx={{ padding: "2px", paddingLeft: "2px!important", paddingRight: "2px!important" }}>
            <Grid container >
                <Grid item xs={12}>
                    <SupplierDeviceInstanceHeader device={device}></SupplierDeviceInstanceHeader>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value="main"
                                label={<Localized id="">_Device details</Localized>} />
                            <Tab
                                key={1}
                                value="processes"
                                label={<Localized id="">_Documentation</Localized>} />
                            <Tab
                                key={2}
                                value="attachments"
                                label={<Localized id="attachments">Attachments</Localized>} />
                        </TabList>
                        <TabPanel value="main">
                            <SupplierDeviceCard device={device}></SupplierDeviceCard>
                        </TabPanel>
                        <TabPanel value="processes">
                            <ValidationProcessesTable></ValidationProcessesTable>
                        </TabPanel>
                        <TabPanel value="attachments">
                            <Attachements resourceId={device.guid} canDelete={true} canUpload={true} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Container>
    </>
}