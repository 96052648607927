import { Localized } from "@fluent/react"
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Stack, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { URSRequirement, updateURSRequirement, UrsGroup, UrsRequirementType } from "../UrsSlice"
import { RichTextEditor } from "../../../app/RichTextEditor"
import { LoadingButton } from "@mui/lab"
import { GroupField } from "../GroupField"
import { URSComment } from "../URSCommentSlice"
import { Comments } from "../Comments"
import { If } from "../../../app/If"
import { Check, Clear } from "@mui/icons-material";
import { RequirementNote } from "../../ursOffer/ursOfferSlice"
import { DiscussOld, Comment } from "../../../app/Discuss"
import { selectLoggedUserId } from "../../user/userSlice"
import { parseISODate } from "../../../app/Utils"

export interface EditRequirementDialogProps {
    requirement : URSRequirement
    requirementGroup : UrsGroup | null
    onSuccess   : () => void
    onCancel    : () => void
    rootSection : UrsRequirementType
    comments    : URSComment[]
    canSendComments: () => boolean
    canResolveComments: () => boolean
    canEditComment: (comment: URSComment) => boolean
    isOfferReady: boolean
}

// TODO: add comments and attachments
export const EditRequirementDialog = (props: EditRequirementDialogProps) => {
const { requirement, requirementGroup, onSuccess, onCancel, rootSection, comments, isOfferReady } = props
const { canSendComments, canResolveComments } = props
const dispatch = useAppDispatch()
// SELECTORS 
const entity = requirement
const { criticality: defCriticality, typeId: defTypeId, name: defName, group: defGroup, order: defOrder, note: defNote, comment: defComment } = entity
const currentUserId = useAppSelector(selectLoggedUserId)

// STATE 
const [criticality, setCriticality] = useState(defCriticality)
const [name, setName] = useState(defName)
const [saving, setSaving] = useState(false)
const [group, setGroup] = useState<UrsGroup | null>(requirementGroup)
const [note, setNote] = useState<RequirementNote | null>(defNote)
const [comment, setComment] = useState<string | null>(defComment)

// HANDLERS 
const handleCriticalityChange: AppTextFieldHandler = useCallback(e => setCriticality(e.target.value), [setCriticality])
const handleOkClick = async () => {
    setSaving(true)
    dispatch(updateURSRequirement({
        ...entity,
        name,
        criticality,
        sectionGuid: group?.id ||  entity.sectionGuid,
        note,
        comment,
    }))
        .unwrap()
        .catch(err => console.error(err))
        .then(() => onSuccess())
        .finally(() => setSaving(false))
}

const anyComment = comments.filter(c => c.ursRequirementId === entity.id).length > 0
const maxWidth = anyComment ? 'xl' : 'md'

const discussion: Comment[] = requirement.offerMessages.map(m => {
        return {
            someId: m.id,
            content: m.message,
            author: m.author,
            parentId: m.parentMessageId,
            authorId: m.userId,
            companyName: m.companyName,
            date: parseISODate(m.date),
            guid: m.guid,
            kind: m.type,
        }
    })

return <Dialog open={true} fullWidth maxWidth={maxWidth}>
    <DialogTitle>
        <Localized id="requirements-edit">
            <span>Edycja wymagania</span>
        </Localized>
    </DialogTitle>
    <DialogContent dividers>
        <Grid container spacing={4}>
            <Grid item xs={anyComment ? 7 : 12}>
                <Stack direction="column" spacing={2}>
                        <TextField                                                            
                            value={criticality}
                            fullWidth
                            required={true}
                            select
                            label={<Localized id="urs-item-criticality"><span>Criticality</span></Localized>}
                            onChange={handleCriticalityChange}
                        >

                            <MenuItem value={"E"}><Localized id="urs-item-criticality-e"><span>Essential</span></Localized></MenuItem>
                            <MenuItem value={"D"}><Localized id="urs-item-criticality-d"><span>Desired</span></Localized></MenuItem>
                            <MenuItem value={"I"}><Localized id="urs-item-criticality-i"><span>Optional</span></Localized></MenuItem>                                
                        </TextField>
                        <GroupField allGroups={rootSection.groups} value={group} onChange={setGroup} />
                        <RichTextEditor label={null} value={name} onValueChange={setName} />
                        <If condition={isOfferReady}>
                            <Card>
                                <CardContent>
                                    <Stack direction="column" spacing={2}>
                                        <h4><Localized id='urs-supplier-comment'>Komentarz dostawcy</Localized></h4>
                                        <TextField
                                            sx={{ maxWidth: '400px' }}
                                            value={note ?? ''}
                                            fullWidth
                                            select
                                            label={<Localized id="urs-offer-req-opinion"><span>Ocena</span></Localized>}
                                            onChange={e => setNote(e.target.value as RequirementNote)}
                                        >
                                            <MenuItem value={"F"}><Check fontSize="small" color='success' /> <Localized id="urs-offer-req-f"><span> Spełnia</span></Localized></MenuItem>                                
                                            <MenuItem value={"U"}><Clear fontSize="small" color='error' /><Localized id="urs-offer-req-u"><span> Nie spełnia</span></Localized></MenuItem>
                                        </TextField>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            inputProps={{ maxLength: 1000 }}
                                            value={comment ?? ''}
                                            onChange={e => setComment(e.target.value)}
                                            label={<Localized id="urs-offer-req-comment"><span>Komentarz</span></Localized>}
                                        />
                                        <If condition={discussion.length > 0}>
                                            <DiscussOld
                                                allComments={discussion}
                                                postComment={null}
                                                updateComment={null}
                                                deleteComment={null}
                                                currentUserId={currentUserId}
                                                showTitle={true}
                                                readOnly={true}
                                            />
                                        </If>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </If>
                </Stack>
            </Grid>
            {anyComment && <Grid item xs={5}>
                    <Comments 
                        ursId={entity.ursId}
                        comments={comments}
                        requirement={entity}
                        isCommentEnabled={canSendComments}
                        isResolvingEnabled={canResolveComments}
                        isEditEnabled={props.canEditComment}
                    />
            </Grid>}
        </Grid>
    </DialogContent>
    <DialogActions>
        <LoadingButton loading={saving} onClick={handleOkClick}>
            <Localized id="ok">
                <span>Ok</span>
            </Localized>
        </LoadingButton>
        <LoadingButton loading={saving} onClick={onCancel}>
            <Localized id="cancel">
                <span>Cancel</span>
            </Localized>
        </LoadingButton>
    </DialogActions></Dialog>
}

