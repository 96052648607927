import React from 'react';
import { Box, Typography, Paper, IconButton, List, ListItem, ListItemText, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const Standards = () => {
  return (
    <Paper elevation={1} sx={{ padding: 2, maxWidth: 600, margin: '0 auto', marginTop: 2 }}>
      {/* Header with Edit Icon */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h6">Standards, Guidelines, and EU Directives</Typography>
        <IconButton color="primary" size="small">
          <EditIcon />
        </IconButton>
      </Box>

      {/* Industry Standards */}
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        Industry Standards
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="EN ISO 12100:2013-08 Standard for Safety of machinery" />
        </ListItem>
        <ListItem>
          <ListItemText primary="VDE (German Association for Electrical, Electronic and Information Technologies)" />
        </ListItem>
        <ListItem>
          <ListItemText primary="EN 60204, IEC 204" />
        </ListItem>
        <ListItem>
          <ListItemText primary="ISO 9001:2015" />
        </ListItem>
        <ListItem>
          <ListItemText primary="ISO 14001:2015" />
        </ListItem>
      </List>
      <Divider sx={{ marginY: 2 }} />

      {/* Guidelines */}
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        Guidelines
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="GAMP 5" />
        </ListItem>
        <ListItem>
          <ListItemText primary="EU GMP Guideline Annex 11" />
        </ListItem>
        <ListItem>
          <ListItemText primary="EU GMP Guideline Annex 15" />
        </ListItem>
        <ListItem>
          <ListItemText primary="FDA 21 CFR Part 11" />
        </ListItem>
      </List>
      <Divider sx={{ marginY: 2 }} />

      {/* EU Directives */}
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        EU Directives
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="2006/42/EG Machinery directive" />
        </ListItem>
        <ListItem>
          <ListItemText primary="2014/30/EU EMC Directive" />
        </ListItem>
        <ListItem>
          <ListItemText primary="2014/34/EU ATEX Directive" />
        </ListItem>
      </List>
    </Paper>
  );
};

export default Standards;
