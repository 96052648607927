import { Box } from '@mui/material';


export const ColorChangingDot = ({ }: {}) => {
    return (
        <Box
            sx={{
                marginRight: 1,
                width: 9,
                height: 9,
                borderRadius: '50%',
                backgroundColor: 'green',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        />
    )
};