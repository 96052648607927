import {Localized} from "@fluent/react"
import {LoadingButton} from "@mui/lab"
import {Alert, Avatar, Box, Button, ButtonGroup, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Tooltip, Typography} from "@mui/material"
import {Stack} from "@mui/system"
import {DragEvent, useCallback, useRef, useState} from "react"
import {useAppDispatch, useAppSelector} from "../../app/hooks"
import {If} from "../../app/If"
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import {Uuid, BlobMeta, deleteBlob, uploadBlobs, selectBlobsByResourceId} from "./ursAttachementsSlice"
import UploadFileIcon from '@mui/icons-material/UploadFile'


export interface AttachementsProps {
    resourceId: Uuid
    canUpload: boolean
    canDelete: boolean
}

export const Attachements = (props: AttachementsProps) => {
    const { resourceId } = props
    const dispatch = useAppDispatch()
    const { blobs } = useAppSelector(state => selectBlobsByResourceId(state, resourceId) ?? { resourceId, blobs: [] } )

    const removeAttachement = useCallback((blobId: string) => {
        dispatch(deleteBlob([resourceId, blobId])).unwrap()
    }, [resourceId, blobs])

    if (blobs === null) {
        return <Skeleton height={200} />
    } else {
        return <View blobs={blobs} {...props} />
    }
}

interface ViewProps extends AttachementsProps {
    blobs      : BlobMeta[]
}

const View = ({ blobs, canUpload, canDelete, resourceId }: ViewProps) => {
    const dispatch = useAppDispatch()
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [uploading, setUploading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileList | never[] | null>(null)

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])
    const handleSelectedFilesChange = useCallback(() => {
        if (fileInputRef) {
            setSelectedFiles(fileInputRef.current?.files ?? null)
        }
    }, [fileInputRef, setSelectedFiles])
    const handleCancelUploadClick = useCallback(() => {
        if (formRef) {
            formRef.current?.reset()
            setSelectedFiles(null)
        }
    }, [formRef, setSelectedFiles])
    const handleUploadClick = async () => {
        if (selectedFiles && selectedFiles.length > 0) {
            setUploading(true)
            let files: File[] = []
            for (const file of selectedFiles) {
                files.push(file)
            }
            try {
                await dispatch(uploadBlobs([resourceId, files])).unwrap()
                if (formRef) {
                    formRef.current?.reset()
                }
                setSelectedFiles(null)
            } catch (error) {
                console.log(error) 
            } finally {
                setUploading(false)
            }
        }
    }

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation() 
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation() 
        e.preventDefault()
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation() 
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            setSelectedFiles(dt.files)
        }
    }, [])

    const handleDeleteBlobClick = (blobId: string) => {
        dispatch(deleteBlob([resourceId, blobId])).unwrap()
    }

    const anyFiles: boolean = selectedFiles !== null && selectedFiles.length > 0 ? true : false


    return <Stack spacing={2}>
        <If condition={blobs.length > 0} otherwise={<Typography gutterBottom component="div" color="text.secondary"><Localized id="no-attachements">Brak zalączników</Localized></Typography>}>
            <Box>
                <List>
                    {blobs.map(({ fileMeta, blobId }) => 
                        <ListItem secondaryAction={
                            <Stack direction='row'>
                                <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blobId}`}>
                                    <IconButton><DownloadIcon /></IconButton>
                                </a>
                                <If condition = {canDelete}>
                                    <Tooltip title={<Localized id="delete">Usuń</Localized>}>
                                        <IconButton>
                                            <DeleteIcon onClick={() => handleDeleteBlobClick(blobId)} />
                                        </IconButton>
                                    </Tooltip>
                                </If>
                            </Stack>
                            }>
                            <ListItemAvatar>
                                <Avatar>
                                    <FileCopyIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={fileMeta.fileName} secondary={fileMeta.contentType} />
                        </ListItem>)}
                </List>
            </Box>
        </If>
        <Box>
            <form ref={formRef}>
                <input 
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    onChange={handleSelectedFilesChange}
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                />
            </form>
            <If condition={canUpload}>
                <Button size="large" variant="contained" onClick={handleSelectFilesClick}>
                    <Localized id="select-files">Wybierz pliki</Localized>
                </Button>
            </If>
        </Box> 
        <If condition={canUpload}>
            <Box sx={{
                width: '100%',
                minHeight: 60,
                border: 'dashed grey',
                marginTop: 2,
                padding: 1,
            }}  ref={dropboxRef} component="div" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDrop={handleDrop}>
                <Typography variant="body1" color="GrayText" gutterBottom>
                    <Localized id="drop-files">or drop here...</Localized>
                </Typography>
                <If condition={anyFiles}>
                    <FileList files={selectedFiles} />
                </If>
            </Box>
        </If>
        <TotalSizeExcceedsLimit files={selectedFiles} mbs={8} />
        <If condition={anyFiles}>
            <ButtonGroup>
                <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleUploadClick}>
                    <Localized id="upload">Wyślij</Localized>
                </LoadingButton>
                <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleCancelUploadClick}>
                    <Localized id="cancel">Anuluj</Localized>
                </LoadingButton>
            </ButtonGroup>
        </If>
    </Stack>
}

const TotalSizeExcceedsLimit = ({ files, mbs }: { files: FileList | never[] | null, mbs: number }) => {
    if (files === null) {
        return null
    }

    let size = 0
    for (const file of files) {
        size += file.size
    }
    if (size > 1024 * 1024 * mbs) {
        if (files.length === 1) {
            return <Alert severity="error">
                <Typography color="error">
                    <Localized id='error-file-too-large' vars={{ size: mbs }}>Some text</Localized>
                </Typography>
            </Alert>
        } else {
            return <Alert severity="error">
                <Typography color="error">
                    <Localized id='error-total-size-exceeded' vars={{ size: mbs }}></Localized>
                </Typography>
            </Alert>
        }
    } else {
        return null
    }
}

function fileSizeKBorMB(file: File): string {
    let size = file.size
    if (size < 1024) {
        return `${size}B`
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)}KB`
    } else {
        return `${(size / (1024 * 1024)).toFixed(2)}MB`
    }
}

export function isFileAnImage(file: File): boolean {
    return file.type.startsWith('image/')
}

const FileImage = ({ file }: { file: File }) => {
    if (isFileAnImage(file)) {
        const url = URL.createObjectURL(file)
        return <img 
            style={{ width: '90px' }}
            src={url}
            onLoad={() => URL.revokeObjectURL(url)}
        />
    } else {
        return <UploadFileIcon />
    }
}

const FileList = ({ files }: { files: FileList | never[] | null }) => {
    if (files === null) {
        return <span>No files</span>
    }

    let idx: number[] = []
    for (let i = 0; i < files.length; i++) {
        idx.push(i)
    }

    return <List>{idx.map(j => {
        const file = files[j]
        return <ListItem>
            <ListItemAvatar>
                <FileImage file={file} />
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`size: ${fileSizeKBorMB(file)}`}></ListItemText>
        </ListItem>
    })}</List>
}

