import { Alert, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useAppDispatch } from "../../../app/hooks"
import { Localized } from "@fluent/react"
import { useState } from "react"
import { LoadingButton } from "@mui/lab"
import { deleteSpec } from "../specSlice"
import DeleteIcon from '@mui/icons-material/Delete';
import { showError, showSuccess } from "../../notifications/notificationsSlice"


export interface DeleteSpecDialogProps {
    specId: number
    onDelete: (specId: number) => void
    onCancel: () => void,
}

export const DeleteSpecDialog = ({ specId, onDelete, onCancel }: DeleteSpecDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")


    const handleConfirmDeleteClick = async () => {
        setSaving(true)
        if (specId) {
            try {
                const result = await dispatch(deleteSpec(
                    specId
                ))

                if (deleteSpec.fulfilled.match(result)) {
                    dispatch(showSuccess("deleted"))
                    onDelete(specId)
                }
                else {
                    let errorMsg = "error"
                    debugger
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }

        setSaving(false)
    }

    return <>
        <Dialog open={true} fullWidth maxWidth='xs'>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} onClick={handleConfirmDeleteClick} startIcon={<DeleteIcon />} size="small" color="error" variant="contained">
                    <Localized id="delete"><span>_delete</span></Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={onCancel}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
