import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { useLocalization } from '@fluent/react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import GavelIcon from '@mui/icons-material/Gavel';
import IsoIcon from '@mui/icons-material/Iso';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import InventoryIcon from '@mui/icons-material/Inventory';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import React from 'react';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { GridView } from '@mui/icons-material';
import { useAppSelector, useQueryStructureId } from './hooks';
import { selectModule } from '../features/modules/moduleSlice';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { Tooltip } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GradingIcon from '@mui/icons-material/Grading';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';

interface ListItemLinkProps {
    icon?: React.ReactElement
    primary: string
    to: string
    appendStructure: boolean
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, to: pureTo, appendStructure } = props;
    const structureId = useQueryStructureId()

    let to = pureTo
    if (appendStructure && structureId !== undefined) {
        to = to.concat("?structureId=", structureId)
    }

    const renderLink = useMemo(
        () => forwardRef<any, Omit<RouterLinkProps, 'to'>>(
            (itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return <li>
        <ListItem button component={renderLink}>
            <Tooltip title={primary}><ListItemIcon>{icon}</ListItemIcon></Tooltip>
            <ListItemText primary={primary} />
        </ListItem>
    </li>
}

export const Nav = () => {
    const module = useAppSelector(state => selectModule(state).currentModule?.code)

    switch (module) {
        case 'super':
            return <SuperNav />
        case 'admin':
            return <AdminNav />
        case 'validation':
            return <ValidationNav />
        case 'urs':
            return <URSNav />
        case 'offering':
            return <OfferingNav />
        case 'project':
            return <ProjectManagementNav />
        case 'measure':
        default:
            return <MeasureNav />
    }
}

const SuperNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/"
            appendStructure={true}
            primary={l10n.getString("menu-homepage", null, "Strona główna")}
            icon={<HomeIcon />}
        />
        <ListItemLink
            to="/structure"
            appendStructure={true}
            primary={l10n.getString("menu-structure", null, "Struktura")}
            icon={<GridView />}
        />
        <ListItemLink
            to="/companies"
            appendStructure={true}
            primary={l10n.getString("menu-companies", null, "Firmy")}
            icon={<BusinessIcon />}
        />
        <ListItemLink
            to="/companies-to-register"
            appendStructure={true}
            primary={l10n.getString("menu-companies-to-register", null, "Firmy do zarejestrowania")}
            icon={<DomainAddIcon />}
        />
        <ListItemLink
            to="/users"
            appendStructure={true}
            primary={l10n.getString("menu-users", null, "Użytkownicy")}
            icon={<PeopleIcon />}
        />
        <ListItemLink
            to="/archived-users"
            appendStructure={true}
            primary={l10n.getString("menu-archived-users", null, "Zarchiwizowani użytkownicy")}
            icon={<PeopleOutlineIcon />}
        />
        <ListItemLink
            to="/documents-overview"
            appendStructure={true}
            primary={l10n.getString("menu-documents-overview", null, "Documenty")}
            icon={<GridView />}
        />
        <ListItemLink
            to="/modules/access"
            appendStructure={true}
            primary={l10n.getString("menu-modules-access", null, "Dostęp do modułów")}
            icon={<InventoryIcon />}
        />
        <ListItemLink
            to="/sys/validations"
            appendStructure={true}
            primary={l10n.getString("menu-validations", null, "Walidacje")}
            icon={<PlaylistAddCheckIcon />}
        />
        <ListItemLink
            to="/sys/requirements"
            appendStructure={true}
            primary={l10n.getString("menu-requirements-dictionary", null, "Urs requirements")}
            icon={<IsoIcon />}
        />
        <ListItemLink
            to="/requirementsTypes"
            appendStructure={true}
            primary={l10n.getString("menu-requirements-types", null, "Typy wymagań")}
            icon={<IsoIcon />}
        />
        <ListItemLink
            to="/sys/urstemplates"
            appendStructure={true}
            primary={l10n.getString("menu-urs-templates", null, "Szablony URS")}
            icon={<HowToVoteIcon />}
        />
        <ListItemLink
            to="/sys/aisettings"
            appendStructure={false}
            primary={l10n.getString("menu-ai-settings", null, "Ustawienia AI")}
            icon={<Diversity2Icon />}
        />
        <ListItemLink
            to="/sys/mailingsettings"
            appendStructure={false}
            primary={l10n.getString("menu-mailing-settings", null, "Ustawienia Mailingu")}
            icon={<EmailIcon />}
        />
        <ListItemLink
            to="/system-settings"
            appendStructure={false}
            primary={l10n.getString("menu-system-settings", null, "Ustawienia systemowe")}
            icon={<SettingsSuggestIcon />}
        />
        <Divider />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}

const ValidationNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/"
            appendStructure={true}
            primary={l10n.getString("menu-homepage", null, "Strona główna")}
            icon={<HomeIcon />}
        />
        <ListItemLink
            to="/process"
            appendStructure={true}
            primary={l10n.getString("menu-processes", null, "_Lista procesów")}
            icon={<AccountTreeIcon />}
        />
        <ListItemLink
            to="/devices"
            appendStructure={true}
            primary={l10n.getString("menu-devices", null, "Lista urzadzeń")}
            icon={<ImportantDevicesIcon />}
        />
        <ListItemLink
            to="/validations"
            appendStructure={true}
            primary={l10n.getString("menu-validations", null, "Walidacje")}
            icon={<PlaylistAddCheckIcon />}
        />
        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}

const URSNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/"
            appendStructure={true}
            primary={l10n.getString("menu-homepage", null, "Strona główna")}
            icon={<HomeIcon />}
        />
        <ListItemLink
            to="/urs"
            appendStructure={true}
            primary={l10n.getString("menu-urs", null, "URS")}
            icon={<GavelIcon />}
        />
        <ListItemLink
            to="/new-risk-analysis"
            appendStructure={true}
            primary={l10n.getString("risk-analysis", null, "risk-analysis")}
            icon={<TroubleshootOutlinedIcon />}
        />
        <ListItemLink
            to="/urstemplates"
            appendStructure={true}
            primary={l10n.getString("menu-urs-templates", null, "Szablony URS")}
            icon={<HowToVoteIcon />}
        />
        <ListItemLink
            to="/requirements"
            appendStructure={true}
            primary={l10n.getString("menu-requirements-dictionary", null, "Katalog wymagań")}
            icon={<IsoIcon />}
        />

        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}

const AdminNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/"
            appendStructure={true}
            primary={l10n.getString("menu-homepage", null, "Strona główna")}
            icon={<HomeIcon />}
        />
        <ListItemLink
            to="/company/users"
            appendStructure={false}
            primary={l10n.getString("menu-users", null, "Użytkownicy")}
            icon={<PeopleIcon />}
        />
        <ListItemLink
            to="/company/archived-users"
            appendStructure={true}
            primary={l10n.getString("menu-archived-users", null, "Zarchiwizowani użytkownicy")}
            icon={<PeopleOutlineIcon />}
        />
        <ListItemLink
            to="/company/settings"
            appendStructure={true}
            primary={l10n.getString("menu-company-settings", null, "Ustawienia firmowe")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/supplier"
            appendStructure={false}
            primary={l10n.getString("menu-supplier", null, "Producenci")}
            icon={<BusinessIcon />}
        />
        <ListItemLink
            to="/mailingsettings"
            appendStructure={false}
            primary={l10n.getString("menu-mailing-settings", null, "Ustawienia Mailingu")}
            icon={<EmailIcon />}
        />
        <ListItemLink
            to="/company/system-events"
            appendStructure={false}
            primary={l10n.getString("menu-system-events", null, "Zdarzenia systemowe")}
            icon={<EventRepeatIcon />}
        />
        <ListItemLink
            to="/aisettings"
            appendStructure={false}
            primary={l10n.getString("menu-ai-settings", null, "Ustawienia AI")}
            icon={<Diversity2Icon />}
        />
        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}

const MeasureNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/measurements"
            appendStructure={true}
            primary={l10n.getString("menu-measurements", null, "Pomiary")}
            icon={<HomeIcon />}
        />
        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}

const OfferingNav = () => {
    const { l10n } = useLocalization();
    return <List>

        <ListItemLink
            to="/ursOffers"
            appendStructure={true}
            primary={l10n.getString("menu-urs-offers", null, "Zapytania URS")}
            icon={<HelpCenterIcon />}
        />

        <ListItemLink
            to="/specs"
            appendStructure={true}
            primary={l10n.getString("menu-specs", null, "Specyfikacje")}
            icon={<GradingIcon />}
        />
        <ListItemLink
            to="/specs/import"
            appendStructure={true}
            primary={l10n.getString("menu-specs-import", null, "Import specyfikacji")}
            icon={<ImportExportIcon />}
        />
        <ListItemLink
            to="/supplier-doc-process"
            appendStructure={true}
            primary={l10n.getString("menu-documentation-process", null, "Doc processes")}
            icon={<DashboardIcon />}
        />
        
        {/* <ListItemLink
            to="/supplierdevices"
            appendStructure={false}
            primary={l10n.getString("menu-supplier-devices", null, "Urządzenia dostawcy")}
            icon={<DevicesIcon />}
        /> */}
        <Divider />
        <ListItemLink
            to="/supplierBudgetOffer"
            appendStructure={true}
            primary={l10n.getString("menu-supplier-budget-offers", null, "Oferty wstępne")}
            icon={<ReceiptLongIcon />}
        />
        <ListItemLink
            to="/supplierOffers"
            appendStructure={true}
            primary={l10n.getString("menu-supplier-offers", null, "Oferty dostawcy")}
            icon={<HistoryEduIcon />}
        />
        <ListItemLink
            to="/fs-document"
            appendStructure={true}
            primary={l10n.getString("menu-fs-document", null, "FS")}
            icon={<HistoryEduIcon />}
        />
        <ListItemLink
            to="/ds-document"
            appendStructure={true}
            primary={l10n.getString("menu-ds-document", null, "DS")}
            icon={<HistoryEduIcon />}
        />
        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}


const ProjectManagementNav = () => {
    const { l10n } = useLocalization();
    return <List>
        <ListItemLink
            to="/projects"
            appendStructure={false}
            primary={l10n.getString("menu-projects-list", null, "Projekty")}
            icon={<DashboardIcon />}
        />
        <Divider />
        <ListItemLink
            to="/projectprocess"
            appendStructure={false}
            primary={l10n.getString("menu-project-process-list", null, "Procesy")}
            icon={<AssignmentIcon />}
        />
        <ListItemLink
            to="/changeControlDocuments"
            appendStructure={false}
            primary={l10n.getString("menu-chc-list", null, "Change control")}
            icon={<AssignmentIcon />}
        />
        <ListItemLink
            to="/businessCases"
            appendStructure={false}
            primary={l10n.getString("menu-business-cases", null, "Business cases")}
            icon={<AssignmentIcon />}
        />
        <ListItemLink
            to="/materialcards"
            appendStructure={true}
            primary={l10n.getString("menu-material-cards", null, "Karty materiałowe")}
            icon={<ListAltOutlinedIcon />}
        />
        <Divider />
        <ListItemLink
            to="/modules"
            appendStructure={false}
            primary={l10n.getString("menu-modules", null, "Zmień moduł")}
            icon={<ChangeCircleIcon />}
        />
        <ListItemLink
            to="/settings"
            appendStructure={true}
            primary={l10n.getString("menu-settings", null, "Ustawienia")}
            icon={<SettingsIcon />}
        />
        <ListItemLink
            to="/logout"
            appendStructure={false}
            primary={l10n.getString("menu-logout", null, "Wyloguj")}
            icon={<PowerSettingsNewIcon />}
        />
    </List>
}
