import React, { useState } from "react";
import { Box, Chip, Typography, Tooltip } from "@mui/material";
import { TestSubType } from "./testSpecificationApi";

type ChipProperties = {
  label: string;
  color: "default" | "success" | "warning" | "primary" | "error";
};

const getChipProperties = (subType: TestSubType): ChipProperties => {
  switch (subType) {
    case "SAT":
      return { label: "SAT", color: "success" };
    case "FAT":
      return { label: "FAT", color: "warning" };
    case "FATSAT":
      return { label: "FAT & SAT", color: "error" };
    default:
      return { label: "NONE", color: "default" };
  }
};

interface TestSubTypeEditorProps {
  subType: TestSubType;
  onUpdatedSubType: (newSubType: TestSubType) => void;
}

const TestSubTypeEditor: React.FC<TestSubTypeEditorProps> = ({ subType, onUpdatedSubType }) => {
  const [selectedSubType, setSelectedSubType] = useState<TestSubType>(subType);
  const subTypes: TestSubType[] = ["NONE", "SAT", "FAT", "FATSAT"];

  const handleChipClick = (subType: TestSubType) => {
    setSelectedSubType(subType); 
    onUpdatedSubType(subType)
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
    
      <Box display="flex" gap={1} flexWrap="wrap">
        {subTypes.map((subType) => {
          const { label, color } = getChipProperties(subType);
          const isSelected = selectedSubType === subType;
          return (
            <Tooltip key={subType} title={`Ustaw podtyp: ${label}`}>
              <Chip
                label={label}
                color={isSelected ? color : "default"}
                variant={isSelected ? "filled" : "outlined"}
                onClick={() => handleChipClick(subType)}
                clickable
                sx={{
                  cursor: "pointer",
                  border: isSelected ? `2px solid` : undefined,
                  borderColor: isSelected ? color : undefined,
                }}
              />
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
};

export default TestSubTypeEditor;
