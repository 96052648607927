import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Divider,
} from "@mui/material";

export interface SupplierDeviceInstance {
  id: string;
  name: string;
  description: string;
  deviceTypeId: string;
  deviceTypeName: string;
  producerId: string;
  producerName: string;
  model: string;
  serialNo: string;
  productionYear: number;
  varrantyStatus: string;
  manufacturer: string;
  validationStatus: string;
  guid: string;
}

interface SupplierDeviceCardProps {
  device: SupplierDeviceInstance;
}

const SupplierDeviceCard: React.FC<SupplierDeviceCardProps> = ({ device }) => {
  return (
    <Card sx={{ maxWidth: 400, margin: "1rem auto", boxShadow: 3, borderRadius: 2 }}>
      <CardHeader
        title={device.name}
        subheader={
          <Typography variant="body2" color="text.secondary">
            {device.deviceTypeName} - {device.producerName}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Model: {device.model}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              Serial No: {device.serialNo}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Production Year: {device.productionYear}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Warranty Status: {device.varrantyStatus}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Manufacturer: {device.manufacturer}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Validation Status: {device.validationStatus}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SupplierDeviceCard;
