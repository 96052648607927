import { Autocomplete, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Switch, TextField, Typography } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ChangeEvent, useCallback, useState, DragEvent, useRef } from "react"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { selectAllUrsOffers } from "../../ursOffer/ursOfferSlice"
import SaveIcon from '@mui/icons-material/Save'
import { DeviceTypeCombobox } from "../../device/DeviceTypeCombobox"
import { AppId } from "../../../app/appTypes"
import { DocumentHeader } from "../documentsApi"
import { If } from "../../../app/If"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface ImportSupploerUrsDialogProps {
    onSucces: (document: DocumentHeader) => void
    onCancel: () => void
}

export const ImportSupploerUrsDialog = (props: ImportSupploerUrsDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [registerDevice, setRegisterDevice] = useState(false);
    const [serialNo, setSerialNo] = useState("");
    const [deviceName, setDeviceName] = useState("");
    const [structureId, setDeviceTypeId] = useState<AppId>("")
    const [model, setModel] = useState<AppId>("")
    const [productionYear, setProductionYear] = useState<number>(2025);
    const [description, setDescription] = useState("");
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [uploading, setUploading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileList | never[] | null>(null)
    const [metadata, setMetadata] = useState<{ key: string; value: string }[]>([]);
    const anyFiles: boolean = selectedFiles !== null && selectedFiles.length > 0 ? true : false

    const serialNoError = validationErrors && serialNo === ""
    const deviceNameError = validationErrors && deviceName === ""

    const handleSerialNoChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSerialNo(e.target.value);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDeviceName(e.target.value);
    };

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            setSelectedFiles(dt.files)
        }
    }, [])
    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])


    const handleSaveClick = async () => {

    }

    const handleCancelUploadClick = useCallback(() => {
        if (formRef) {
            formRef.current?.reset()
            setSelectedFiles(null)
        }
    }, [formRef, setSelectedFiles])


    const handleUploadClick = async () => {
        setValidationErrors(true)

        
        if (selectedFiles && selectedFiles.length > 0 && structureId) {
            setUploading(true)
            let files: File[] = []
            for (const file of selectedFiles) {
                files.push(file)
            }
            try {

           
                try {
                    const result = await dispatch(uploadFiles(files, '22', metadata));

                   if(result){
                        dispatch(showSuccess("saved"))
                   }
                   else{
                    dispatch(showSuccess("error"))

                   }
                   
                } catch (error) {
                    console.log(error)
                }

                if (formRef) {
                    formRef.current?.reset()
                }
                setSelectedFiles(null)
            } catch (error) {
                console.log(error)
            } finally {
                setUploading(false)
            }
        }
    }

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])

    const handleSelectedFilesChange = useCallback(() => {
        if (fileInputRef) {
            setSelectedFiles(fileInputRef.current?.files ?? null)
        }
    }, [fileInputRef, setSelectedFiles])

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='Import URS'>Import URS</Localized></DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        value={serialNo}
                        onChange={handleSerialNoChange}
                        label={<Localized id="">Numer</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={deviceNameError}
                        helperText={deviceNameError && <RequiredError />}
                        multiline={true}
                        inputProps={{ maxLength: 200 }}
                        value={deviceName}
                        onChange={handleNameChange}
                        label={<Localized id="">Title</Localized>}>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <DeviceTypeCombobox
                        onlyLeafs={true}
                        fullWidth
                        required
                        error={structureId === ''}
                        helperText={structureId === '' && <RequiredError />}
                        value={structureId}
                        onChange={handleDeviceTypeIdChange}
                        label={<Localized id="structure">Struktura</Localized>}
                    />
                </Grid>
                <form ref={formRef}>
                    <input
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        type="file"
                        onChange={handleSelectedFilesChange}
                        accept="image/*,.xlsx,"
                    />
                </form>
                <Button size="large" variant="contained" onClick={handleSelectFilesClick}>
                    <Localized id="select-file">Wybierz plik</Localized>
                </Button>
                <Box sx={{
                    width: '100%',
                    minHeight: 60,
                    border: 'dashed grey',
                    marginTop: 2,
                    padding: 1,
                }} ref={dropboxRef} component="div" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <Typography variant="body1" color="GrayText" gutterBottom>
                        <Localized id="drop-files">or drop here...</Localized>
                    </Typography>
                    <If condition={anyFiles}>
                        <FileList files={selectedFiles} />
                    </If>
                </Box>
                <If condition={anyFiles}>
                    <ButtonGroup>
                        <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleUploadClick}>
                            <Localized id="import">Import</Localized>
                        </LoadingButton>
                        <LoadingButton loading={uploading} size="large" variant="contained" onClick={handleCancelUploadClick}>
                            <Localized id="cancel">Anuluj</Localized>
                        </LoadingButton>
                    </ButtonGroup>
                </If>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} size="small" color="success" variant="contained" startIcon={<SaveIcon />} onClick={handleSaveClick}>
                <Localized id="save">Zapisz</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} size="small" onClick={props.onCancel} color="primary" variant="outlined">
                <Localized id="cancel">_cancel</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

const FileList = ({ files }: { files: FileList | never[] | null }) => {
    if (files === null) {
        return <span>No files</span>
    }

    let idx: number[] = []
    for (let i = 0; i < files.length; i++) {
        idx.push(i)
    }

    return <List>{idx.map(j => {
        const file = files[j]
        const url = URL.createObjectURL(file)
        return <ListItem>
            <ListItemAvatar>
                <img
                    style={{ width: '90px' }}
                    src={url}
                    onLoad={() => URL.revokeObjectURL(url)}
                />
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`size: ${file.size}B`}></ListItemText>
        </ListItem>
    })}</List>
}
function uploadFiles(files: File[], importDefinitionId: any, metadata: { key: string; value: string }[]): any {
    throw new Error("Function not implemented.")
}

