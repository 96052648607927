import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  Paper,
  Chip,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonGroup
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { TestPhase, TestPhaseStep, TestSubType } from './testSpecificationApi';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TestSubTypeEditor from './TestSubTypeEditor';
import { Localized } from '@fluent/react';

interface TestPhaseTabProps {
  phase: TestPhase;
  onUpdateSteps: (updatedSteps: TestPhaseStep[]) => void;
  onUpdateIsActive: (isActive: boolean) => void;
  onUpdatePhaseSubType: (subType: TestSubType) => void;
}

const TestPhaseTab: React.FC<TestPhaseTabProps> = ({ phase, onUpdateSteps, onUpdateIsActive, onUpdatePhaseSubType }) => {
  const [steps, setSteps] = useState<TestPhaseStep[]>(phase.steps);
  const [selectedSubType, setSelectedSubType] = useState<TestSubType>(phase.subType);

  const [expandedSteps, setExpandedSteps] = useState<Record<number, boolean>>(
    Object.fromEntries(phase.steps.map((step) => [step.id, false]))
  );
  const [stepCounter, setStepCounter] = useState(
    Math.max(...phase.steps.map((step) => step.id), 0) + 1
  );

  const [criteriaCounter, setCriteriaCounter] = useState(
    Math.max(
      ...phase.steps.flatMap((step) => step.criterias.map((criteria) => criteria.id)),
      0
    ) + 1
  );

  const [confirmDeactivate, setConfirmDeactivate] = useState(false); // Dialog potwierdzenia
  const newItemGuid = useRef<number | null>(null);
  const newItemRef = useRef<HTMLLIElement | null>(null);
  const newCriteriaItemGuid = useRef<number | null>(null);
  const newCriteriaItemRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (newItemGuid.current && newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: 'smooth' });
      newItemGuid.current = null;
    }
    if (newCriteriaItemGuid.current && newCriteriaItemRef.current) {
      newCriteriaItemRef.current.scrollIntoView({ behavior: 'smooth' });
      newCriteriaItemGuid.current = null;
    }
  }, [steps, criteriaCounter]);


  const handleAddStep = () => {
    const newStep: TestPhaseStep = {
      id: stepCounter,
      name: '',
      order: steps.length + 1,
      phaseId: phase.id,
      criterias: [],
    };
    const updatedSteps = [...steps, newStep];
    newItemGuid.current = newStep.id;

    setSteps(updatedSteps);
    onUpdateSteps(updatedSteps);
    setExpandedSteps({ ...expandedSteps, [newStep.id]: true });
    setStepCounter((prev) => prev + 1);
  };

  const handleAddCriteria = (stepId: number) => {
    const updatedSteps = steps.map((step) =>
      step.id === stepId
        ? {
          ...step,
          criterias: [
            ...step.criterias,
            {
              id: criteriaCounter,
              name: '',
              order: step.criterias.length + 1,
              stepId: stepId,
            },
          ],
        }
        : step
    );

    newCriteriaItemGuid.current = criteriaCounter;

    setSteps(updatedSteps);
    onUpdateSteps(updatedSteps);
    setCriteriaCounter((prev) => prev + 1);
  };



  const handleSubTypeChange = (newSubType: TestSubType) => {
    setSelectedSubType(newSubType);
    onUpdatePhaseSubType(newSubType)
  };

  const handleExpandAll = () => {
    setExpandedSteps(Object.fromEntries(steps.map((step) => [step.id, true])));
  };

  const handleCollapseAll = () => {
    setExpandedSteps(Object.fromEntries(steps.map((step) => [step.id, false])));
  };

  const handleGenerate = () => {
    console.log('Generowanie danych dla etapu:', phase);
  };

  const handleDeleteStep = (id: number) => {
    // Usuwamy krok
    const updatedSteps = steps
      .filter((step) => step.id !== id) // Usuwa krok o podanym ID
      .map((step, index) => ({
        ...step,
        order: index + 1, // Aktualizuje numerację kroków
      }));

    // Aktualizujemy stan
    setSteps(updatedSteps);
    onUpdateSteps(updatedSteps);
  };

  const handleDeleteCriteria = (stepId: number, criteriaId: number) => {
    const updatedSteps = steps.map((step) =>
      step.id === stepId
        ? {
          ...step,
          criterias: step.criterias
            .filter((criteria) => criteria.id !== criteriaId) // Usuń kryterium
            .map((criteria, index) => ({
              ...criteria,
              order: index + 1, // Aktualizuj numerację kryteriów
            })),
        }
        : step
    );

    setSteps(updatedSteps);
    onUpdateSteps(updatedSteps);
  };

  const handleCriteriaChange = (e, stepId, criteriaId) => {
    const updatedSteps = steps.map((s) =>
      s.id === stepId
        ? {
            ...s,
            criterias: s.criterias.map((c) =>
              c.id === criteriaId
                ? { ...c, name: e.target.value }
                : c
            ),
          }
        : s
    );
  
    setSteps(updatedSteps);
    onUpdateSteps(updatedSteps);
  };

  const handleDeactivate = () => {
    setConfirmDeactivate(false);
    onUpdateIsActive(false);
  };

  if (!phase.isActive) {
    return (
      <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
        <Typography variant="h6">
          <Localized id="test-stage-not-active-title">
            <span>_etap jest nieaktywny</span>
          </Localized>
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          <Localized id="test-stage-not-active-description">
            <span>_etap jest nieaktywny</span>
          </Localized>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onUpdateIsActive(true)}
        >
           <Localized id="test-activate-stage">
            <span>_activate</span>
          </Localized>  
        </Button>
      </Paper>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TestSubTypeEditor
          subType={selectedSubType}
          onUpdatedSubType={handleSubTypeChange} />
      </Box>
      <Paper elevation={1} style={{ padding: 5 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {phase.phaseType} - Test steps ({steps.length})
          </Typography>

          <ButtonGroup size="small">
            <Tooltip title="Zwiń Wszystko" >
              <IconButton color="primary" onClick={handleCollapseAll}>
                <ExpandLessIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rozwiń Wszystko">
              <IconButton color="primary" onClick={handleExpandAll}>
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />

            <Tooltip title="Generuj">
              <IconButton color="primary" onClick={handleGenerate}>
                <AutoFixHighIcon />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Dezaktywuj Etap">
              <IconButton
                sx={{
                  color: 'inherit', // Domyślny kolor
                  '&:hover': {
                    color: 'red', // Kolor po najechaniu
                  },
                }}
                size='small'
                onClick={() => setConfirmDeactivate(true)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Box>


        {/* Lista kroków */}
        {steps.map((step) => (
          <Accordion
            key={step.id}
            expanded={expandedSteps[step.id] ?? false}
            onChange={() =>
              setExpandedSteps((prev) => ({
                ...prev,
                [step.id]: !prev[step.id],
              }))
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}

            >
              <Box display="flex" alignItems="center" gap={2} width="100%">
                <Typography variant="subtitle1">{step.order}.</Typography>
                <TextField
                  label="Step description"
                  value={step.name}
                  autoFocus={true}
                  inputRef={step.id === newItemGuid.current ? newItemRef : null}
                  onChange={(e) => {
                    const updatedSteps = steps.map((s) =>
                      s.id === step.id ? { ...s, name: e.target.value } : s
                    );
                  
                    // console.log("Updated step:", { id: step.id, name: e.target.value });
                    // console.log("All updated steps:", updatedSteps);
                  
                    setSteps(updatedSteps);
                    onUpdateSteps(updatedSteps);
                  }}
                  fullWidth

                />
                <Chip label={`Criterias: ${step.criterias.length}`} color="primary" />
                <IconButton
                  size='small'
                  color="error" onClick={() => handleDeleteStep(step.id)}
                  sx={{
                    color: 'inherit', // Domyślny kolor
                    '&:hover': {
                      color: 'red', // Kolor po najechaniu
                    },
                  }}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" gap={2}>
                {step.criterias.map((criteria) => (
                  <Box
                    key={criteria.id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{
                      marginLeft: 4,
                      paddingLeft: 2,
                      borderLeft: '2px solid #ccc',
                      '&:focus-within': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Szary odcień
                      },
                    }}
                  >
                    <Typography variant="subtitle2">
                      {step.order}.{criteria.order}
                    </Typography>
                    <TextField
                      value={criteria.name}
                      label="Cirteria description"
                      autoFocus={true}
                      inputRef={criteria.id === newCriteriaItemGuid.current ? newCriteriaItemRef : null}
                      onChange={(e) => handleCriteriaChange(e, step.id, criteria.id)}

                      fullWidth
                    />
                    <IconButton
                      size='small'
                      sx={{
                        color: 'inherit', // Domyślny kolor
                        '&:hover': {
                          color: 'red', // Kolor po najechaniu
                        },
                      }}
                      onClick={() => handleDeleteCriteria(step.id, criteria.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleAddCriteria(step.id)}
                  variant="outlined"
                  color='info'
                  sx={{
                    width: '50%', // Szerokość przycisku 50% ekranu
                    margin: '0 auto', // Wyśrodkowanie przycisku w kontenerze
                  }}
                >
                  Add criteria
                </Button>

              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
      {/* Przycisk Dodaj Krok */}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddStep}
        variant="outlined"
        size='medium'
        color='success'
      >
        Add test step
      </Button>

      {/* Dialog potwierdzenia dezaktywacji */}
      <Dialog
        open={confirmDeactivate}
        onClose={() => setConfirmDeactivate(false)}
      >
        <DialogTitle>Potwierdzenie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz dezaktywować ten etap? Wszystkie zmiany pozostaną zapisane.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeactivate(false)}>Anuluj</Button>
          <Button color="error" onClick={handleDeactivate}>
            Dezaktywuj
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestPhaseTab;
