import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Comment, DiscussProps } from "./Discuss";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Alert, Box, Button, ButtonGroup, Divider, Paper, Typography } from "@mui/material";
import { mkTipTapExtWithMention, RichTextContainer } from "./RichTextEditor";
import { Localized, useLocalization } from "@fluent/react";
import { formatDate, formatTime } from "./Utils";
import { useAppDispatch, useAppSelector } from "./hooks";
import { selectAllUsers } from "../features/users/usersSlice";
import { useEditor } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import { useState } from "react";
import { selectLoggedUserId } from "../features/user/userSlice";
import { AppId } from "./appTypes";
import { BlobMeta, uploadBlobs } from "../features/urs/ursAttachementsSlice";
import { unwrapResult } from "@reduxjs/toolkit";

type UpdateComment = ((commentId: AppId, message: string) => Promise<void>) | null

export const Discuss = (props: DiscussProps & { docUUID: string }) => {
    const { allComments, postComment, updateComment, docUUID } = props
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const allUsers = useAppSelector(selectAllUsers)

    const [saving, setSaving] = useState(false)

    const placeholder = l10n.getString('discussion-placeholder')
    let exts: any[] = mkTipTapExtWithMention(allUsers.map(u => {
        return {
            id: u.userName,
            label: `${u.firstName} ${u.lastName}`,
        }
    }))
    exts.push(Placeholder.configure({
        placeholder,
    }))

    const editor = useEditor({
        extensions: exts,
        content: '<p></p>',
    })

    const handlePublishMessage = () => {
        if (postComment !== null && editor && editor.getHTML() !== '<p></p>') {
            setSaving(true)
            postComment(editor.getHTML(), null, 'text', null)
                .then(() => {
                    setSaving(false)
                    editor.commands.setContent('<p></p>')
                })
        }
    }

    const uploadFile = (fs: File[]) : Promise<[string, BlobMeta]> => {
        return dispatch(uploadBlobs([docUUID, fs]))
            .then(unwrapResult)
            .then(bms => {
                const bm = bms[bms.length - 1]
                return [docUUID, bm]
            })
    }

    let content = <></>
    if (allComments.length === 0) {
        content = <>
            <Alert severity='warning'>
                <Localized id='discussion-empty'>Brak wiadomości. Dyskusja nierozpoczęta.</Localized>
            </Alert>
            <Box>
                <RichTextContainer label={<Localized id='discussion-msg'>Wiadomość</Localized>} editor={editor} uploadFile={uploadFile} />
                <LoadingButton loading={saving} sx={{ mt: .5 }} variant="contained" onClick={handlePublishMessage}>
                    <Localized id='send'>Send</Localized>
                </LoadingButton>
            </Box>
        </>
    } else {
        content = <>
            <Timeline>
                {allComments.map(c => <Message update={updateComment} comment={c} docUUID={docUUID} />)}
            </Timeline>
            <Divider />
            <Box>
                <RichTextContainer label={<Localized id='discussion-replay'>Replay</Localized>} editor={editor} uploadFile={uploadFile} />
                <LoadingButton loading={saving} sx={{ mt: .5 }} variant="contained" onClick={handlePublishMessage}>Send</LoadingButton>
            </Box>
        </>
    }

    return <Paper sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: 1,
    }}>{content}</Paper>
}

interface MessageProps {
    comment: Comment
    update: UpdateComment
    docUUID : string
}
const Message = (props: MessageProps) => {
    const { comment, update, docUUID } = props
    const { author, content, someId, authorId, date, companyName } = comment

    const allUsers = useAppSelector(selectAllUsers)
    const currentUserId = useAppSelector(selectLoggedUserId)
    const dispatch = useAppDispatch()

    const createdDate = formatDate(date, false)
    const createdTime = formatTime(date)
    const mine = authorId == currentUserId

    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)

    let exts: any[] = mkTipTapExtWithMention(allUsers.map(u => {
        return {
            id: u.userName,
            label: `${u.firstName} ${u.lastName}`,
        }
    }))

    const editor = useEditor({
        extensions: exts,
        content: '<p></p>',
    })

    const handleEditClick = () => {
        setEdit(true)
        editor?.commands.setContent(content)
    }
    const handleSaveClick = () => {
        if (update) {
            setSaving(true)
            update(someId, editor?.getHTML() ?? '')
                .then(() => {
                    setEdit(false)
                })
                .finally(() => {
                    setSaving(false)
                })
        }
    }

    const uploadFile = (fs: File[]) : Promise<[string, BlobMeta]> => {
        return dispatch(uploadBlobs([docUUID, fs]))
            .then(unwrapResult)
            .then(bms => {
                const bm = bms[bms.length - 1]
                return [docUUID, bm]
            })
    }

    const msg = edit
        ? <RichTextContainer editor={editor} label={<Localized id='discussion-msg-content'>Message content</Localized>} uploadFile={uploadFile} />
        : <Typography component='div' variant="body2" dangerouslySetInnerHTML={{ __html: content }} />

    return <TimelineItem>
                    <TimelineOppositeContent sx={{
                        flex: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0,
                    }} >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                ['& span.hour']: {
                                    ml: 1,
                                },
                                ['& span.icon']: {
                                    ml: .25,
                                },
                            }}>
                            <span>{createdDate}</span>
                            <span className="icon"><CalendarMonthIcon fontSize="small" /></span>
                            <span className="hour">{createdTime}</span>
                            <span className="icon"><AccessTimeIcon fontSize="small" /></span>
                        </Box>
                        <Typography component="div"><b>{author}</b></Typography>
                        <Typography component="div" variant='body2' color='text.secondary'>{companyName}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper sx={{
                            p: 1,
                            bgcolor: '#fafaf9',
                            // bgcolor: 'var(--blue-100)',
                            // border: '1px solid var(--blue-500)',
                            ['& p']: { m: .5 },
                        }} >
                            <Box>
                                {msg}
                            </Box>
                            {edit && <ButtonGroup sx={{ mt: .5 }}>
                                <LoadingButton loading={saving} variant='contained' onClick={handleSaveClick}><Localized id='save'>Save</Localized></LoadingButton>
                                <LoadingButton loading={saving} variant='outlined' onClick={() => setEdit(false)}><Localized id='cancel'>Cancel</Localized></LoadingButton>
                            </ButtonGroup>}
                            {!edit && mine && update !== null && <Button onClick={handleEditClick} sx={{ p: 0 }} variant='text' size='small'><Localized id='edit'>Edit</Localized></Button>}
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
}
