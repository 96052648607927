import { AppId } from "../../app/appTypes"
import { RelatedTestSpecificationsOverview } from "../testSpecification/RelatedTestSpecificationsOverview"

export interface SpecTestsProps {
    componentId: number,
    defStructureId: AppId | null
}

export const SpecTests = ({ componentId, defStructureId }: SpecTestsProps) => {

    return <>
        <RelatedTestSpecificationsOverview
            relatedObjectType={"DEVICE_COMPONENT"}
            relatedObjectId={componentId.toString()}
            defStructureId={defStructureId}
        />
    </>
}
